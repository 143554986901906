<section class="widget">
  <header>
    <h4>Time Reporting</h4>
    <div class="widget-controls">
      <button
        type="button"
        class="btn btn-transparent btn-xs"
        data-toggle="modal"
        data-target="#csv-export-form-modal"
        title="Export time reports"
        [class.color-red]="pullHoursFailed"
        [disabled]="isExportHours"
      >
        <i class="glyphicon glyphicon-download"></i>
        Export time reports
      </button>
      <button
        type="button"
        class="btn btn-xs btn-transparent"
        [class.color-red]="pullHoursFailed"
        title="Pull the latest time reports from Jira"
        [disabled]="isPullingHours"
        (click)="pullHours(true)"
      >
        <i class="glyphicon glyphicon-refresh"></i>
        Refresh
      </button>
    </div>
  </header>
  <div class="mt">
    <cm-tmt-view
      [data]="timeManagementRows"
      [dates]="datesList"
      [time]="timeTable['time']"
    ></cm-tmt-view>
  </div>
</section>

<!-- Build in modal is present in csv-export-form -->
<csv-export-form [jiraId]="entity.JiraTitle!"></csv-export-form>
