import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import {
  ProjectsService,
  IProject,
  Project,
  // IResourceType
} from '.';
import { MessengerService } from '../../service/messenger.service';
import { BroadcastService } from '../../service/broadcast.service';
import { AuthService } from '../../service/auth.service';

import { AbstractEntityComponent } from '../../prototype/entity-component';

@Component({
  selector: 'projects',
  templateUrl: 'projects.component.html',
})
export class ProjectsComponent
  extends AbstractEntityComponent<IProject, Project>
  implements OnChanges
{
  protected formModel: Project = new Project();
  protected eventType: string = 'project';

  protected activatedMilestone: any = {};
  protected invoiceHoursOptions: any = {};
  projectsForMilestones: any[] = [];
  projectsForPurchasedHours: any[] = [];

  @Input() projectOwner!: string;
  @Input() ownerType!: string;
  @Input() milestones: any[] = [];
  @Input() purchasedHours: any[] = [];
  @Input() commisionable? = false;

  @Output() milestoneAdded = new EventEmitter();
  @Output() milestoneUpdated = new EventEmitter();
  @Output() milestoneDeleted = new EventEmitter();
  @Output() hoursSaved = new EventEmitter();
  @Output() hoursRemoved = new EventEmitter();

  expandedRows = {};
  activeTypeIdx: number = 0;
  removedId!: string;

  constructor(
    protected authService: AuthService,
    protected itemService: ProjectsService,
    protected broadcastService: BroadcastService,
    private messengerService: MessengerService
  ) {
    super();
  }
  useMilestones() {
    return this.ownerType === 'opportunity';
  }
  canCreate(): boolean {
    return this.authService.isAdminOrEditor();
  }
  canDelete(): boolean {
    return this.authService.isAdmin();
  }

  ownerCode(): string {
    return this.ownerType + ':' + this.projectOwner;
  }
  expandRow(id) {
    this.expandedRows[id] = !this.expandedRows[id];
  }
  pull(force: boolean = false): void {
    if (force && !this.searchQ) {
      this.itemService.reset();
    }
    this.isUpdating = true;

    let collection = this.itemService.forOwner(this.ownerCode());

    collection.subscribe((items) => {
      this.items = [
        new Project(
          '',
          this.ownerCode(),
          `Invoices for Project ${this.projectOwner}`,
          'Invoices that are apart of the original project'
        ),
      ]
        .concat(items)
        .sort(
          (l, r) => l.Id.localeCompare(r.Id) || l.Title.localeCompare(r.Title)
        );
      this.isUpdating = false;
      if (this.milestones) {
        this.combineMilestones();
      }
      if (this.purchasedHours) {
        this.combinePurchasedHours();
      }
    });
  }

  turnPage(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.items) {
      return;
    }
    if (changes['milestones']) {
      this.combineMilestones();
    }
    if (changes['purchasedHours']) {
      this.combinePurchasedHours();
    }
  }

  combinePurchasedHours() {
    const projects = this.items.reduce((projects, project) => {
      projects[project.Id!] = project;
      project.PurchasedHours = [];
      return projects;
    }, {});
    this.purchasedHours.forEach((item, idx) => {
      item.idx = idx;
      let id = projects[item.ProjectId] ? item.ProjectId : '';
      if (!projects[id]) return;
      projects[id].PurchasedHours.push(item);
    });

    this.splitProjectsForInvoices();
  }
  combineMilestones() {
    const projects = this.items.reduce((projects, project) => {
      projects[project.Id!] = project;
      project.Milestones = [];
      return projects;
    }, {});
    this.milestones.forEach((milestone) => {
      let id = projects[milestone.ProjectId] ? milestone.ProjectId : '';
      if (!projects[id]) return;
      projects[id].Milestones.push(milestone);
    });
    this.splitProjectsForInvoices();
  }

  splitProjectsForInvoices() {
    this.projectsForPurchasedHours = (this.items || []).filter(
      ({ Id, Milestones }) => !Id || !Milestones || !Milestones.length
    );
    this.projectsForMilestones = (this.items || []).filter(
      ({ Id, PurchasedHours }) =>
        !Id || !PurchasedHours || !PurchasedHours.length
    );
  }

  prepareForm(project?: Project): void {
    this.formHeading = `${project ? 'Update' : 'Create'} Project`;
    this.formModel = project || new Project('', this.ownerCode());
    super.prepareForm(project);
  }

  afterSave(): void {
    this.formModel = new Project();
    this.pull(true);
    super.afterSave();
  }

  edit(project: any) {
    this.prepareForm(project);
    super.edit(project);
  }

  remove(id: string) {
    this.itemService.delete(id).subscribe(() => {
      this.pull(true);
      this.broadcastService.next([this.eventType, 'close']);
    });
  }

  sortTable(field: string) {}

  activateMilestone({ milestone, ownerType, ownerId }) {
    this.activatedMilestone = {
      milestone,
      ownerType,
      ownerId,
      approveChange: false,
      status: milestone.Status,
      notes: milestone.Notes,
    };
  }
}
