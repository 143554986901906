<table class="table table-bordered table-lg table-hover">
  <thead>
    <tr>
      <th colspan="7" class="text-center">Purchased hours</th>
    </tr>

    <tr>
      <th>Hours</th>
      <th>Value (AUD)</th>
      <th>Expires</th>
      <th>Notes</th>
      <th>Due Date</th>
      <th>PO</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr  *ngFor="let item of hours; let idx = index">
      <td>{{ item.Hours }}</td>
      <td>${{ item.Value||0 | number:'1.2' }}</td>
      <td>{{ item.ExpireMonth }} {{ item.ExpireYear }}</td>
      <td>{{ item.Notes }}</td>
      <td [class]="{'color-red': (item.DueDate | moment).isBefore()}">{{ item.DueDate | date: 'dd/MM/y' }}</td>

      <td>{{ item.PurchaseOrder }}</td>
      <td [ngSwitch]="item.Status" [attr.data-status]="item.Status">
        <div *ngSwitchCase="20" class="invoiced-label">Invoiced</div>
        <div *ngSwitchDefault>
          <button
            type="button" class="btn btn-transparent btn-xs"
            [ngClass]="'milestone-status-' + item.Status"
            data-toggle="modal" data-target="#purchased-hours-invoice-modal"
            (click)="onUpdate([idx, 'purchased', hours])"
          >
            <i class="glyphicon glyphicon-check"></i>
            {{ statuses[item.Status] }}
          </button>
        </div>
      </td>
      <td>
        <button
          *ngIf="canCreate()"
          type="button" class="btn btn-success btn-xs"
          data-toggle="modal" data-target="#purchased-form-modal"
          (click)="onUpdate([idx, 'purchased', hours])"
        >
          <i class="glyphicon glyphicon-pencil"></i>
        </button>

        <button
          *ngIf="canDelete()"
          type="button" class="btn btn-danger btn-xs"
          data-toggle="modal" data-target="#purchased-hours-remove-modal"
          (click)="onRemove([idx, 'purchased', hours])"
        >
          <i class="glyphicon glyphicon-trash"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
