<h1 class="page-title">
  Chargeable Hours
  <button
    type="button"
    class="btn btn-transparent btn-xs"
    (click)="refreshAll(true)"
  >
    <i class="fa fa-refresh"></i>
    Refresh all
  </button>
</h1>

<div class="col-lg-12">
  <section class="widget">
    <div class="width-300 inline-filter pull-right margin-bottom">
      <div
        class="input-group input-group-sm input-group-transparent input-group-rounded"
      >
        <label class="control-label pull-left po-label">PO</label>
        <select
          id="product-owner-filter"
          (changeValue)="userChanged($event)"
          type="text"
          class="form-control select2 pull-left"
        >
          <option value="any">Any</option>
          <option *ngFor="let user of usersList" [value]="user.FullName">
            {{ authService.email === user.Email && ('Me (' + user.FullName +
            ')') || user.FullName }}
          </option>
        </select>
      </div>
    </div>
    <div class="body">
      <div class="mt">
        <table class="table table-striped table-hover table-hours">
          <thead>
            <tr>
              <th class="sortable hidden-xs">
                <a (click)="sortItems('ClientCode', $event)"
                  >Client Code<b class="sort-caret"></b
                ></a>
              </th>
              <th class="sortable hidden-sm hidden-xs">
                <a (click)="sortItems('JobNumber', $event)"
                  ><i class="fa fa-list-ol" matTooltip="Job Number"></i
                  ><b class="sort-caret"></b
                ></a>
              </th>
              <th class="sortable">
                <a (click)="sortItems('Title', $event)"
                  >Title<b class="sort-caret"></b
                ></a>
              </th>
              <th class="sortable hidden-sm hidden-xs">
                <a (click)="sortItems('Type', $event)"
                  >Type<b class="sort-caret"></b
                ></a>
              </th>
              <th class="sortable">
                <a (click)="sortItems('ProductOwner', $event)"
                  >Product Owner<b class="sort-caret"></b
                ></a>
              </th>
              <th class="sortable">
                <a (click)="sortItems('Status', $event)"
                  >Status<b class="sort-caret"></b
                ></a>
              </th>
              <th class="sortable hidden-sm hidden-xs">
                <a (click)="sortItems('Timestamp', $event)"
                  >Last Refreshed<b class="sort-caret"></b
                ></a>
              </th>
              <th class="sortable">
                <a (click)="sortItems('Time', $event)">
                  <i
                    class="fa fa-hourglass-half"
                    matTooltip="Hours remaining"
                  ></i>
                  {{ timeTable | totalHours:items | number:'.0-2' }}
                  <span
                    *ngIf="items | sumBy:'NotInvoicedYet'"
                    matTooltip="Not invoiced yet"
                  >
                    ({{ items | sumBy:'NotInvoicedYet' | number:'.0-2' }})
                  </span>
                  <b class="sort-caret"></b>
                </a>
              </th>
              <th><i class="fa fa-cogs" matTooltip="Actions"></i></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of items">
              <td class="hidden-xs">{{ item.ClientCode }}</td>
              <td class="hidden-sm hidden-xs">{{ item.JobNumber }}</td>
              <td>
                <a [routerLink]="[item.LinkBase, item.JobNumber]">
                  {{ item.Title }}
                  <i
                    *ngIf="timeTable[item.JiraTitle]?.isPotentiallyHolded && item.ServiceType !== 'AWS Hosting'"
                    class="fa fa-warning"
                  ></i>
                </a>
              </td>
              <td class="hidden-sm hidden-xs">{{ item.Type }}</td>
              <td>{{ item.ProductOwner }}</td>
              <td>{{ item.Status }}</td>
              <ng-container *ngIf="timeTable[item.JiraTitle]">
                <td
                  [ngClass]="{warning: !timeTable[item.JiraTitle].lastUpdated}"
                  class="hidden-sm hidden-xs"
                >
                  <ng-container *ngIf="!timeTable[item.JiraTitle].lastUpdated">
                    <i class="fa fa-exclamation-triangle"></i> Request error
                  </ng-container>
                  {{ timeTable[item.JiraTitle].lastUpdated }}
                </td>
                <td [ngClass]="{danger: timeTable[item.JiraTitle].time < 0}">
                  {{ timeTable[item.JiraTitle].time }}
                  <span
                    *ngIf="item.NotInvoicedYet > 0"
                    matTooltip="Not invoiced yet"
                  >
                    ({{ item.NotInvoicedYet }})
                  </span>
                </td>
              </ng-container>
              <ng-container *ngIf="!timeTable[item.JiraTitle]">
                <td><i class="fa fa-spinner fa-spin"></i></td>
                <td></td>
              </ng-container>
              <td>
                <button
                  class="btn btn-primary btn-xs"
                  (click)="refreshOne(item, true)"
                >
                  <i class="fa fa-refresh"></i>
                </button>
                <a
                  target="_blank"
                  [routerLink]="[item.LinkBase, item.JobNumber, {'edit-form': true}]"
                  class="btn btn-secondary btn-xs"
                  matTooltip="{{ item.BudgetNote || ''}}"
                  matTooltipPosition="before"
                >
                  <i
                    class="fa"
                    [ngClass]="{'fa-comment-o': !item.BudgetNote, 'fa-comment': !!item.BudgetNote}"
                  ></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>

<div class="col-lg-12">
  <section class="widget">
    <h3 class="head heading">Time per product owner</h3>
    <div class="body">
      <div class="form-group clearfix">
        <label class="control-label col-sm-4" for="start-date"
          >Start Date</label
        >
        <div class="col-sm-6">
          <input
            type="text"
            datetimepicker
            data-format="Y-MM-DD"
            [value]="poStart"
            (valueChanged)="poStart = $event; changePoDate($event)"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group clearfix">
        <label class="control-label col-sm-4" for="start-date">End Date</label>
        <div class="col-sm-6">
          <input
            type="text"
            datetimepicker
            data-format="Y-MM-DD"
            [value]="poEnd"
            (valueChanged)="poEnd = $event; changePoDate($event)"
            class="form-control"
          />
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="mt" style="overflow: auto; max-height: 100vh">
        <div *ngIf="loadingPoReport" class="text-center">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
        <table
          mat-table
          [dataSource]="fixedItems | groupTimeByProductOwner:timeTable:poLogged:poOwnLogged:userMap"
          *ngIf="!loadingPoReport"
          style="min-width: max-content"
        >
          <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef>Product Owner</th>
            <td mat-cell *matCellDef="let item">{{ item.name }}</td>
          </ng-container>

          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Active Projects</th>
            <td mat-cell *matCellDef="let item">{{ item.activeProjects }}</td>
          </ng-container>

          <ng-container matColumnDef="liability">
            <th mat-header-cell *matHeaderCellDef>
              Current Liability (inc. not invoiced)
              <i
                class="fa fa-question-circle"
                matTooltip="Current Liability hours are shown as of now (ignoring selected dates)"
              ></i>
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.time | number:'.0-2'}}
              <span *ngIf="item.notInvoiced > 0" matTooltip="Not invoiced yet">
                ({{ item.notInvoiced.toFixed(2) }})
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="team_logged">
            <th mat-header-cell *matHeaderCellDef>
              Logged by the team
              <i
                class="fa fa-question-circle"
                matTooltip="Logged hours are shown for the selected date range"
              ></i>
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.teamLogged | number:'.0-2' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="po_logged">
            <th mat-header-cell *matHeaderCellDef>
              Logged by PO
              <i
                class="fa fa-question-circle"
                matTooltip="Logged hours are shown for the selected date range"
              ></i>
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.poOwnLogged | number:'.0-2' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="active_score">
            <th mat-header-cell *matHeaderCellDef class="bg-score">
              Active products/agreements
              <i
                class="fa fa-question-circle"
                matTooltip="at the current point in time"
              ></i>
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.activeScore | number:'.0-2' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="responsibility_score">
            <th mat-header-cell *matHeaderCellDef class="bg-score">
              Volume of responsibility (hrs)
              <i
                class="fa fa-question-circle"
                matTooltip="at the current point in time"
              ></i>
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.responsibilityScore | number:'.0-2' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="team_score">
            <th mat-header-cell *matHeaderCellDef class="bg-score">
              Logged for PO’s projects by all team members (hrs)
              <i
                class="fa fa-question-circle"
                matTooltip="for the current reporting week"
              ></i>
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.teamScore | number:'.0-2' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="po_score">
            <th mat-header-cell *matHeaderCellDef class="bg-score">
              Logged by PO (hrs)
              <i
                class="fa fa-question-circle"
                matTooltip="for the current reporting week"
              ></i>
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.poScore | number:'.0-2' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sales_score">
            <th mat-header-cell *matHeaderCellDef class="bg-score">
              Sales/pre-sales done by PO
              <i
                class="fa fa-question-circle"
                matTooltip="for the current reporting week"
              ></i>
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.salesScore | number:'.0-2' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="total_score">
            <th mat-header-cell *matHeaderCellDef class="bg-score">Total</th>
            <td mat-cell *matCellDef="let item">
              {{ item.totalScore | number:'.0-2' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="toggle">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
              <i
                [ngClass]="{fa: true, 'fa-caret-up': expandedPo[item.name], 'fa-caret-down': !expandedPo[item.name]}"
              ></i>
            </td>
          </ng-container>

          <ng-container matColumnDef="filler">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let proj"></td>
          </ng-container>
          <ng-container matColumnDef="proj_name" sticky>
            <th mat-header-cell *matHeaderCellDef>Project</th>
            <td mat-cell *matCellDef="let proj">
              <em> {{ proj[2].ClientCode }} {{ proj[2].JobNumber }} </em>
              {{ proj[2].Title }}
              <em *ngIf="'Active' !== proj[2].Status">
                ({{ proj[2].Status }})
              </em>
            </td>
          </ng-container>

          <ng-container matColumnDef="proj_liability">
            <th mat-header-cell *matHeaderCellDef>Liability</th>
            <td mat-cell *matCellDef="let proj">
              {{ timeTable[proj[0]]?.time > 0 ? timeTable[proj[0]].time : 0 }}
              <span
                *ngIf="proj[2].NotInvoicedYet > 0"
                matTooltip="Not invoiced yet"
              >
                ({{ proj[2].NotInvoicedYet }})
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="proj_team_logged">
            <th mat-header-cell *matHeaderCellDef>Logged by the team</th>
            <td mat-cell *matCellDef="let proj">
              {{ (proj[1]||0)|number:'.0-2' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="proj_po_logged">
            <th mat-header-cell *matHeaderCellDef>Logged by PO</th>
            <td mat-cell *matCellDef="let proj">
              {{ ((poOwnLogged[proj[3]]||{})[proj[0]]||0)|number:'.0-2'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="separator">
            <th mat-header-cell *matHeaderCellDef class="bg-separator"></th>
            <td mat-cell *matCellDef="let proj" class="bg-separator"></td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="['name', 'active', 'liability', 'team_logged', 'po_logged',  'separator', 'active_score', 'responsibility_score', 'team_score', 'po_score', 'sales_score', 'total_score', 'toggle']; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let item; columns: ['name', 'active', 'liability', 'team_logged', 'po_logged', 'separator', 'active_score', 'responsibility_score', 'team_score', 'po_score', 'sales_score', 'total_score', 'toggle']"
            (click)="expandedPo[item.name] = !expandedPo[item.name]"
          ></tr>
          <tr
            mat-row
            [hidden]="!expandedPo[proj[3]]"
            *matRowDef="let proj; when: isProjectRow, columns: ['proj_name', 'filler', 'proj_liability', 'proj_team_logged', 'proj_po_logged', 'filler', 'filler', 'filler', 'filler', 'filler', 'filler', 'filler']"
          ></tr>
        </table>
      </div>
    </div>
  </section>
</div>

<div class="col-lg-12">
  <section class="widget">
    <h3 class="head heading">Reported vs. Capacity</h3>
    <div class="body">
      <div class="form-group clearfix">
        <label class="control-label col-sm-4" for="start-date"
          >Start Date</label
        >
        <div class="col-sm-6">
          <input
            type="text"
            datetimepicker
            data-format="Y-MM-DD"
            [value]="teamStart"
            (valueChanged)="teamStart = $event; changeTeamDate($event)"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group clearfix">
        <label class="control-label col-sm-4" for="start-date">End Date</label>
        <div class="col-sm-6">
          <input
            type="text"
            datetimepicker
            data-format="Y-MM-DD"
            [value]="teamEnd"
            (valueChanged)="teamEnd = $event; changeTeamDate($event)"
            class="form-control"
          />
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="mt">
        <div *ngIf="!teamReport" class="text-center">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
        <table class="table table-striped table-hover" *ngIf="teamReport">
          <thead>
            <tr>
              <th>Team</th>
              <th>Total hours</th>
              <th>Chargeable hours</th>
              <th>Capacity</th>
              <th>Rate</th>
              <th matTooltip="(total - chargeable)" class="hidden-sm hidden-xs">
                Difference
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let team of Teams">
              <tr
                (click)="expandedCapacity[team.key] = !expandedCapacity[team.key]"
                class="pointable"
              >
                <td>
                  <strong>{{ team.name }}</strong>
                </td>
                <ng-container *ngIf="teamReport[team.key]">
                  <td>{{ teamReport[team.key].total_time.toFixed(2) }}</td>
                  <td>{{ teamReport[team.key].chargeable_time.toFixed(2) }}</td>
                  <td>{{ teamReport[team.key].cap|number:'.0-2' }}</td>
                  <td>
                    {{ (teamReport[team.key].chargeable_time /
                    (teamReport[team.key].cap||1) * 100).toFixed(2) }}%
                  </td>
                  <td
                    class="hidden-sm hidden-xs"
                    matTooltip="(total - chargeable)"
                  >
                    {{ (teamReport[team.key].total_time -
                    teamReport[team.key].chargeable_time).toFixed(2) }}
                  </td>
                  <td>
                    <i
                      *ngIf="expandedCapacity[team.key]"
                      class="fa fa-caret-up"
                    ></i>
                    <i
                      *ngIf="!expandedCapacity[team.key]"
                      class="fa fa-caret-down"
                    ></i>
                  </td>
                </ng-container>
                <ng-container *ngIf="!teamReport[team.key]">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="hidden-sm hidden-xs"></td>
                  <td></td>
                </ng-container>
              </tr>
              <ng-container *ngFor="let user of teamReport[team.key]?.details">
                <tr *ngIf="expandedCapacity[team.key]" class="user-details">
                  <td>{{ user.name }}</td>
                  <td>{{ user.total_time }}</td>
                  <td>{{ user.chargeable_time }}</td>
                  <td>{{ user.cap }}</td>
                  <td>
                    {{ (user.chargeable_time / (user.cap||1) * 100).toFixed(2)
                    }}%
                  </td>
                  <td class="hidden-sm hidden-xs">
                    {{ (user.total_time - user.chargeable_time).toFixed(2) }}
                  </td>
                  <td></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>
