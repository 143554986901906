import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AuthService } from "../../service";

@Component({
  templateUrl: "milestones.component.html",
  selector: "lcm-milestones",
})
export class MilestonesComponent {
  milestoneStatuses = {
    0: "Not ready to invoice",
    10: "Ready to invoice",
    20: "Invoiced",
  };

  approveMilestoneChange = false;

  constructor(private authService: AuthService) {}

  canCreate(): boolean {
    return this.authService.isAdminOrEditor();
  }
  canDelete(): boolean {
    return this.authService.isAdmin();
  }

  @Input() milestones: any[] = [];
  @Input() ownerType!: string;
  @Input() ownerId!: string;
  @Input() project;
  @Output() activate = new EventEmitter();

  onActivate(m) {
    this.activate.emit({
      milestone: m,
      ownerType: this.ownerType,
      ownerId: this.ownerId,
    })
  }
}
