import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: "sidebar-link-collection",
  template: `
    <li class="panel ">
      <a
        class="accordion-toggle collapsed"
        data-toggle="collapse"
        data-parent="#side-nav"
        href="#{{ collectionId }}"
      >
        <i *ngIf="icon" class="fa fa-{{ icon }}"></i>
        <span class="name">{{ label }}</span>
      </a>
      <ul id="{{ collectionId }}" class="panel-collapse collapse">
        <ng-content></ng-content>
      </ul>
    </li>
  `,

  styles: [":host ::ng-deep ul ul {margin-left: 10px;}"]
})
export class SidebarLinkCollectionComponent implements OnInit {
  @Input() label!: string;
  @Input() collectionId!: string;
  @Input() icon!: string;

  ngOnInit(): void {
    if (!this.collectionId) {
      this.collectionId = this.label.toLowerCase().replace(/\s|\./g, "-");
    }
  }
}
