import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AuthService } from "../../service";

@Component({
  templateUrl: "purchased-hours.component.html",
  selector: "lcm-purchased-hours",
})
export class PurchasedHoursComponent {
  statuses = {
    0: "Not ready to invoice",
    10: "Ready to invoice",
    20: "Invoiced",
  };

  constructor(private authService: AuthService) {}

  canCreate(): boolean {
    return this.authService.isAdminOrEditor();
  }
  canDelete(): boolean {
    return this.authService.isAdmin();
  }

  @Input() hours: any[] = [];
  @Input() ownerType!: string;
  @Input() ownerId!: string;
  @Input() project;

  @Output() update = new EventEmitter();
  @Output() remove = new EventEmitter();

  onUpdate(options) {
    this.update.emit({
      options,
      ownerType: this.ownerType,
      ownerId: this.ownerId,
    })
  }
  onRemove(options) {
    this.update.emit({
      options,
      ownerType: this.ownerType,
      ownerId: this.ownerId,
    })
  }

}
