<div class="col-lg-12">
  <section class="widget">
    <h1>Commission</h1>
    <div class="body">
      <div class="mt">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Client Code</th>
              <th>
                <i class="fa fa-list-ol" title="Job/Agreement Number"></i>
              </th>
              <th>Title</th>
              <th>Type</th>
              <th>Invoice amount</th>
              <th>Commission</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let agreement of agreements">
              <tr
                (click)="expanded[agreement.AgreementNumber] = !expanded[agreement.AgreementNumber]"
              >
                <td>{{ agreement.ClientCode }}</td>
                <td>{{ agreement.AgreementNumber }}</td>
                <td>
                  <a
                    title="View"
                    [routerLink]="['/app/agreements/view', agreement.AgreementNumber]"
                  >
                    {{ agreement.Title }}
                  </a>
                </td>
                <td>Agreement</td>
                <td>${{ agreement|totalCommissionAmount }}</td>
                <td>
                  ${{ (agreement|totalCommissionAmount) * .05 | number:'.0-2' }}
                </td>
                <td>
                  <i
                    *ngIf="expanded[agreement.AgreementNumber]"
                    class="fa fa-caret-up"
                  ></i>
                  <i
                    *ngIf="!expanded[agreement.AgreementNumber]"
                    class="fa fa-caret-down"
                  ></i>
                </td>
              </tr>
              <tr *ngIf="expanded[agreement.AgreementNumber]">
                <td colspan="7">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th class="col-md-6">Notes</th>
                        <th>Invoice amount</th>
                        <th>Commission</th>
                      </tr>
                    </thead>
                    <ng-container
                      *ngFor="let invoice of invoices[agreement.AgreementNumber]"
                    >
                      <tr>
                        <td>{{ invoice.InvoiceDate|date:'dd/MM/yyyy' }}</td>
                        <td>{{ invoice.Notes }}</td>
                        <td>${{ invoice.InvoiceAmount }}</td>
                        <td>
                          ${{ (invoice.InvoiceAmount * .05 | number:'.0-2') }}
                        </td>
                      </tr>
                    </ng-container>
                  </table>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngFor="let opportunity of opportunities">
              <tr
                (click)="expanded[opportunity.JobNumber] = !expanded[opportunity.JobNumber]"
              >
                <td>{{ opportunity.ClientCode }}</td>
                <td>{{ opportunity.JobNumber }}</td>
                <td>
                  <a
                    title="View"
                    [routerLink]="['/app/products/view', opportunity.JobNumber]"
                  >
                    {{ opportunity.Title }}
                  </a>
                </td>
                <td>Product</td>
                <td>${{ opportunity|totalCommissionAmount }}</td>
                <td>
                  ${{ (opportunity|totalCommissionAmount) * .05 | number:'.0-2'
                  }}
                </td>
                <td>
                  <i
                    *ngIf="expanded[opportunity.JobNumber]"
                    class="fa fa-caret-up"
                  ></i>
                  <i
                    *ngIf="!expanded[opportunity.JobNumber]"
                    class="fa fa-caret-down"
                  ></i>
                </td>
              </tr>
              <tr *ngIf="expanded[opportunity.JobNumber]">
                <td colspan="7">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th class="col-md-6">Notes</th>
                        <th>Invoice amount</th>
                        <th>Commission</th>
                      </tr>
                    </thead>
                    <ng-container
                      *ngFor="let invoice of invoices[opportunity.JobNumber]"
                    >
                      <tr>
                        <td>{{ invoice.InvoiceDate|date:'dd/MM/yyyy' }}</td>
                        <td>{{ invoice.Notes }}</td>
                        <td>${{ invoice.InvoiceAmount }}</td>
                        <td>
                          ${{ (invoice.InvoiceAmount * .05 | number:'.0-2') }}
                        </td>
                      </tr>
                    </ng-container>
                  </table>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>
