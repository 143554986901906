import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Subject, Observable, from, of } from 'rxjs';

import { map, tap, concatAll, delay, catchError } from 'rxjs/operators';

import { LambdaService } from './lambda.service';
import { AuthService } from './auth.service';

import { WorklogRecord } from '../prototype/worklog.prototype';

@Injectable()
export class JiraService {
  protected worklogs: { [key: string]: WorklogRecord } = {};
  protected timeTable: { [key: string]: any } = {};

  timeTableSubject: Subject<any> = new Subject();

  jiraLink = 'https://linkdigital.jira.com/projects/';

  constructor(
    protected http: HttpClient,
    protected lambda: LambdaService,
    protected auth: AuthService
  ) {}

  getUserTime(start: string, end: string, user: string) {
    return this.http
      .get<any>(
        [this.lambda.getJiraUrl(), 'users', start, end, user].join('/'),
        {
          headers: new HttpHeaders({ authorization: this.auth.authToken }),
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          return of({ total_time: 0, chargeable_time: 0 });
        })
      );
  }
  getProjectsTime(start: string, end: string, projects: string[], jiraId?: string) {
    const url = [this.lambda.getJiraUrl(), 'projects', start, end].join('/');
    const params = projects.reduce(
      (params: HttpParams, proj: string) => params.append('project', proj),
      new HttpParams()
    );

    return this.http.get<any>(url, {
      headers: new HttpHeaders({ authorization: this.auth.authToken }),
      params: params.append("jiraId", jiraId || ""),
    });
  }

  getProjectSpCompleted(project?: string) {
    return this.getProjectSp(project, 'Done');
  }

  getProjectSp(project: string | undefined, status: string) {
    return this.http.get<any>(
      `${this.lambda.getJiraUrl()}/story-points/${project}/${status}`,
      {
        headers: new HttpHeaders({ authorization: this.auth.authToken }),
      }
    );
  }
  getWorklog(
    id: string,
    dates: Array<string>,
    force: boolean = false
  ): Observable<WorklogRecord> {
    if (force) delete this.worklogs[id];

    if (id in this.worklogs) return from([this.worklogs[id]]);
    const [start, end] = dates;
    return this.fetchWorklog(id, { start, end }, force);
  }

  getTimeTable(
    id: string,
    dates: Array<string>,
    force: boolean = false,
    payload: any = {}
  ): Observable<any> {
    if (force) delete this.timeTable[id];
    if (id in this.timeTable) return from([this.timeTable[id]]);
    let [start, end] = dates;
    return this.fetchTimeTable(id, { start, end }, force, payload);
  }

  massTimeTableUpdate(items) {
    from(items)
      .pipe(
        map((item) => from([item]).pipe(delay(100))),
        concatAll(),
        map((item) =>
          this.getTimeTable(
            (item as any).id,
            (item as any).dates,
            (item as any).force,
            (item as any).payload
          ).subscribe(
            (result) => this.timeTableSubject.next([(item as any).id, result]),
            (error) => this.timeTableSubject.next([(item as any).id, null])
          )
        )
      )
      .subscribe();
  }

  private fetchWorklog(
    id: string,
    { start, end }: any,
    force: boolean = false
  ): Observable<WorklogRecord> {
    let params = new HttpParams();
    if (start) {
      params = params.set('start_date', start);
    }
    if (end) {
      params = params.set('end_date', end);
    }
    if (force) {
      params = params.set('force', 'true');
    }
    const request = this.http
      .get<WorklogRecord>([this.lambda.getJiraUrl(), id].join('/'), {
        headers: new HttpHeaders({ authorization: this.auth.authToken }),
        params: params,
      })
      .pipe(tap((record: WorklogRecord) => (this.worklogs[id] = record)));

    return request;
  }

  private fetchTimeTable(
    id: string,
    { start, end }: any,
    force: boolean = false,
    payload: any = {}
  ): Observable<any> {
    let params = new HttpParams();
    if (start) {
      params = params.set('start_date', start);
    }
    if (end) {
      params = params.set('end_date', end);
    }
    if (force) {
      params = params.set('force', 'true');
    }
    const request = this.http
      .post<any>(
        [this.lambda.getJiraUrl(), 'detailed', id].join('/'),
        payload,
        {
          headers: new HttpHeaders({ authorization: this.auth.authToken }),
          params: params,
        }
      )
      .pipe(tap((record) => (this.timeTable[id] = record)));
    return request;
  }

  /* generic download mechanism */
  downloadCsv(id: string, data?: Object): Observable<ArrayBuffer> {
    let params = new HttpParams();

    if (data) {
      for (let key in data) {
        params = params.set(key, data[key]);
      }
    }
    const url = [this.lambda.getJiraCsvUrl(), id].join('/');

    // get the file
    try {
      return this.http.get(url, {
        params: params,
        responseType: 'arraybuffer',
      });
    } catch (err) {
      console.error(err)
      return of(new ArrayBuffer(0));
    }

  }
}
