<h1 class="page-title">Agreements</h1>

<section class="widget" (widgetReload)="reload()">
  <header>
    <h4>
      Agreements
      <span *ngIf="clientCode">
        (
        <span class="fw-semi-bold">{{ clientName || clientCode }}</span>
        <span *ngIf="jobNumber">, {{ jobTitle }}</span>
        )
      </span>
      <button
        *ngIf="canCreate()"
        type="button"
        class="btn btn-transparent btn-xs"
        data-toggle="modal"
        data-target="#agreement-form-modal"
        (click)="prepareForm()"
      >
        <i class="fa fa-plus"></i>
        Add Agreement
      </button>
    </h4>
    <div class="widget-controls sppiner-vert-search">
      <a
        *ngIf="isUpdating && items?.length"
        class="animated fadeIn handle ui-sortable-handle"
      >
        <i class="fa fa-spinner fa-spin"></i>
      </a>

      <a data-widgster="refresh" title="Refresh"
        ><i class="glyphicon glyphicon-refresh"></i
      ></a>
    </div>
    <div class="widget-controls filter-control">
      <div class="width-300 inline-filter">
        <div
          class="input-group input-group-sm input-group-transparent input-group-rounded"
        >
          <label class="control-label pull-left po-label">PO</label>
          <select
            id="product-owner-filter"
            (changeValue)="userChanged($event)"
            type="text"
            class="form-control select2 pull-left"
          >
            <option value="any">Any</option>
            <option *ngFor="let user of usersList" [value]="user.FullName">
              {{ authService.email === user.Email && ('Me (' + user.FullName +
              ')') || user.FullName }}
            </option>
          </select>
        </div>
      </div>
      <div class="width-250 inline-filter">
        <div
          class="input-group input-group-sm input-group-transparent input-group-rounded"
        >
          <span class="input-group-addon">
            <i class="fa fa-search"></i>
          </span>
          <input
            class="form-control input-transparent"
            type="text"
            [value]="clientQ"
            placeholder="Client Code or Name"
            #lookUpAgreement
            (keyup)="clientQ = lookUpAgreement.value; searchClient(lookUpAgreement.value, [singleUser, false])"
          />
        </div>
      </div>
    </div>
  </header>
  <div class="body">
    <div
      *ngIf="items?.length === null"
      class="loader animated fadeIn handle ui-sortable-handle"
    >
      <span class="spinner">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
    </div>
    <div *ngIf="items?.length === 0">
      <h3 class="text-center">There are no any agreements here yet.</h3>
    </div>
    <div class="mt">
      <table
        *ngIf="items?.length"
        id="datatable-table"
        class="table table-striped table-hover table-editable agreement-table"
      >
        <thead>
          <tr>
            <th class="editable sortable renderable client-code-col">
              <a (click)="sortItems('ClientCode', $event)"
                >Client Code<b class="sort-caret"></b
              ></a>
            </th>
            <th class="editable renderable hidden-sm client-name-col">
              Client Name
            </th>
            <th class="editable sortable renderable job-col visible-lg">
              <a (click)="sortItems('JobNumber', $event)"
                >Prod #<b class="sort-caret"></b
              ></a>
            </th>
            <th class="editable sortable renderable long-title agreement-col">
              <a (click)="sortItems('AgreementNumber', $event)"
                >Agreement #<b class="sort-caret"></b
              ></a>
            </th>
            <th class="editable sortable renderable name-col">
              <a (click)="sortItems('Title', $event)"
                >Name<b class="sort-caret"></b
              ></a>
            </th>
            <th class="visible-lg editable sortable renderable type-col">
              <a (click)="sortItems('ServiceType', $event)"
                >Type<b class="sort-caret"></b
              ></a>
            </th>
            <th class="visible-lg editable sortable renderable">
              <a (click)="sortItems('StartDate', $event)"
                >Start<b class="sort-caret"></b
              ></a>
            </th>
            <th class="visible-lg editable sortable renderable">
              <a (click)="sortItems('EndDate', $event)"
                >Expiry<b class="sort-caret"></b
              ></a>
            </th>
            <th class="visible-md visible-lg editable sortable renderable">
              <a (click)="sortItems('BillingCycle', $event)"
                >Billing Cycle<b class="sort-caret"></b
              ></a>
            </th>
            <th class="visible-md visible-lg editable sortable renderable">
              <a (click)="sortItems('InvoiceAmount', $event)"
                >Price<b class="sort-caret"></b
              ></a>
            </th>
            <th class="visible-md visible-lg editable sortable renderable">
              <a (click)="sortItems('Status', $event)"
                >Status<b class="sort-caret"></b
              ></a>
            </th>
            <th class="hidden-xs">Jira</th>
            <th class="options-col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let agreement of items">
            <td>{{ agreement.ClientCode }}</td>
            <td class="hidden-sm">
              {{ clientCodeToNameMapping[agreement.ClientCode] }}
            </td>
            <td><span class="fw-semi-bold visible-lg">{{ agreement.JobNumber }}</span></td>
            <td class="agreement-number">{{ agreement.AgreementNumber }}</td>
            <td>{{ agreement.Title }}</td>
            <td class="visible-lg">{{ agreement.ServiceType }}</td>
            <td class="visible-lg">{{ transformDate(agreement.StartDate) }}</td>
            <td class="visible-lg">{{ transformDate(agreement.EndDate) }}</td>
            <td class="visible-md visible-lg">{{ agreement.BillingCycle }}</td>
            <td class="visible-md visible-lg">
              ${{ agreement.InvoiceAmount | number }}
            </td>
            <td class="hidden-sm">{{ agreement.Status }}</td>
            <td class="hidden-xs jira-links">
              <a href="{{ getJiraLink(agreement) }}" target="_blank"
                >{{ agreement.JiraTitle }}</a
              >
            </td>
            <td>
              <button
                *ngIf="canCreate()"
                title="Edit"
                class="btn btn-success btn-sm"
                (click)="edit(agreement)"
                data-toggle="modal"
                data-target="#agreement-form-modal"
              >
                <span class="glyphicon glyphicon-pencil"></span>
              </button>

              <a
                title="View"
                class="btn btn-success btn-sm"
                [routerLink]="['/app/agreements/view', agreement.AgreementNumber]"
              >
                <span class="glyphicon glyphicon-eye-open"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-paginator
        [length]="itemService.totalAmount"
        [pageSize]="pageLimit"
        [pageSizeOptions]="[10, 20, 50, 100]"
        [showFirstLastButtons]="true"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </div>
  </div>
</section>

<lcm-reservation [agreement-id]="justUpdatedAgreement||''"></lcm-reservation>

<modal-dialog
  [modalId]="'agreement-form-modal'"
  [heading]="formHeading"
  [broadcaster]="triggerBroadcaster"
>
  <agreement-form
    [broadcaster]="triggerBroadcaster"
    [model]="formModel"
    (formSaved)="afterSave()"
  ></agreement-form>
</modal-dialog>
