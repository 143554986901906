import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { IAgreement } from '../prototype/agreement.prototype';
import { AgreementService } from '../service/agreement.service';
import { Observable } from 'rxjs';

@Injectable()
export class AgreementResolver implements Resolve<IAgreement | null> {
  constructor(private backend: AgreementService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IAgreement | null> {
    let id = route.params['entityId'];

    return this.backend.getByUniqueKey(id);
  }
}
