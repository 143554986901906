import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  Input
} from "@angular/core";

declare var jQuery: any;

@Directive({
  selector: ".select2"
})
export class Select2Directive {
  private $el: any;

  @Output() changeValue = new EventEmitter();
  @Input()
  set value(val: string) {
    this.$el.val(val || null);
    this.$el.trigger("change");
  }

  constructor(el: ElementRef) {
    this.$el = jQuery(el.nativeElement);
  }

  ngAfterViewInit(): void {
    let options = {
      tags: this.$el.data("tags"),
      tokenSeparators: [",", " "]
    };

    jQuery(".select2-hidden-accessible").remove();

    this.$el.select2(options).on("change", (e: any) => {
      if (e.val) this.changeValue.emit(e.val);
    });
  }

  ngOnDestroy(): void {
    this.$el.off("change");
  }
}
