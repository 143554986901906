import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, map, tap } from 'rxjs';

import { MessengerService, AuthService } from '../../service';
import { LambdaService } from '../../service/lambda.service';
import { FetchAbstractService } from '../../service/fetch-abstract.service';

import { IProject } from '.';

@Injectable()
export class ProjectsService extends FetchAbstractService<IProject> {
  protected uniqueKeyName: string = 'Id';

  constructor(
    protected http: HttpClient,
    protected lambda: LambdaService,
    protected auth: AuthService,
    protected messenger: MessengerService
  ) {
    super();
    this.defaultOrder = 'Asc';
    this.defaultOrderColumn = 'Title';
    this.filterBy = ['Title'];
  }

  getLoadAllUrl(): string {
    return this.lambda.getProjectsUrl();
  }

  getSaveUrl(forEdit: boolean = false): string {
    return this.lambda.getProjectsUrl();
  }

  getEditUrl(project: IProject): string {
    return this.getSaveUrl() + '/' + project.Id;
  }

  forOwner(id: string): Observable<any> {
    let request: Observable<any> = this.http.get(
      this.getSaveUrl() + '/' + id,
      this.getDefaultHeaders()
    );
    return request;
  }

  searchProductOwner(
    owner: string,
    pageChange: boolean,
    withFilters: boolean = true
  ): Observable<IProject[]> {
    let items = from([this.items]) as Observable<IProject[]>;
    items = items.pipe(
      map((items: Array<IProject>) => {
        return items.filter((item: any) => item._productOwner == owner);
      })
    );

    return items.pipe(
      tap((items: Array<IProject>) => (this.length = items.length))
    );
  }

  getLoadAllErrorMsg(): string {
    return 'List of projects is unavailable';
  }

  delete(id: string): Observable<any> {
    let request: Observable<any> = this.http.delete(
      this.getSaveUrl() + '/' + id,
      this.getDefaultHeaders()
    );

    return request;
  }
}
