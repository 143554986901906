import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OpportunityRoutingModule, routes } from "./opportunity-routing.module";
import { OpportunityComponent } from "./opportunity.component";
import { OpportunityDetailComponent } from "./opportunity-detail.component";
import { DirectiveModule } from "../directive/directive.module";
import { ComponentModule } from "../components/component.module";
import { OpportunityResolver } from "./opportunity-resolver";
import { ResourceRequestsModule } from "../resource-requests/resource-requests.module";
import { ProjectsModule } from "../modules/projects/projects.module";

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    OpportunityRoutingModule,
    DirectiveModule,
    ComponentModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    ResourceRequestsModule,
    ProjectsModule,

    MatPaginatorModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,

  ],
  declarations: [OpportunityComponent, OpportunityDetailComponent],
  providers: [OpportunityResolver]
})
export class OpportunityModule { }
