<h1 class="page-title">
  Product
  <button type="button" class="btn btn-transparent btn-xs" (click)="back()">
    <i class="fa fa-reply"></i>
    Back
  </button>
</h1>

<div class="col-lg-8 opportunity-details">
  <section class="widget" *ngIf="entity">
    <header>
      <h4>
        {{ entity.Title }}
        <i *ngIf="isPotentiallyHolded" class="fa fa-warning"></i>
        <button
          *ngIf="authService.isAdminOrEditor()"
          type="button"
          class="btn btn-transparent btn-xs edit-button"
          data-toggle="modal"
          data-target="#opportunity-form-modal"
          (click)="model = entity"
        >
          <i class="fa fa-pencil"></i>
          Edit
        </button>
      </h4>
    </header>
    <div class="body">
      <div class="mt">
        <table class="table table-striped table-hover">
          <tbody>
            <tr>
              <td style="width: 20%">Client Code</td>
              <td>
                <a [routerLink]="['/app/client-accounts/view', entity.ClientCode]">
                  {{ entity.ClientCode }}
                </a>
              </td>
            </tr>

            <tr>
              <td>Client Name</td>
              <td>{{ clientName }}</td>
            </tr>

            <tr>
              <td>Product Number</td>
              <td>{{ entity.JobNumber }}</td>
            </tr>
            <tr>
              <td>Title</td>
              <td>{{ entity.Title }}</td>
            </tr>
            <tr>
              <td>Product Owner</td>
              <td>{{ entity.ProductOwner }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{{ entity.Status }}</td>
            </tr>
            <tr>
              <td>Jira Title</td>
              <td>
                <a href="{{ getJiraLink(entity) }}" target="_blank"
                  >{{ entity.JiraTitle }}</a
                >
              </td>
            </tr>
            <tr>
              <td>Commissionable</td>
              <td>{{ entity.Commissionable ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <td>Cost per story point</td>
              <td>
                <span>
                  ${{ projectValue|number }} / {{ completedSp }}SP (${{
                  (projectValue / completedSp)|number:'.0-2' }})
                </span>
              </td>
            </tr>
            <tr>
              <td>Budget Note</td>
              <td>{{ entity.BudgetNote || '' }}</td>
            </tr>
            <tr>
              <td>Client Xero ID</td>
              <td>
                <a *ngIf="client?.XeroId"
                  target="_blank"
                  href="https://go.xero.com/Contacts/View.aspx?contactID={{ client?.XeroId }}"
                  title="{{ client?.XeroId }}"
                >
                  {{ client?.XeroId }}
                </a>
              </td>
            </tr>
            <tr *ngIf="agreements.length">
              <td>Agreements</td>
              <td>
                <ul>
                  <li *ngFor="let agreement of agreements">
                    <a
                      [routerLink]="['/app/agreements/view', agreement.AgreementNumber]"
                      >
                      {{ agreement.AgreementNumber }} - {{ agreement.Title }}
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>
<div class="col-lg-4">
  <activity-stream
    [id]="'opportunity' + (+(entity?.JobNumber||0))"
    [createMessage]="'created product'"
  >
  </activity-stream>
</div>

<div class="col-xs-12">
  <projects
    [projectOwner]="entity?.JobNumber || ''"
    ownerType="opportunity"
    (hoursSaved)="afterSave()"
    (hoursRemoved)="afterSave()"
    (milestoneAdded)="afterSave()"
    (milestoneUpdated)="afterSave()"
    (milestoneDeleted)="afterSave()"
    [milestones]="entity?.Milestones || []"
    [purchasedHours]="entity?.PurchasedHours || []"
    [commisionable]="entity?.Commissionable"
  ></projects>
</div>

<div class="col-lg-12">
  <section class="widget">
    <div class="body">
      <div class="mt">
        <table class="table table-striped table-hover">
          <tr>
            <td class="preserve-spaces">
              Unchargeable Hours
              <button
                *ngIf="authService.isAdminOrEditor()"
                type="button"
                class="btn btn-transparent btn-xs add-unchargeable-hours-button"
                title="Add Unchargeable Hours"
                data-toggle="modal"
                data-target="#unchargeable-form-modal"
              >
                <i class="glyphicon glyphicon-plus-sign"></i>
              </button>
            </td>
            <td>
              <table
                *ngIf="entity?.UnchargeableHours?.length"
                class="table table-bordered table-lg table-hover"
              >
                <thead>
                  <tr>
                    <th>Credited</th>
                    <th>Unchargeable Hours</th>
                    <th>Notes</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let a of entity?.UnchargeableHours; let i = index">
                    <td>{{ a.Month }} {{ a.Year }}</td>
                    <td>{{ a.Hours }}</td>
                    <td>{{ a.Notes }}</td>
                    <td>
                      <button
                        [disabled]="isHoursRemoving"
                        type="button"
                        class="btn btn-transparent btn-xs"
                        data-toggle="modal"
                        data-target="#hours-remove-modal"
                        (click)="removeHoursOptions = [i, 'uncharged', entity?.UnchargeableHours]; removeHoursType = 'Unchargeable'"
                      >
                        <i class="glyphicon glyphicon-remove-circle"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td class="preserve-spaces">
              Profitable Hours
              <button
                *ngIf="authService.isAdminOrEditor()"
                type="button"
                class="btn btn-transparent btn-xs add-unchargeable-hours-button"
                title="Add Profitable Hours"
                data-toggle="modal"
                data-target="#profitable-form-modal"
              >
                <i class="glyphicon glyphicon-plus-sign"></i>
              </button>
            </td>
            <td>
              <table
                *ngIf="entity?.ProfitableHours?.length"
                class="table table-bordered table-lg table-hover"
              >
                <thead>
                  <tr>
                    <th>Credited</th>
                    <th>Profitable Hours</th>
                    <th>Notes</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let a of entity?.ProfitableHours; let i = index">
                    <td>{{ a.Month }} {{ a.Year }}</td>
                    <td>{{ a.Hours }}</td>
                    <td>{{ a.Notes }}</td>
                    <td>
                      <button
                        [disabled]="isHoursRemoving"
                        type="button"
                        class="btn btn-transparent btn-xs"
                        data-toggle="modal"
                        data-target="#hours-remove-modal"
                        (click)="removeHoursOptions = [i, 'profited', entity?.ProfitableHours]; removeHoursType = 'Profitable'"
                      >
                        <i class="glyphicon glyphicon-remove-circle"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>Notes</td>
            <td>{{ entity?.Notes }}</td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</div>

<div class="col-xs-12">
  <time-management-table [entity]="entity!"></time-management-table>
</div>

<div class="col-xs-12">
  <resource-requests
    [linkedProject]="entity?.JobNumber"
    projectType="project"
  ></resource-requests>
</div>

<modal-dialog
  *ngIf="entity"
  [modalId]="'opportunity-form-modal'"
  heading="Edit {{ entity.JobNumber}}"
  [broadcaster]="triggerBroadcaster"
>
  <opportunity-form
    [broadcaster]="triggerBroadcaster"
    [model]="model"
    (formSaved)="afterSave()"
  ></opportunity-form>
</modal-dialog>

<modal-dialog
  [modalId]="'unchargeable-form-modal'"
  heading="Add Unchargeable Hours"
  [broadcaster]="triggerBroadcaster"
>
  <hours-form
    [agreement-number]="entity?.JobNumber!"
    type="uncharged"
    (formSaved)="afterSave()"
    using-service="opportunity"
  ></hours-form>
</modal-dialog>

<modal-dialog
  [modalId]="'profitable-form-modal'"
  heading="Add Profitable Hours"
  [broadcaster]="triggerBroadcaster"
>
  <hours-form
    [agreement-number]="entity?.JobNumber!"
    type="profited"
    (formSaved)="afterSave()"
    using-service="opportunity"
  ></hours-form>
</modal-dialog>

<modal-dialog
  [modalId]="'hours-remove-modal'"
  heading="Confirmation"
  [withControls]="true"
  [broadcaster]="triggerBroadcaster"
  confirm-text="Delete"
  (acceptModal)="removeHours(removeHoursOptions)"
>
  Please confirm you'd like to delete this {{ removeHoursType }} Hours item.
  This action cannot be undone.
</modal-dialog>
