import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  // MilestoneFormComponent,
  PurchasedHoursComponent,
  HoursModalsComponent,
} from ".";

import { CommonModule } from "@angular/common";
import { DirectiveModule } from "../../directive/directive.module";
import { ComponentModule } from "../../components/component.module";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DirectiveModule,
    FormsModule,
    ComponentModule,
  ],

  declarations: [
    // MilestoneFormComponent,
    PurchasedHoursComponent,
    HoursModalsComponent,
  ],
  exports: [
    // MilestoneFormComponent,
    PurchasedHoursComponent,
    HoursModalsComponent,
  ],
})
export class HoursModule { }
