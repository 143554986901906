import { Component, Input, OnInit } from '@angular/core';

import { HistoryRecordService } from '../../service/history-record.service';
import { HistoryRecord } from '../../prototype/history-record.prototype';
import { tap } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'activity-stream',
  templateUrl: './activity-stream.template.html',
})
export class ActivityStreamComponent implements OnInit {
  @Input() id!: string;
  @Input() createMessage: string = 'created this entity';
  @Input() updateMessage: string = 'updated this entity';

  records?: Array<HistoryRecord>;
  isUpdating: boolean = false;

  constructor(private history: HistoryRecordService) {}

  ngOnInit(): void {
    this.getHistory(false, 10);
  }

  getHistory(force: boolean = false, limit?: number): void {
    if (force) delete this.records;
    this.isUpdating = true;
    if (this.id)
      this.history
        .getHistory(this.id, force, limit)
        .pipe(tap(() => (this.isUpdating = false)))
        .subscribe(
          (history: Array<HistoryRecord>) => {
            this.records = history.map((record) => {
              record.Data.humanReadable = this.getRecordMessage(record);
              record.Data.relativeTime = this.relativeTime(record.Time);
              record.Data.icon = this.getRecordIcon(record);
              return record;
            });
          },
          (err) => console.warn('ActivityStream error: %o', err)
        );
  }

  relativeTime(date: string): string {
    return moment(date).fromNow();
  }

  getRecordMessage(record: HistoryRecord): string {
    const diff = record.Data.diff;
    let text;
    switch (record.Data.type) {
      case 'create':
        return this.createMessage;
      case 'update':
        text = 'updated: ';
        for (let key in diff) {
          if (~key.toLowerCase().indexOf('date')) {
            text += `${key}(${
              moment(diff[key][0]).format('DD/MM/YYYY') || '{empty}'
            } -> ${moment(diff[key][1]).format('DD/MM/YYYY') || '{empty}'}), `;
          } else {
            text += `${key}(${diff[key][0] || '{empty}'} -> ${
              diff[key][1] || '{empty}'
            }), `;
          }
        }
        return text.slice(0, -2);
      case 'change':
        let info = record.Data.change;
        text = record.Data.action + ': ';
        for (let key in info) {
          if (key === 'Id') continue;
          text += `${key}(${info[key]}), `;
        }
        return text.slice(0, -2);
      default:
        return record.Data.type;
    }
  }

  getRecordIcon(record: HistoryRecord): string {
    switch (record.Data.type) {
      case 'create':
        return 'fa fa-plus color-green';
      case 'update':
      case 'change':
        return 'fa fa-exchange color-blue';
      default:
        return 'fa fa-question color-red';
    }
  }
}
