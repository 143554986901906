<h2 widget class="page-title">Dashboard</h2>
<div class="row dashboard-main">
  <div class="col-lg-12">
    <section class="widget due-invoices-widget">
      <header>
        <h4>Due Invoices</h4>
        <div class="widget-controls">
          <a (click)="getDueInvoices(true)" title="Refresh">
            <i
              class="glyphicon glyphicon-refresh"
              [class.rotating]="pending"
            ></i>
          </a>

          <a data-widgster="expand" title="Expand" href="#"
            ><i class="glyphicon glyphicon-chevron-up"></i
          ></a>
          <a data-widgster="collapse" title="Collapse" href="#"
            ><i class="glyphicon glyphicon-chevron-down"></i
          ></a>
        </div>
      </header>
      <div class="body widget-table-overflow">
        <table class="table table-striped table-lg mt-sm mb-0 sources-table">
          <thead>
            <tr>
              <th>Client Code</th>
              <th>Client Name</th>
              <th>Agreement #</th>
              <th>Service</th>
              <th>Cycle</th>
              <th>Expiry</th>
              <th>Amount</th>
              <th *ngIf="authService.isAdmin()"></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let agreement of dueInvoices">
              <td>
                <a
                  [routerLink]="['/app/client-accounts/view', agreement.ClientCode]"
                  >{{ agreement.ClientCode }}</a
                >
              </td>
              <td>{{ agreement.ClientName }}</td>
              <td>
                <a
                  [routerLink]="['/app/agreements/view', agreement.AgreementNumber]"
                  >{{ agreement.AgreementNumber }}</a
                >
              </td>
              <td>
                <a
                  [routerLink]="['/app/agreements/view', agreement.AgreementNumber]"
                  >{{ agreement.Title }}</a
                >
              </td>
              <td class="hidden-xs">{{ agreement.BillingCycle }}</td>
              <td [class.color-red]="agreement.InvoiceIn! < 0">
                {{ transformDate(agreement.NextInvoiceDate!) }}
                <strong *ngIf="agreement.InvoiceIn! < 0"
                  >({{ -agreement.InvoiceIn! }} day{{ agreement.InvoiceIn! > -2
                  ? '' : 's' }})</strong
                >
              </td>
              <td class="hidden-xs">
                ${{ (agreement.ServiceAmount||0) + (agreement.HostingAmount||0)
                + (agreement.SupportMaintenanceAmount||0)|number }}
              </td>
              <td *ngIf="authService.isAdmin()">
                <button
                  type="button"
                  class="btn btn-transparent"
                  data-toggle="modal"
                  data-target="#invoiced-modal"
                  (click)="(currentAgreement = agreement) && (currentAddon = null)"
                >
                  Invoiced
                </button>
              </td>
            </tr>

            <tr *ngIf="addons.length">
              <th colspan="8" style="text-align: center">
                <strong>Separate Addons</strong>
              </th>
            </tr>
            <tr *ngFor="let addon of addons">
              <td>
                <a
                  [routerLink]="['/app/client-accounts/view', addon.parent.ClientCode]"
                  >{{ addon.parent.ClientCode }}</a
                >
              </td>
              <td>{{ addon.parent.ClientName }}</td>
              <td>
                <a
                  [routerLink]="['/app/agreements/view', addon.parent.AgreementNumber]"
                  >{{ addon.parent.AgreementNumber }}</a
                >
              </td>
              <td>
                <a
                  [routerLink]="['/app/agreements/view', addon.parent.AgreementNumber]"
                  >{{ addon.parent.Title }}</a
                >
              </td>
              <td class="hidden-xs">{{ addon.BillingCycle }}</td>
              <td [class.color-red]="addon.InvoiceIn < 0">
                {{ transformDate(addon.NextInvoiceDate) }}
                <strong *ngIf="addon.InvoiceIn < 0"
                  >({{ -addon.InvoiceIn }} day{{ addon.InvoiceIn > -2 ? '' : 's'
                  }})</strong
                >
              </td>
              <td class="hidden-xs">${{ addon.InvoiceAmount||0 }}</td>
              <td *ngIf="authService.isAdmin()">
                <button
                  type="button"
                  class="btn btn-transparent"
                  data-toggle="modal"
                  data-target="#invoiced-modal"
                  (click)="(currentAddon = addon) && (currentAgreement = addon.parent)"
                >
                  Invoiced
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section
      class="widget expiring-agreements-widget"
      *ngIf="milestones.length"
    >
      <header>
        <h4>Ready to Invoice Milestones</h4>
        <div class="widget-controls">
          <a (click)="getMilestones(true)" title="Refresh">
            <i
              class="glyphicon glyphicon-refresh"
              [class.rotating]="pending"
            ></i>
          </a>
          <a data-widgster="expand" title="Expand" href="#"
            ><i class="glyphicon glyphicon-chevron-up"></i
          ></a>
          <a data-widgster="collapse" title="Collapse" href="#"
            ><i class="glyphicon glyphicon-chevron-down"></i
          ></a>
        </div>
      </header>
      <div class="widget-table-overflow body">
        <mat-table [dataSource]="milestones">
          <ng-container matColumnDef="job_number">
            <mat-header-cell *matHeaderCellDef>Job Number</mat-header-cell>
            <mat-cell *matCellDef="let m">
              <a [routerLink]="['/app/products/view', m.JobNumber]">
                {{ m.JobNumber }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let m">{{ m.Name }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="due_date">
            <mat-header-cell *matHeaderCellDef>Due Date</mat-header-cell>
            <mat-cell *matCellDef="let m">
              {{ transformDate(m.DueDate) }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
            <mat-cell *matCellDef="let m">
              ${{ m.Value|number:'.0-2' }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let m">
              <button
                *ngIf="authService.isAdmin()"
                type="button"
                class="btn btn-transparent"
                data-toggle="modal"
                data-target="#milestone-modal"
                [ngClass]="'milestone-status-' + m.Status"
                (click)="activeMilestone = m; approveMilestoneChange=false; activeMilestoneStatus=m.Status; activeMilestoneNotes=m.Notes"
              >
                <i class="glyphicon glyphicon-check"></i>
                {{ opportunityService.milestoneStatuses[m.Status] }}
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['job_number', 'name', 'due_date', 'value', 'actions']"
          ></mat-header-row>
          <mat-row
            *matRowDef="let m; columns: ['job_number', 'name', 'due_date', 'value', 'actions']"
          ></mat-row>
        </mat-table>
      </div>
    </section>
    <section
      class="widget expiring-agreements-widget"
      *ngIf="purchasedHours.length"
    >
      <header>
        <h4>Ready to Invoice Purchased Hours</h4>
        <div class="widget-controls">
          <a (click)="getPurchasedHours(true)" title="Refresh">
            <i
              class="glyphicon glyphicon-refresh"
              [class.rotating]="pending"
            ></i>
          </a>
          <a data-widgster="expand" title="Expand" href="#"
            ><i class="glyphicon glyphicon-chevron-up"></i
          ></a>
          <a data-widgster="collapse" title="Collapse" href="#"
            ><i class="glyphicon glyphicon-chevron-down"></i
          ></a>
        </div>
      </header>
      <div class="widget-table-overflow body">
        <mat-table [dataSource]="purchasedHours">
          <ng-container matColumnDef="job_number">
            <mat-header-cell *matHeaderCellDef>Job Number</mat-header-cell>
            <mat-cell *matCellDef="let item">
              <a
                [routerLink]="['/app/' + (item._owner.type == 'agreement' ? 'agreements' : 'products') + '/view', item._owner.id]"
              >
                {{ item._owner.id }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let item"> {{ item.Notes }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="due_date">
            <mat-header-cell *matHeaderCellDef>Due Date</mat-header-cell>
            <mat-cell *matCellDef="let item">
              {{ transformDate(item.DueDate) }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
            <mat-cell *matCellDef="let item">
              ${{ item.Value|number:'.0-2' }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let item; let idx = index">
              <button
                *ngIf="authService.isAdmin()"
                type="button"
                class="btn btn-transparent"
                data-toggle="modal"
                data-target="#purchased-hours-invoice-modal"
                [ngClass]="'milestone-status-' + item.Status"
                (click)="invoiceHoursOptions= {options: [idx, 'purchased', purchasedHours], ownerType: item._owner.type, ownerId: item._owner.id}"
              >
                <i class="glyphicon glyphicon-check"></i>
                {{ opportunityService.milestoneStatuses[item.Status] }}
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['job_number', 'name', 'due_date', 'value', 'actions']"
          ></mat-header-row>
          <mat-row
            *matRowDef="let item; columns: ['job_number', 'name', 'due_date', 'value', 'actions']"
          ></mat-row>
        </mat-table>
      </div>
    </section>

    <section class="widget expiring-agreements-widget">
      <header>
        <h4>Expiring Agreements</h4>
        <div class="widget-controls">
          <a (click)="getDueInvoices(true)" title="Refresh">
            <i
              class="glyphicon glyphicon-refresh"
              [class.rotating]="pending"
            ></i>
          </a>
          <a data-widgster="expand" title="Expand" href="#"
            ><i class="glyphicon glyphicon-chevron-up"></i
          ></a>
          <a data-widgster="collapse" title="Collapse" href="#"
            ><i class="glyphicon glyphicon-chevron-down"></i
          ></a>
        </div>
      </header>
      <div class="widget-table-overflow body">
        <mat-table [dataSource]="expiringAgreements">
          <ng-container matColumnDef="client_code">
            <mat-header-cell *matHeaderCellDef>Client Code</mat-header-cell>
            <mat-cell *matCellDef="let agreement">
              <a
                [routerLink]="['/app/client-accounts/view', agreement.ClientCode]"
                >{{ agreement.ClientCode }}</a
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="client_name">
            <mat-header-cell *matHeaderCellDef>Client Name</mat-header-cell>
            <mat-cell *matCellDef="let agreement">
              {{ agreement.ClientName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="agreement_number">
            <mat-header-cell *matHeaderCellDef>Agreement #</mat-header-cell>
            <mat-cell *matCellDef="let agreement">
              <a
                [routerLink]="['/app/agreements/view', agreement.AgreementNumber]"
                >{{ agreement.AgreementNumber }}</a
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="service">
            <mat-header-cell *matHeaderCellDef>Service</mat-header-cell>
            <mat-cell *matCellDef="let agreement">
              <a
                [routerLink]="['/app/agreements/view', agreement.AgreementNumber]"
                >{{ agreement.Title }}</a
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="cycle">
            <mat-header-cell *matHeaderCellDef>Cycle</mat-header-cell>
            <mat-cell *matCellDef="let agreement">
              {{ agreement.BillingCycle }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expiry">
            <mat-header-cell *matHeaderCellDef>Expiry</mat-header-cell>
            <mat-cell
              *matCellDef="let agreement"
              [class.color-red]="agreement.ExpiringIn! < 0"
            >
              {{ transformDate(agreement.EndDate) }}
              <strong *ngIf="agreement.ExpiringIn! < 0"
                >({{ -agreement.ExpiringIn! }} day{{ agreement.ExpiringIn! > -2
                ? '' : 's' }})</strong
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
            <mat-cell *matCellDef="let agreement">
              ${{ agreement.InvoiceAmount | number }}
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="['client_code', 'client_name', 'agreement_number', 'service', 'cycle', 'expiry', 'value']"
          ></mat-header-row>
          <mat-row
            *matRowDef="let agreement; columns: ['client_code', 'client_name', 'agreement_number', 'service', 'cycle', 'expiry', 'value']"
          ></mat-row>
        </mat-table>
      </div>
    </section>
  </div>
</div>
<div class="loader-wrap hiding hide">
  <i class="fa fa-circle-o-notch fa-spin"></i>
</div>

<modal-dialog
  [modalId]="'milestone-modal'"
  heading="Update status"
  [withControls]="true"
  [broadcaster]="triggerBroadcaster"
  [confirm-text]="approveMilestoneChange ? 'Confirm' : 'Update'"
  (acceptModal)="invoiceMilestone(activeMilestone, +activeMilestoneStatus, activeMilestoneNotes)"
>
  <p *ngIf="approveMilestoneChange">
    Please confirm you'd like to mark this milestone as invoiced.
  </p>
  <form
    *ngIf="!approveMilestoneChange"
    class="form-horizontal form-label-left"
    name="milestone-update-form"
  >
    <div class="form-group">
      <label class="control-label col-sm-4" for="status">Status</label>
      <div class="col-sm-6">
        <select
          type="text"
          id="status"
          [ngModelOptions]="{standalone: true}"
          required
          class="form-control"
          [(ngModel)]="activeMilestoneStatus"
        >
          <option
            *ngFor="let item of opportunityService.iterMilestoneStatuses()"
            [value]="item[0]"
          >
            {{ item[1] }}
          </option>
        </select>
      </div>
    </div>
    <div *ngIf="activeMilestoneStatus == 20" class="form-group">
      <label class="control-label col-sm-4" for="notes">Notes</label>
      <div class="col-sm-6" id="notes" name="notes">
        <textarea
          class="form-control"
          [ngModelOptions]="{standalone: true}"
          [(ngModel)]="activeMilestoneNotes"
        ></textarea>
      </div>
    </div>
  </form>
</modal-dialog>

<modal-dialog
  [modalId]="'invoiced-modal'"
  heading="Confirmation"
  [withControls]="true"
  [broadcaster]="triggerBroadcaster"
  (acceptModal)="updateNextInvoiceDate(currentAgreement, currentAddon)"
>
  Please confirm that the client has been invoiced .
</modal-dialog>

<hours-modals
  hoursType="purchased"
  [commisionable]="true"
  [invoiceHoursOptions]="invoiceHoursOptions.options"
  [ownerType]="invoiceHoursOptions.ownerType"
  [ownerId]="invoiceHoursOptions.ownerId"
  (save)="afterHoursInvoice()"
  (update)="afterHoursInvoice()"
  (remove)="afterHoursInvoice()"
  [projects]="[]"
></hours-modals>
