import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ChargeableHoursComponent } from "./chargeable-hours.component";

export const routes: Routes = [
  {
    path: "",
    component: ChargeableHoursComponent,
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HoursRoutingModule {}
