import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { DirectiveModule } from '../directive/directive.module';
import { ComponentModule } from '../components/component.module';

import { HoursRoutingModule } from './hours-routing.module';
import { ChargeableHoursComponent } from './chargeable-hours.component';
import { TotalHoursPipe, GroupTimeByProductOwnerPipe } from './hours.pipe';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableModule } from '@angular/material/table';

@NgModule({
  imports: [
    HoursRoutingModule,
    HttpClientModule,
    CommonModule,
    ComponentModule,
    DirectiveModule,
    MatTooltipModule,
    MatTableModule,
  ],
  declarations: [
    ChargeableHoursComponent,
    TotalHoursPipe,
    GroupTimeByProductOwnerPipe,
  ],
})
export class HoursModule {}
