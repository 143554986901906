<h1 class="page-title">Resource Requests</h1>
<lcm-listing
  heading="Resource Requests"
  [canCreate]="canCreate()"
  [canRefresh]="false"
  [isUpdating]="isUpdating"
  [items]="items"
  [contentTpl]="contentTpl"
  addModalTarget="#resource-request-form-modal"
  (refresh)="reload()"
  (add)="prepareForm()"
>
  <ng-template #contentTpl>
    <table id="datatable-table" class="table table-striped table-hover">
      <thead>
        <tr>
          <th (click)="sortTable('request', 'LinkedProject')" class="pointable">
            Linked Project
            <i
              *ngIf="sort.request.column === 'LinkedProject'"
              [class]="
                'fa fa-sort-amount-' + (sort.resource.asc ? 'asc' : 'desc')
              "
            ></i>
          </th>
          <th (click)="sortTable('request', 'StartDate')" class="pointable">
            Start Date
            <i
              *ngIf="sort.request.column === 'StartDate'"
              [class]="
                'fa fa-sort-amount-' + (sort.resource.asc ? 'asc' : 'desc')
              "
            ></i>
          </th>
          <th (click)="sortTable('request', 'EndDate')" class="pointable">
            End Date
            <i
              *ngIf="sort.request.column === 'EndDate'"
              [class]="
                'fa fa-sort-amount-' + (sort.resource.asc ? 'asc' : 'desc')
              "
            ></i>
          </th>
          <th (click)="sortTable('request', 'Status')" class="pointable">
            Status
            <i
              *ngIf="sort.request.column === 'Status'"
              [class]="
                'fa fa-sort-amount-' + (sort.resource.asc ? 'asc' : 'desc')
              "
            ></i>
          </th>
          <th
            (click)="sortTable('request', 'BusinessDuration')"
            class="pointable"
          >
            Duration
            <i
              *ngIf="sort.request.column === 'BusinessDuration'"
              [class]="
                'fa fa-sort-amount-' + (sort.request.asc ? 'asc' : 'desc')
              "
            ></i>
          </th>
          <th (click)="sortTable('request', 'Velocity')" class="pointable">
            Velocity
            <i
              *ngIf="sort.request.column === 'Velocity'"
              [class]="
                'fa fa-sort-amount-' + (sort.request.asc ? 'asc' : 'desc')
              "
            ></i>
          </th>
          <th (click)="sortTable('request', 'FTECommitment')" class="pointable">
            FTE Commitment
            <i
              *ngIf="sort.request.column === 'FTECommitment'"
              [class]="
                'fa fa-sort-amount-' + (sort.request.asc ? 'asc' : 'desc')
              "
            ></i>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="
            let r of items
              | resourceRequestsPreprocess
              | orderBy : sort.request.column : sort.request.asc
          "
        >
          <tr>
            <td>
              <a [routerLink]="linkedProjectRoute(r.LinkedProject)">
                {{ r.LinkedProject.slice(1) }}
              </a>
            </td>
            <td>{{ (r.StartDate | moment).format("MMM DD") }}</td>
            <td>{{ (r.EndDate | moment).format("MMM DD") }}</td>
            <td>{{ r.Status | resourceRequestsStatus }}</td>
            <td title="{{ r.BusinessDuration }} business days">
              {{ (r | resourceRequestsDuration) / 7 | number : ".0-1" }} weeks
            </td>
            <td>{{ r.Velocity | number : ".0-2" }} hrs/week</td>
            <td>{{ r.FTECommitment | number : ".0-2" }}</td>
            <td>
              <button
                *ngIf="canCreate()"
                title="Edit"
                class="btn btn-success btn-sm"
                (click)="edit(r)"
                data-toggle="modal"
                data-target="#resource-request-form-modal"
              >
                <span class="glyphicon glyphicon-pencil"></span>
              </button>
              <button
                *ngIf="canCreate()"
                title="Delete"
                class="btn btn-danger btn-sm"
                (click)="removedId = r.Id"
                data-toggle="modal"
                data-target="#resource-request-delete-modal"
              >
                <span class="glyphicon glyphicon-trash"></span>
              </button>
              <ng-container *ngIf="r.ResourceTypes?.length">
                <button
                  (click)="expandedRows[r.Id] = !expandedRows[r.Id]"
                  class="btn btn-sm btn-info"
                >
                  <span
                    [class]="
                      'glyphicon glyphicon-chevron-' +
                      (expandedRows[r.Id] ? 'up' : 'down')
                    "
                  ></span>
                  <i></i>
                </button>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="r.ResourceTypes?.length && expandedRows[r.Id]">
            <td colspan="8">
              <table class="table table-condensed table-striped table-hover">
                <thead>
                  <tr>
                    <th
                      (click)="sortTable('resource', 'Name')"
                      class="pointable"
                    >
                      Name
                      <i
                        *ngIf="sort.resource.column === 'Name'"
                        [class]="
                          'fa fa-sort-amount-' +
                          (sort.resource.asc ? 'asc' : 'desc')
                        "
                      ></i>
                    </th>
                    <th
                      (click)="sortTable('resource', 'Group')"
                      class="pointable"
                    >
                      Group
                      <i
                        *ngIf="sort.resource.column === 'Group'"
                        [class]="
                          'fa fa-sort-amount-' +
                          (sort.resource.asc ? 'asc' : 'desc')
                        "
                      ></i>
                    </th>
                    <th
                      (click)="sortTable('resource', 'Allocation')"
                      class="pointable"
                    >
                      Allocation
                      <i
                        *ngIf="sort.resource.column === 'Allocation'"
                        [class]="
                          'fa fa-sort-amount-' +
                          (sort.resource.asc ? 'asc' : 'desc')
                        "
                      ></i>
                    </th>
                    <th
                      (click)="sortTable('resource', 'Expenditure')"
                      class="pointable"
                    >
                      Expenditure
                      <i
                        *ngIf="sort.resource.column === 'Expenditure'"
                        [class]="
                          'fa fa-sort-amount-' +
                          (sort.resource.asc ? 'asc' : 'desc')
                        "
                      ></i>
                    </th>
                    <th>Notes</th>
                    <th
                      (click)="sortTable('resource', 'FTECommitment')"
                      class="pointable"
                    >
                      FTE Commitment
                      <i
                        *ngIf="sort.resource.column === 'FTECommitment'"
                        [class]="
                          'fa fa-sort-amount-' +
                          (sort.resource.asc ? 'asc' : 'desc')
                        "
                      ></i>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let type of r.ResourceTypes
                        | orderBy : sort.resource.column : sort.resource.asc;
                      let i = index
                    "
                  >
                    <td>{{ type.Name }}</td>
                    <td>{{ type.Group }}</td>
                    <td>{{ type.Allocation }}</td>
                    <td>{{ type.Expenditure }}</td>
                    <td>{{ type.Notes }}</td>
                    <td>{{ type.FTECommitment | number : ".0-2" }}</td>
                    <td>
                      <button
                        *ngIf="canCreate()"
                        title="Edit"
                        class="btn btn-success btn-sm"
                        (click)="editType(r, i)"
                        data-toggle="modal"
                        data-target="#resource-request-type-form-modal"
                      >
                        <span class="glyphicon glyphicon-pencil"></span>
                      </button>
                      <button
                        *ngIf="canCreate()"
                        title="Delete"
                        class="btn btn-danger btn-sm"
                        (click)="formModel = r; activeTypeIdx = i"
                        data-toggle="modal"
                        data-target="#resource-request-type-delete-modal"
                      >
                        <span class="glyphicon glyphicon-trash"></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <div class="stats">
      <h3 class="heading">Stats</h3>
      <table class="table table-hover table-striped">
        <thead>
          <tr class="pointable">
            <th (click)="sortTable('stats', 'Name')">
              Group
              <i
                *ngIf="sort.stats.column === 'Name'"
                [class]="
                  'fa fa-sort-amount-' + (sort.stats.asc ? 'asc' : 'desc')
                "
              ></i>
            </th>
            <th (click)="sortTable('stats', 'TotalHours')">
              Total Hours
              <i
                *ngIf="sort.stats.column === 'TotalHours'"
                [class]="
                  'fa fa-sort-amount-' + (sort.stats.asc ? 'asc' : 'desc')
                "
              ></i>
            </th>
            <th (click)="sortTable('stats', 'RemainingHours')">
              Remaining Hours
              <i
                *ngIf="sort.stats.column === 'RemainingHours'"
                [class]="
                  'fa fa-sort-amount-' + (sort.stats.asc ? 'asc' : 'desc')
                "
              ></i>
            </th>
            <th (click)="sortTable('stats', 'RemainingAllocation')">
              FTE Commitment
              <i
                *ngIf="sort.stats.column === 'RemainingAllocation'"
                [class]="
                  'fa fa-sort-amount-' + (sort.stats.asc ? 'asc' : 'desc')
                "
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let group of items
                | resourceRequestsStats
                | orderBy : sort.stats.column : sort.stats.asc
            "
          >
            <td>{{ group.Name }}</td>
            <td>{{ group.TotalHours }}</td>
            <td>{{ group.RemainingHours | number : ".0-2" }}</td>
            <td>{{ group.RemainingAllocation | number : ".0-2" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</lcm-listing>
<resource-calculator></resource-calculator>

<modal-dialog
  modalId="resource-request-form-modal"
  [heading]="formHeading"
  [broadcaster]="triggerBroadcaster"
>
  <resource-requests-form
    [broadcaster]="triggerBroadcaster"
    [model]="formModel"
    (formSaved)="afterSave()"
  ></resource-requests-form>
</modal-dialog>
<modal-dialog
  modalId="resource-request-delete-modal"
  heading="Confirm resource request removal"
>
  <button data-dismiss="modal" class="btn btn-success">Cancel</button>
  <button class="btn btn-danger" (click)="remove(removedId)">Confirm</button>
</modal-dialog>

<modal-dialog
  modalId="resource-request-type-form-modal"
  heading="Update request type"
  [broadcaster]="triggerBroadcaster"
>
  <resource-requests-type-form
    [broadcaster]="triggerBroadcaster"
    [model]="[activeTypeIdx, formModel]"
    (formSaved)="afterSave()"
  ></resource-requests-type-form>
</modal-dialog>

<modal-dialog
  modalId="resource-request-type-delete-modal"
  heading="Confirm request type removal"
>
  <button data-dismiss="modal" class="btn btn-success">Cancel</button>
  <button class="btn btn-danger" (click)="removeType(formModel, activeTypeIdx)">
    Confirm
  </button>
</modal-dialog>
