import { FetchAbstractService } from '../service/fetch-abstract.service';
import { JiraService, BroadcastService, AuthService } from '../service';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Component } from '@angular/core';

import * as moment from "moment";

@Component({template: ''})
export abstract class AbstractDetailComponent<T> {
  protected authService!: AuthService;
  protected itemService!: FetchAbstractService<T>;
  protected route!: ActivatedRoute;
  protected location!: Location;

  protected triggerBroadcaster!: Observable<string>;
  protected broadcastService!: BroadcastService;
  protected jira!: JiraService;

  protected entity?: T;
  protected abstract eventType: string;

  protected worklogSubscription!: Subscription;
  protected isPotentiallyHolded: boolean = false;

  setEntity(entity: T): void {
    this.entity = entity;
  }

  ngOnInit(): void {
    this.setEntity(this.route.snapshot.data['entity']);
    this.worklogSubscription = this.jira
      .getWorklog(<any>this.entity!['JiraTitle'], [
        moment().subtract(3, 'month').toISOString(),
      ])
      .subscribe(
        ({ worklog }) =>
          Object.keys(worklog).length || (this.isPotentiallyHolded = true),
        () => (this.isPotentiallyHolded = true)
      );

    this.triggerBroadcaster = this.broadcastService.getObservable().pipe(
      filter(([type]: string[]) => type === this.eventType),
      map(([, event]: string[]) => event)
    );
  }

  ngOnDestroy(): void {
    this.worklogSubscription && this.worklogSubscription.unsubscribe();
  }

  back(): void {
    this.location.back();
  }

  afterSave(): void {
    this.broadcastService.next([this.eventType, 'save']);
    this.broadcastService.next([this.eventType, 'close']);
  }
}
