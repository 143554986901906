<h1 class="page-title">Client Accounts</h1>

<section class="widget" (widgetReload)="reload()">
  <header>
    <h4>
      Client Accounts
      <button
        *ngIf="canCreate()"
        type="button"
        class="btn btn-transparent btn-xs"
        data-toggle="modal"
        data-target="#client-form-modal"
        (click)="prepareForm()"
      >
        <i class="fa fa-plus"></i>
        Add Client Account
      </button>
    </h4>

    <div class="widget-controls sppiner-vert-search">
      <a
        *ngIf="isUpdating && items?.length"
        class="animated fadeIn handle ui-sortable-handle"
      >
        <i class="fa fa-spinner fa-spin"></i>
      </a>
      <a data-widgster="refresh" title="Refresh"
        ><i class="glyphicon glyphicon-refresh"></i
      ></a>
    </div>
    <div class="widget-controls filter-control">
      <div class="width-250">
        <div
          class="input-group input-group-sm input-group-transparent input-group-rounded"
        >
          <span class="input-group-addon">
            <i class="fa fa-search"></i>
          </span>
          <input
            class="form-control input-transparent"
            type="text"
            value=""
            placeholder="Client Code or Client Name"
            #lookUpClient
            (keyup)="searchClient(lookUpClient.value)"
          />
        </div>
      </div>
    </div>
  </header>
  <div class="body">
    <div
      *ngIf="items?.length === null"
      class="loader animated fadeIn handle ui-sortable-handle"
    >
      <span class="spinner">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
    </div>
    <div *ngIf="items?.length === 0">
      <h3 class="text-center">There are no any clients here yet.</h3>
    </div>

    <div class="mt">
      <table
        *ngIf="items?.length"
        id="datatable-table"
        class="table table-striped table-hover table-editable clients-table"
      >
        <thead>
          <tr>
            <th class="no-sort hidden-xs col-sm-4 links-col">Links</th>
            <th class="col-sm-2 editable sortable renderable">
              <a (click)="sortItems('ClientCode', $event)"
                >Client Code<b class="sort-caret"></b
              ></a>
            </th>
            <th class="no-sort col-md-2 hidden-sm hidden-xs">Commissionable</th>
            <th class="editable sortable renderable">
              <a (click)="sortItems('ClientName', $event)"
                >Name<b class="sort-caret"></b
              ></a>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let client of items">
            <td class="hidden-xs business-links">
              <span *ngIf="client.XeroId">
                <a
                  target="_blank"
                  href="https://go.xero.com/Contacts/View.aspx?contactID={{ client.XeroId }}"
                  title="{{ client.XeroId }}"
                >
                  <img alt="" src="../../assets/img/xero-logo.png" />
                </a>
              </span>
              <span *ngIf="client.InsightlyId">
                <a
                  target="_blank"
                  href="https://googleapps.insight.ly/details/Organisations/Details/{{ client.InsightlyId }}"
                  title="{{ client.InsightlyId }}"
                >
                  <img alt="" src="../../assets/img/insightly-logo.png" />
                </a>
              </span>
            </td>
            <td class="client-code">
              <span class="fw-semi-bold">{{ client.ClientCode}}</span>
            </td>
            <td class="hidden-sm">
              {{ client.Commissionable ? 'Yes' : 'No' }}
            </td>
            <td>{{ client.ClientName }}</td>
            <td>
              <a
                class="btn btn-default btn-sm"
                [routerLink]="['/app/products', client.ClientCode]"
                >Products</a
              >
              <a
                class="btn btn-primary btn-sm"
                [routerLink]="['/app/agreements', client.ClientCode]"
                >Agreements</a
              >
              <button
                *ngIf="canEdit()"
                title="Edit"
                class="btn btn-success btn-sm"
                (click)="edit(client)"
                data-toggle="modal"
                data-target="#client-form-modal"
              >
                <span class="glyphicon glyphicon-pencil"></span>
              </button>

              <a
                title="View"
                class="btn btn-success btn-sm"
                [routerLink]="['view', client.ClientCode]"
              >
                <span class="glyphicon glyphicon-eye-open"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <mat-paginator
        [length]="itemService.totalAmount"
        [pageSize]="pageLimit"
        [pageSizeOptions]="[10, 20, 50, 100]"
        [showFirstLastButtons]="true"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </div>
  </div>
</section>

<modal-dialog
  [modalId]="'client-form-modal'"
  [heading]="formHeading"
  [broadcaster]="triggerBroadcaster"
>
  <client-account-form
    [broadcaster]="triggerBroadcaster"
    [model]="formModel"
    (formSaved)="afterSave()"
  ></client-account-form>
</modal-dialog>
