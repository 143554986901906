<modal-dialog modalId="milestone-form-modal" heading="Add Milestone Hours">
  <milestone-form [projects]="projects || []"
    [modelInstance]="activated.milestone"
    [using-service]="ownerType"
    [id]="ownerId" (formSaved)="saveMilestone()"
  ></milestone-form>
</modal-dialog>
<modal-dialog
  modalId="delete-milestone-modal"
  heading="Confirmation"
  [withControls]="true"
  confirm-text="Delete"
  (acceptModal)="deleteMilestone(activated.milestone.Id)"
>
  Please confirm you'd like to delete this milestone. This action cannot be undone.
</modal-dialog>

<modal-dialog
  modalId="update-milestone-modal"
  heading="Update status"
  [withControls]="true"
  [confirm-text]="activated.approveChange ? 'Confirm' : 'Update'"
  (acceptModal)="updateMilestone(activated.milestone.Id, +activated.status, activated.notes)"
>

  <p *ngIf="activated.approveChange">
    Please confirm you'd like to mark this milestone as invoiced.
  </p>
  <form *ngIf="!activated.approveChange" class="form-horizontal form-label-left" name="milestone-update-form">
    <div class="form-group">
      <label class="control-label col-sm-4" for="milestone-status-change">Status</label>
      <div class="col-sm-6">
        <select type="text" id="milestone-status-change" [ngModelOptions]="{standalone: true}"
          required class="form-control" [(ngModel)]="activated.status"
        >
          <option *ngFor="let status of invoiceService.statuses"
            [value]="status">
            {{ invoiceService.statusLabels[status] }}</option>
        </select>
      </div>
    </div>
    <div *ngIf="activated.status == 20" class="form-group">
      <label class="control-label col-sm-4" for="milestone-notes">Notes</label>
      <div class="col-sm-6" id="milestone-notes" name="notes">
        <textarea class="form-control"  [ngModelOptions]="{standalone: true}"
          [(ngModel)]="activated.notes"></textarea>
      </div>
    </div>
    <div *ngIf="activated.status == 10" class="form-group">
      <label class="control-label col-sm-4" for="milestone-notes">Message</label>
      <div class="col-sm-6" id="milestone-notes" name="notes">
        <textarea class="form-control"  [ngModelOptions]="{standalone: true}"
          [(ngModel)]="message"></textarea>
      </div>
    </div>

  </form>
</modal-dialog>
