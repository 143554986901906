<section class="widget" (widgetReload)="refreshListing()">
  <header>
    <h4>
      {{ heading }}
      <button
        *ngIf="canCreate"
        type="button"
        class="btn btn-transparent btn-xs"
        data-toggle="modal"
        [attr.data-target]="addModalTarget"
        (click)="addItem()"
      >
        <i class="fa fa-plus"></i>
        Add
      </button>
    </h4>

    <div class="widget-controls sppiner-vert-search" *ngIf="canRefresh">
      <a
        *ngIf="isUpdating && items?.length"
        class="animated fadeIn handle ui-sortable-handle"
      >
        <i class="fa fa-spinner fa-spin"></i>
      </a>
      <a data-widgster="refresh" title="Refresh"
        ><i class="glyphicon glyphicon-refresh"></i
      ></a>
    </div>
  </header>
  <div class="body">
    <div class="mt">
      <div
        *ngIf="!items; else blockContent"
        class="loader animated fadeIn handle ui-sortable-handle"
      >
        <span class="spinner">
          <i class="fa fa-spinner fa-spin"></i>
        </span>
      </div>
      <ng-container *ngTemplateOutlet="pagerTpl"></ng-container>
    </div>
  </div>
</section>

<ng-template #blockContent>
  <ng-container
    *ngIf="
      items.length === 0;
      then emptyTpl ? emptyTpl : defaultEmptyTpl;
      else contentTpl
    "
  >
  </ng-container>
</ng-template>

<ng-template #defaultEmptyTpl>
  <h3 class="text-center">There are no any item here yet.</h3>
</ng-template>
