<form
  [formGroup]="form"
  class="form-horizontal form-label-left"
  (ngSubmit)="onSubmit()"
>
  <div class="form-group">
    <label class="control-label col-sm-4" for="code">Email</label>
    <div class="col-sm-6">
      <input
        formControlName="Email"
        [class.parsley-error]="form.controls['Email'].invalid && form.controls['Email'].dirty"
        type="email"
        id="code"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Email'].valid || form.controls['Email'].pristine"
      >
        <li [hidden]="!form.controls['Email'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['Email'].hasError('pattern')">
          Invalid email
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="name">Full Name</label>
    <div class="col-sm-6">
      <input
        type="text"
        id="name"
        formControlName="FullName"
        [class.parsley-error]="form.controls['FullName'].invalid && form.controls['FullName'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['FullName'].valid || form.controls['FullName'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="name">Role</label>
    <div class="col-sm-6">
      <select
        formControlName="Role"
        [class.parsley-error]="form.controls['Role'].invalid && form.controls['Role'].dirty"
        required
        class="form-control"
      >
        <option *ngFor="let role of Roles" [value]="role.value">
          {{ role.label }}
        </option>
      </select>
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Role'].valid || form.controls['Role'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group" *ngIf="forEdit">
    <label class="control-label col-sm-4" for="name">State</label>
    <div class="col-sm-6">
      <select
        formControlName="State"
        [class.parsley-error]="form.controls['State'].invalid && form.controls['State'].dirty"
        required
        class="form-control"
      >
        <option *ngFor="let state of States" [value]="state.value">
          {{ state.label }}
        </option>
      </select>
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['State'].valid || form.controls['State'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>
  <input
    *ngIf="!forEdit"
    type="hidden"
    formControlName="State"
    value="active"
  />

  <div class="form-group">
    <label class="control-label col-sm-4" for="team">Team</label>
    <div class="col-sm-6">
      <select formControlName="Team" class="form-control">
        <option *ngFor="let team of Teams" [value]="team.value">
          {{ team.label }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="capacity"
      >Chargeble Hrs. Capacity</label
    >
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="Capacity"
        id="capacity"
        step="0.1"
        class="form-control"
      />
    </div>
  </div>

  <div [hidden]="form.value.Team !== 'po'" class="form-group">
    <label class="control-label col-sm-4" for="capacity">
      Sales Capacity
    </label>
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="SalesCapacity"
        id="sales-capacity"
        step="0.1"
        class="form-control"
      />
    </div>
  </div>

  <div [hidden]="form.value.Team !== 'dev'">
    <div class="form-group">
      <label class="control-label col-sm-4" for="ckan-capacity"
        >CKAN Capacity (ave. SP/month)</label
      >
      <div class="col-sm-6">
        <input
          type="number"
          formControlName="CkanCapacity"
          id="ckan-capacity"
          step="0.1"
          class="form-control"
        />
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-4" for="drupal-capacity"
        >Drupal Capacity (ave. SP/month)</label
      >
      <div class="col-sm-6">
        <input
          type="number"
          formControlName="DrupalCapacity"
          id="drupal-capacity"
          step="0.1"
          class="form-control"
        />
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-4" for="js-capacity"
        >JS Capacity (ave. SP/month)</label
      >
      <div class="col-sm-6">
        <input
          type="number"
          formControlName="JsCapacity"
          id="js-capacity"
          step="0.1"
          class="form-control"
        />
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-4" for="other-capacity"
        >Other Capacity (ave. SP/month)</label
      >
      <div class="col-sm-6">
        <input
          type="number"
          formControlName="OtherCapacity"
          id="other-capacity"
          step="0.1"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="code">Jira ID</label>
    <div class="col-sm-6">
      <input
        formControlName="JiraId"
        [class.parsley-error]="form.controls['JiraId'].invalid && form.controls['JiraId'].dirty"
        type="email"
        id="code"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['JiraId'].valid || form.controls['JiraId'].pristine"
      >
        <li [hidden]="!form.controls['JiraId'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['JiraId'].hasError('pattern')">
          Invalid email
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-8 col-sm-offset-4">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="form.invalid || formPending"
      >
        Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</form>
