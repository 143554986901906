import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, from } from "rxjs";
import { tap, map } from "rxjs/operators";

import { MessengerService } from "./messenger.service";
import { LambdaService } from "./lambda.service";
import { AuthService } from "./auth.service";

import { HistoryRecord } from "../prototype/history-record.prototype";

@Injectable()
export class HistoryRecordService {
  protected history: { [key: string]: Array<HistoryRecord> } = {};

  constructor(
    protected http: HttpClient,
    protected lambda: LambdaService,
    protected auth: AuthService,
    protected messenger: MessengerService
  ) {}

  getHistory(
    id: string,
    force: boolean = false,
    limit?: number
  ): Observable<Array<HistoryRecord>> {
    if (force) {
      delete this.history[id];
    }
    if (id in this.history) {
      return from([this.history[id].slice(0, limit)]);
    }

    return this.fetchHistory(id).pipe(
      map((history: Array<HistoryRecord>) => history.slice(0, limit))
    );
  }

  private fetchHistory(id: string): Observable<Array<HistoryRecord>> {
    const request = this.http
      .get<HistoryRecord[]>([this.lambda.getRecordsUrl(), id].join("/"), {
        headers: new HttpHeaders({ authorization: this.auth.authToken }),
      })
      .pipe(tap((data) => (this.history[id] = data)));

    return request;
  }
}
