<form
  [formGroup]="form"
  class="form-horizontal form-label-left"
  (ngSubmit)="onSubmit()"
>
  <div class="form-group">
    <label class="control-label col-sm-4">Addon Name</label>
    <div class="col-sm-6">
      <select
        type="text"
        formControlName="AddonName"
        required
        class="form-control"
      >
        <option *ngFor="let name of collections.AddonNames" [value]="name">
          {{ name }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4">Start Date</label>
    <div class="col-sm-6">
      <input
        type="text"
        datetimepicker
        data-format="Y-MM-DD"
        formControlName="StartDate"
        [class.parsley-error]="form.controls['StartDate'].invalid && form.controls['StartDate'].dirty"
        (valueChanged)="form.controls['StartDate'].setValue($event)"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['StartDate'].valid || form.controls['StartDate'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4">End Date</label>
    <div class="col-sm-6">
      <input
        type="text"
        datetimepicker
        data-format="Y-MM-DD"
        formControlName="EndDate"
        [class.parsley-error]="form.controls['EndDate'].invalid && form.controls['EndDate'].value"
        (valueChanged)="form.controls['EndDate'].setValue($event)"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['EndDate'].valid || !form.controls['EndDate'].value"
      >
        <li [hidden]="!form.controls['EndDate'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['EndDate'].hasError('greater')">
          Must be greater than Start Date
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="billing-cycle"
      >Billing Cycle</label
    >
    <div class="col-sm-6">
      <select
        type="text"
        id="billing-cycle"
        formControlName="BillingCycle"
        required
        class="form-control"
      >
        <option *ngFor="let cycle of collections.BillingCycles" [value]="cycle">
          {{ cycle }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="status">Status</label>
    <div class="col-sm-6">
      <select
        type="text"
        id="status"
        formControlName="Status"
        required
        class="form-control"
      >
        <option *ngFor="let status of collections.Statuses" [value]="status">
          {{ status }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="invoice">Invoice Amount</label>
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="InvoiceAmount"
        id="invoice"
        [class.parsley-error]="form.controls['InvoiceAmount'].invalid && form.controls['InvoiceAmount'].dirty"
        required
        step="0.01"
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['InvoiceAmount'].valid || form.controls['InvoiceAmount'].pristine"
      >
        <li [hidden]="!form.controls['InvoiceAmount'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['InvoiceAmount'].hasError('positive')">
          Value must be greater than 0
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="separately"
      >Invoice Separately</label
    >
    <div class="col-sm-6">
      <input
        type="checkbox"
        formControlName="InvoiceSeparately"
        id="separately"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4">Next Invoice</label>
    <div class="col-sm-6">
      <input
        type="text"
        datetimepicker
        data-format="Y-MM-DD"
        formControlName="NextInvoiceDate"
        [class.parsley-error]="form.controls['NextInvoiceDate'].invalid && form.controls['NextInvoiceDate'].value"
        (valueChanged)="form.controls['NextInvoiceDate'].setValue($event)"
        class="form-control"
        [required]="form.controls['InvoiceSeparately'].value || false"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['NextInvoiceDate'].valid || !form.controls['NextInvoiceDate'].value"
      >
        <li [hidden]="!form.controls['NextInvoiceDate'].hasError('required')">
          This field is required
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-8 col-sm-offset-4">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="form.invalid || formPending"
      >
        Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</form>
