<h1 class="page-title">User Accounts</h1>

<section class="widget" (widgetReload)="reload()">
  <header>
    <h4>
      User Accounts
      <button
        type="button"
        class="btn btn-transparent btn-xs"
        data-toggle="modal"
        data-target="#user-form-modal"
        (click)="prepareForm()"
      >
        <i class="fa fa-plus"></i>
        Add User
      </button>
    </h4>

    <div class="widget-controls sppiner-vert-search">
      <a
        *ngIf="isUpdating && items?.length"
        class="animated fadeIn handle ui-sortable-handle"
      >
        <i class="fa fa-spinner fa-spin"></i>
      </a>
      <a data-widgster="refresh" title="Refresh"
        ><i class="glyphicon glyphicon-refresh"></i
      ></a>
    </div>
  </header>
  <div class="body">
    <div
      *ngIf="items?.length === null"
      class="loader animated fadeIn handle ui-sortable-handle"
    >
      <span class="spinner">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
    </div>
    <div *ngIf="items?.length === 0; else table">
      <h3 class="text-center">There are no any users here yet.</h3>
    </div>

    <ng-template #table>
      <mat-table [dataSource]="items">
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.Email }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="fullname">
          <mat-header-cell *matHeaderCellDef>Full Name</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.FullName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="role">
          <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ Roles[user.Role] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ States[user.State] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="team">
          <mat-header-cell *matHeaderCellDef>Team</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ Teams[user.Team] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="capacity">
          <mat-header-cell *matHeaderCellDef>Capacity</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.Capacity }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let user">
            <button
              title="Edit"
              class="btn btn-success btn-sm"
              (click)="edit(user)"
              data-toggle="modal"
              data-target="#user-form-modal"
            >
              <span class="glyphicon glyphicon-pencil"></span>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="[
            'email',
            'fullname',
            'role',
            'state',
            'team',
            'capacity',
            'actions'
          ]"
        ></mat-header-row>
        <mat-row
          *matRowDef="
            let user;
            columns: [
              'email',
              'fullname',
              'role',
              'state',
              'team',
              'capacity',
              'actions'
            ]
          "
        ></mat-row>
      </mat-table>

      <mat-paginator
        [length]="itemService.totalAmount"
        [pageSize]="pageLimit"
        [pageSizeOptions]="[10, 20, 50, 100]"
        [showFirstLastButtons]="true"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </ng-template>
  </div>
</section>

<modal-dialog
  [modalId]="'user-form-modal'"
  [heading]="formHeading"
  [broadcaster]="triggerBroadcaster"
>
  <user-form
    [broadcaster]="triggerBroadcaster"
    [model]="formModel"
    (formSaved)="afterSave()"
  ></user-form>
</modal-dialog>
