<h1 class="page-title">Resources</h1>

<section class="widget" (widgetReload)="reload()">
  <header>
    <h4>
      Resources
      <button
        *ngIf="canCreate()"
        type="button"
        class="btn btn-transparent btn-xs"
        data-toggle="modal"
        data-target="#resource-form-modal"
        (click)="prepareForm()"
      >
        <i class="fa fa-plus"></i>
        Add Resource
      </button>
    </h4>

    <div class="widget-controls sppiner-vert-search">
      <a
        *ngIf="isUpdating && items?.length"
        class="animated fadeIn handle ui-sortable-handle"
      >
        <i class="fa fa-spinner fa-spin"></i>
      </a>
      <a data-widgster="refresh" title="Refresh"
        ><i class="glyphicon glyphicon-refresh"></i
      ></a>
    </div>
  </header>
  <div class="body">
    <div
      *ngIf="items?.length === null"
      class="loader animated fadeIn handle ui-sortable-handle"
    >
      <span class="spinner">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
    </div>
    <div *ngIf="items?.length === 0; else table">
      <h3 class="text-center">There are no any resources here yet.</h3>
    </div>

    <ng-template #table>
      <mat-table [dataSource]="items">
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
          <mat-cell *matCellDef="let resource">{{ resource.Title }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="url">
          <mat-header-cell *matHeaderCellDef>URL</mat-header-cell>
          <mat-cell *matCellDef="let resource">
            <a href="{{ resource.Url }}" target="_blank">{{ resource.Url }}</a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let resource">
            <button
              *ngIf="0 && canCreate()"
              title="Edit"
              class="btn btn-success btn-sm"
              (click)="edit(resource)"
              data-toggle="modal"
              data-target="#resource-form-modal"
            >
              <span class="glyphicon glyphicon-pencil"></span>
            </button>
            <button
              *ngIf="canCreate()"
              title="Delete"
              class="btn btn-danger btn-sm"
              (click)="removedId = resource.Id!"
              data-toggle="modal"
              data-target="#resource-delete-modal"
            >
              <span class="glyphicon glyphicon-trash"></span>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['title', 'url', 'actions']"></mat-header-row>
        <mat-row
          *matRowDef="let resource; columns: ['title', 'url', 'actions']"
        ></mat-row>
      </mat-table>

      <mat-paginator
        [length]="itemService.totalAmount"
        [pageSize]="pageLimit"
        [pageSizeOptions]="[10, 20, 50, 100]"
        [showFirstLastButtons]="true"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </ng-template>
  </div>
</section>

<modal-dialog
  [modalId]="'resource-form-modal'"
  [heading]="formHeading"
  [broadcaster]="triggerBroadcaster"
>
  <resource-form
    [broadcaster]="triggerBroadcaster"
    [model]="formModel"
    (formSaved)="afterSave()"
  ></resource-form>
</modal-dialog>

<modal-dialog
  [modalId]="'resource-delete-modal'"
  heading="Confirm resource removal"
>
  <button data-dismiss="modal" class="btn btn-success">Cancel</button>
  <button class="btn btn-danger" (click)="remove(removedId)">Confirm</button>
</modal-dialog>
