import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ClientService } from '../service/client.service';
import { UserService } from '../service/user.service';
import { OpportunityService } from '../service/opportunity.service';
import { MessengerService } from '../service/messenger.service';
import { BroadcastService } from '../service/broadcast.service';
import { AuthService } from '../service/auth.service';

import { IOpportunity, Opportunity, StatusOptions } from '../prototype/opportunity.prototype';
import { IUser } from '../prototype';

import { AbstractEntityComponent } from '../prototype/entity-component';
import { map } from 'rxjs/operators';
import { JiraService } from '../service';
import { FormControl } from '@angular/forms';

@Component({
  templateUrl: 'opportunity.template.html',
})
export class OpportunityComponent extends AbstractEntityComponent<
  IOpportunity,
  Opportunity
> {
  protected formModel: Opportunity = new Opportunity();
  protected eventType: string = 'opportunity';

  clientCode!: string;
  clientName!: string;
  usersList: IUser[] = [];
  clientCodeToNameMapping = {};

  statusOptions = ["", ...StatusOptions];
  selectedStatuses: string[];

  constructor(
    protected authService: AuthService,
    private clientService: ClientService,
    private userService: UserService,
    protected itemService: OpportunityService,
    protected broadcastService: BroadcastService,
    private messengerService: MessengerService,
    private jira: JiraService,
    private route: ActivatedRoute
  ) {
    super();

    const defaultStatuses = this.statusOptions.filter(v => v!=="Prospective");
    const storedStatuses = window.localStorage.getItem('cm:product:filter:statuses')
    if (storedStatuses) {
      try {
        this.selectedStatuses = JSON.parse(storedStatuses)
      } catch (e) {
        this.selectedStatuses = defaultStatuses;
      }
    } else {
      this.selectedStatuses = defaultStatuses;
    }

  }

  canCreate(): boolean {
    return this.authService.isAdminOrEditor();
  }

  prepareForm(opportunity?: IOpportunity): void {
    this.formHeading = `${opportunity ? 'Update' : 'Create'} Product`;
    this.formModel = opportunity || new Opportunity(this.clientCode || '');
    super.prepareForm(opportunity);
  }

  afterSave(): void {
    this.formModel = new Opportunity();
    this.pull(true);
    super.afterSave();
  }

  edit(opportunity: IOpportunity) {
    this.prepareForm(opportunity);
    super.edit(opportunity);
  }

  ngOnInit(): void {
    this.clientCode = this.route.snapshot.params['clientCode'];
    this.clientService
      .getAll()
      .pipe(
        map((clients) =>
          clients.reduce((mapping, client) => {
            mapping[client.ClientCode] = client.ClientName;
            return mapping;
          }, {})
        )
      )
      .subscribe((result) => {
        this.clientCodeToNameMapping = result;
      });
    this.userService
      .getAll()
      .pipe(map((all) => all.filter((u) => u.State === 'active')))
      .subscribe(
        (usersList: IUser[]) =>
          (this.usersList = usersList.sort(({ Email: a }, { Email: b }) => {
            if (a === b) return 0;
            if (a === this.authService.email) return -1;
            if (b === this.authService.email) return 1;
            return a > b ? 1 : -1;
          }))
      );
    if (this.clientCode) {
      this.itemService.setFilter({ ClientCode: this.clientCode, Status: this.selectedStatuses });
      this.updateClientName(this.clientCode);
    } else {
      this.itemService.setFilter({Status: this.selectedStatuses});
    }
    super.ngOnInit();
  }

  getJiraLink(opportunity: IOpportunity): string {
    return this.jira.jiraLink + opportunity.JiraTitle;
  }

  updateClientName(clientCode: string): void {
    this.clientService
      .getClientName(clientCode)
      .subscribe((clientName: string) => (this.clientName = clientName));
  }

  statusChanged(value: string[]) {
    window.localStorage.setItem('cm:product:filter:statuses', JSON.stringify(value))
    this.selectedStatuses = value;
    this.itemService.patchFilter({Status: value})

    this.searchClient(this.clientQ, [this.singleUser, false])
  }
}
