<form
  [formGroup]="form"
  class="form-horizontal form-label-left"
  (ngSubmit)="onSubmit()"
>
  <div class="form-group">
    <div class="clearfix">
      <label class="control-label col-sm-4">Credited</label>
      <div class="col-sm-6">
        <div class="pull-left box-inline">
          <select
            formControlName="Month"
            (changeValue)="monthChanged($event)"
            [value]="form.value.Month"
            type="text"
            class="form-control select2"
            required
          >
            <option
              *ngFor="let month of collections.getMonths"
              [value]="month"
              [selected]="month === form.value.Month"
            >
              {{ month }}
            </option>
          </select>
          <ul
            class="parsley-error-list"
            [hidden]="!form.controls['Month'].hasError('required')"
          >
            <li>This field is required</li>
          </ul>
        </div>
        <div class="pull-left box-inline">
          <select
            type="text"
            formControlName="Year"
            required
            class="form-control"
          >
            <option
              *ngFor="let year of collections.getYearsRange"
              [value]="year"
            >
              {{ year }}
            </option>
          </select>
          <ul
            class="parsley-error-list"
            [hidden]="!form.controls['Year'].hasError('required')"
          >
            <li>This field is required</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4"
      >{{ (type === 'profited' ? 'unused' : type) | titlecase }} Hours</label
    >
    <div class="col-sm-6">
      <input
        formControlName="Hours"
        type="number"
        step="0.01"
        value=""
        class="form-control"
        required
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Hours'].valid || !form.controls['Hours'].value"
      >
        <li [hidden]="!form.controls['Hours'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['Hours'].hasError('positive')">
          Value must be greater than 0
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf=" type === 'purchased' " class="form-group">
    <div class="clearfix">
      <label class="control-label col-sm-4">Expires</label>
      <div class="col-sm-6">
        <div class="pull-left box-inline">
          <select
            formControlName="ExpireMonth"
            id="expire-month-list"
            (changeValue)="expireMonthChanged($event)"
            [value]="form.value.ExpireMonth"
            type="text"
            id="expire-month"
            class="form-control select2"
          >
            <option
              *ngFor="let month of collections.getMonths"
              [value]="month"
              [selected]="month === form.value.ExpireMonth"
            >
              {{ month }}
            </option>
          </select>
          <ul
            class="parsley-error-list"
            [hidden]="form.controls['ExpireYear'].valid"
          >
            <li>Select expire month</li>
          </ul>
        </div>
        <div class="pull-left box-inline">
          <select
            type="text"
            id="expire-year-list"
            formControlName="ExpireYear"
            class="form-control"
          >
            <option
              *ngFor="let year of collections.getYearsRange"
              [value]="year"
            >
              {{ year }}
            </option>
          </select>
          <ul
            class="parsley-error-list"
            [hidden]="form.controls['ExpireMonth'].valid"
          >
            <li>Select expire year</li>
          </ul>
        </div>
        <div class="pull-left">
          <button
            class="btn btn-primary"
            type="button"
            (click)="removeExpire()"
          >
            <i class="glyphicon glyphicon-remove"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" *ngIf=" type === 'purchased' ">
    <label class="control-label col-sm-4" for="hours-status">Status</label>
    <div class="col-sm-6">
      <select
        type="text"
        id="hours-status"
        formControlName="Status"
        required
        class="form-control"
      >
        <option *ngFor="let item of statuses" [ngValue]="item[0]">
          {{ item[1] }}
        </option>
      </select>
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Status'].valid || form.controls['Status'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group" *ngIf=" type === 'purchased' ">
    <label class="control-label col-sm-4" for="milestone-due-date"
      >Due Date</label
    >
    <div class="col-sm-6">
      <input
        type="text"
        id="milestone-due-date"
        datetimepicker
        data-format="Y-MM-DD"
        formControlName="DueDate"
        [class.parsley-error]="form.controls['DueDate'].invalid && form.controls['DueDate'].dirty"
        (valueChanged)="form.controls['DueDate'].setValue($event)"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['DueDate'].valid || form.controls['DueDate'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div *ngIf=" type === 'purchased' " class="form-group">
    <label class="control-label col-sm-4">Commissionable</label>
    <div class="col-sm-6">
      <select
        type="text"
        formControlName="Commissionable"
        required
        class="form-control"
      >
        <option [ngValue]="false" [selected]="!form.value.Commissionable">
          No
        </option>
        <option [ngValue]="true" [selected]="form.value.Commissionable">
          Yes
        </option>
      </select>
    </div>
  </div>

  <div *ngIf=" type === 'purchased' " class="form-group">
    <label class="control-label col-sm-4">Hourly Rate</label>
    <div class="col-sm-6">
      <input formControlName="HourlyRate" type="number" class="form-control" />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['HourlyRate'].valid || !form.controls['HourlyRate'].value"
      >
        <li [hidden]="!form.controls['HourlyRate'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['HourlyRate'].hasError('positive')">
          Value must be greater than 0
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group" *ngIf="!isUnforeseen()">
    <label class="control-label col-sm-4">Value (in AUD)</label>
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="Value"
        [class.parsley-error]="form.controls['Value'].invalid && form.controls['Value'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Value'].valid || form.controls['Value'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div *ngIf=" type === 'purchased' " class="form-group">
    <label class="control-label col-sm-4" for="hours-currency">Currency</label>
    <div class="col-sm-6">
      <select
        type="text"
        id="hours-currency"
        formControlName="Currency"
        required
        class="form-control"
      >
        <option [ngValue]="'AUD'" [selected]="form.value.Currency === 'AUD'">
          AUD
        </option>
        <option [ngValue]="'USD'" [selected]="form.value.Currency === 'USD'">
          USD
        </option>
        <option [ngValue]="'EURO'" [selected]="form.value.Currency === 'EURO'">
          EURO
        </option>
        <option [ngValue]="'NZD'" [selected]="form.value.Currency === 'NZD'">
          NZD
        </option>
        <option [ngValue]="'CAD'" [selected]="form.value.Currency === 'CAD'">
          CAD
        </option>
      </select>
    </div>
  </div>
  <div class="form-group" [hidden]="form.value.Currency === 'AUD'">
    <label class="control-label col-sm-4">
      Exchange Rate
      <br />
      <sub> (1 AUD equals ... {{ form.value.Currency }}) </sub>
    </label>
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="Rate"
        [class.parsley-error]="form.controls['Rate'].invalid && form.controls['Rate'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Rate'].valid || form.controls['Rate'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="hours-notes-{{ usingService }}"
      >Notes</label
    >
    <div class="col-sm-6">
      <textarea
        rows="3"
        class="autogrow form-control transition-height"
        formControlName="Notes"
        id="hours-notes-{{ usingService }}-{{ type }}"
        placeholder="Try to add few new lines.."
      ></textarea>
      <ul
        class="parsley-error-list"
        [hidden]="!form.controls['Notes'].hasError('required')"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group" *ngIf="type === 'purchased'">
    <label
      class="control-label col-sm-4"
      for="hours-project-id-{{ usingService }}-{{ type }}"
      >Project</label
    >
    <div class="col-sm-6">
      <select
        type="text"
        id="hours-project-id-{{ usingService }}-{{ type }}"
        formControlName="ProjectId"
        class="form-control"
      >
        <option *ngFor="let item of projects" [ngValue]="item.Id">
          {{ item.Title }}
        </option>
      </select>
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['ProjectId'].valid || form.controls['ProjectId'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group" *ngIf="type === 'purchased'">
    <label class="control-label col-sm-4">Purchase Order</label>
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="PurchaseOrder"
        [class.parsley-error]="form.controls['PurchaseOrder'].invalid && form.controls['PurchaseOrder'].dirty"
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['PurchaseOrder'].valid || form.controls['PurchaseOrder'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-8 col-sm-offset-4">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="form.invalid || formPending"
      >
        Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</form>
