import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import {
  ClientAccountRoutingModule,
  routes,
} from './client-account-routing.module';
import { ClientAccountComponent } from './client-account.component';
import { ClientAccountDetailComponent } from './client-account-detail.component';
import { DirectiveModule } from '../directive/directive.module';
import { ComponentModule } from '../components/component.module';

import { ClientResolver } from './client-resolver';
import { ResourceRequestsModule } from '../resource-requests/resource-requests.module';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  imports: [
    CommonModule,
    ClientAccountRoutingModule,
    DirectiveModule,
    ComponentModule,
    HttpClientModule,

    ResourceRequestsModule,
    MatPaginatorModule,
  ],
  declarations: [ClientAccountComponent, ClientAccountDetailComponent],
  providers: [ClientResolver],
})
export class ClientAccountModule {}
