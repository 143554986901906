import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [RouterModule, CommonModule],
  selector: 'sidebar-link',
  template: `
    <li
      [class.active]="rla.isActive"
      routerLinkActive
      #rla="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: exact }"
    >
      <a [routerLink]="[link]">
        <i *ngIf="icon" class="fa fa-{{ icon }}"></i>
        <span class="name"><ng-content></ng-content></span>
      </a>
    </li>
  `,
})
export class SidebarLinkComponent {
  @Input() link: string = '';
  @Input() icon!: string;
  @Input() exact: boolean = false;
}
