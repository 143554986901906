import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import {
  JiraService,
  BroadcastService,
  AuthService,
  OpportunityService,
  ClientService,
  AgreementService,
} from '../service';

import { Opportunity } from '../prototype/opportunity.prototype';

import { AbstractDetailComponent } from '../prototype/entity-detail-component';
import { Client } from '../components/client-form/client-account';
import { Agreement } from '../prototype/agreement.prototype';
import { filter, map } from 'rxjs';

@Component({
  templateUrl: 'opportunity-detail.template.html',
})
export class OpportunityDetailComponent
  extends AbstractDetailComponent<Opportunity>
  implements OnInit
{
  protected eventType: string = 'opportunity-details';
  protected model!: Opportunity;
  protected isHoursRemoving: boolean = false;
  protected clientName!: string;
  protected client: Client | null = null;
  protected agreements: Agreement[] = [];

  removeHoursType?: string;
  removeHoursOptions: any;
  completedSp = 0;
  projectValue = 0;

  constructor(
    protected authService: AuthService,
    protected itemService: OpportunityService,
    protected route: ActivatedRoute,
    protected location: Location,
    protected broadcastService: BroadcastService,
    protected jira: JiraService,
    protected clientService: ClientService,
    protected agreementService: AgreementService,
  ) {
    super();
  }
  ngOnInit() {
    super.ngOnInit();

    this.entity = this.entity!;

    this.jira
      .getProjectSpCompleted(this.entity['JiraTitle'])
      .subscribe(({ Done }) => (this.completedSp = Done), console.warn);
    this.projectValue =
      (this.entity['PurchasedHours'] || []).reduce(
        (total: number, item: any) =>
          item.Status === 20 ? total + item.Hours * item.HourlyRate : total,
        0
      ) +
      (this.entity['Milestones'] || []).reduce(
        (total: number, item: any) =>
          item.Status === 20 ? total + item.Value : total,
        0
      );

    if (this.route.snapshot.params['edit-form']) {
      this.model = this.entity;
      setTimeout(
        () =>
          this.broadcastService.next([
            this.eventType,
            'show:opportunity-form-modal',
          ]),
        2000
      );
    }
    this.clientService
      .getByUniqueKey(this.entity.ClientCode)
      .subscribe((client) => {
        this.clientName = client?.ClientName || "";
        this.client = client
      });
    this.agreementService.getAll().pipe(
      map(agreements => agreements.filter(item => item.ClientCode === this.entity?.ClientCode))
    ).subscribe(agreements => this.agreements = agreements)
  }

  getJiraLink(opportunity: Opportunity): string {
    return this.jira.jiraLink + opportunity.JiraTitle;
  }

  afterSave(): void {
    super.afterSave();

    this.reloadEntity();
  }

  removeHours(options: any[]) {
    const id = this.entity?.JobNumber!;
    this.isHoursRemoving = true;
    console.debug('Removing hours of %s with options %o', id, options);
    const [idx, type, items] = options;
    const target = items[idx];
    this.itemService.removeHours(id, type, target.Id).subscribe(() => {
      this.afterSave();
    });
  }
  private reloadEntity(): void {
    this.itemService.reset();
    this.itemService
      .getByUniqueKey(this.entity?.JobNumber!)
      .subscribe((entity: Opportunity | null) => {
        this.entity = entity!;
        this.isHoursRemoving = false;
      });
  }
}
