import { NgModule } from '@angular/core';
import { PagesRoutingModule } from './pages-routing.module';

import { DashboardResolver } from './dashboard/dashboard-resolver';

@NgModule({
  declarations: [],
  imports: [PagesRoutingModule],
  providers: [DashboardResolver],
})
export class PagesModule {}
