import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "sumBy" })
export class SumByPipe implements PipeTransform {
  transform(items: any[] | void, column: any, initial = 0): number {
    if (!items) {
      return initial;
    }
    return items.reduce(
      (accum, { [column]: next }) => accum + (+next || 0),
      initial
    );
  }
}
