<modal-dialog
  modalId="reservation-agreement-popup"
  heading="Do any reservations for this agreement need to be renewed?"
  [withControls]="true"
  (acceptModal)="makeReservation()"
  cancel-text="No"
  confirm-text="Yes"
>
  <form
    class="form-horizontal form-label-left"
    name="purchased-hours-update-form"
  >
    <div class="form-group">
      <label class="control-label col-sm-4" for="purchased-hours-notes"
        >Notes</label
      >
      <div class="col-sm-6" id="purchased-hours-notes" name="notes">
        <textarea
          class="form-control"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="notes"
        ></textarea>
      </div>
    </div>
  </form>
</modal-dialog>
