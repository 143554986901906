<ul id="side-nav" class="side-nav">
  <sidebar-link link="dashboard" icon="home">Dashboard</sidebar-link>
  <sidebar-link link="client-accounts" icon="user"
    >Client Accounts</sidebar-link
  >
  <sidebar-link link="products" icon="bell">Products</sidebar-link>
  <sidebar-link link="agreements" icon="money">Agreements</sidebar-link>
  <sidebar-link link="projects" icon="cubes">Active Projects</sidebar-link>
  <sidebar-link link="resources" icon="book">Resources</sidebar-link>
  <sidebar-link link="resource-requests" icon="cart-plus"
    >Resource Requests</sidebar-link
  >
  <sidebar-link-collection
    *ngIf="auth.isAdminOrEditor()"
    label="Admin"
    icon="briefcase"
  >
    <sidebar-link *ngIf="auth.isAdmin()" link="users" icon="user"
      >Users</sidebar-link
    >
    <sidebar-link *ngIf="auth.isAdminOrEditor()" link="hours" icon="clock-o"
      >Hours</sidebar-link
    >
    <sidebar-link *ngIf="auth.isAdmin()" link="commission" icon=""
      >Commission</sidebar-link
    >
  </sidebar-link-collection>
</ul>
