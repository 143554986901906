<h1 class="page-title">Projects</h1>

<lcm-listing
  heading="Projects"
  [canCreate]="canCreate()"
  [canRefresh]="false"
  [isUpdating]="isUpdating"
  [items]="items"
  [contentTpl]="contentTpl"
  addModalTarget="#project-form-modal"
  (refresh)="reload()"
  (add)="prepareForm()"
>
  <ng-template #contentTpl>
    <button
      *ngIf="useMilestones() && authService.isAdminOrEditor()"
      type="button"
      class="btn btn-transparent btn-xs add-addon-button"
      data-toggle="modal"
      data-target="#milestone-form-modal"
      (click)="activatedMilestone.milestone = {}"
    >
      Milestones
      <i class="glyphicon glyphicon-plus-sign"></i>
    </button>
    <span>&nbsp;</span>
    <button
      *ngIf="authService.isAdminOrEditor()"
      type="button"
      class="btn btn-transparent btn-xs add-purchased-hours-button"
      data-toggle="modal"
      data-target="#purchased-form-modal"
      (click)="
        invoiceHoursOptions = {
          options: [],
          ownerId: projectOwner,
          ownerType: ownerType
        }
      "
    >
      Purchased Hours
      <i class="glyphicon glyphicon-plus-sign"></i>
    </button>

    <table id="datatable-table" class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
          <th>Type</th>
          <th>Purchase Order</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let project of items">
          <tr>
            <td>{{ project.Title }}</td>
            <td>{{ project.Description }}</td>
            <td>
              <ng-container
                *ngIf="
                  !project.Id ||
                    (project.PurchasedHours.length == 0 &&
                      project.Milestones.length === 0);
                  else project_type
                "
                >{{ project.Id ? "Anything" : "Main Project" }}</ng-container
              >
              <ng-template #project_type>
                <ng-container *ngIf="project.PurchasedHours.length > 0"
                  >Purchased Hours</ng-container
                >
                <ng-container *ngIf="project.Milestones.length > 0"
                  >Milestones</ng-container
                >
              </ng-template>
            </td>
            <td>{{ project.PurchaseOrder }}</td>
            <td>
              {{
                project.StartDate &&
                  (project.StartDate | moment).format("MMM DD, YYYY")
              }}
            </td>
            <td>
              {{
                project.EndDate &&
                  (project.EndDate | moment).format("MMM DD, YYYY")
              }}
            </td>
            <td>
              <button
                *ngIf="project.Id && canCreate()"
                title="Edit"
                class="btn btn-success btn-xs"
                (click)="edit(project)"
                data-toggle="modal"
                data-target="#project-form-modal"
              >
                <span class="glyphicon glyphicon-pencil"></span>
              </button>
              <button
                *ngIf="project.Id && canDelete()"
                title="Delete"
                class="btn btn-danger btn-xs"
                (click)="removedId = project.Id"
                data-toggle="modal"
                data-target="#project-delete-modal"
              >
                <span class="glyphicon glyphicon-trash"></span>
              </button>
              <button
                *ngIf="
                  project.Milestones.length || project.PurchasedHours.length
                "
                title="Toggle"
                class="btn btn-link btn-xs"
                (click)="expandRow(project.Id)"
              >
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-caret-down': !expandedRows[project.Id!],
                    'fa-caret-up': expandedRows[project.Id!]
                  }"
                ></i>
              </button>
            </td>
          </tr>
          <tr
            *ngIf="
              expandedRows[project.Id!] &&
              (project.Milestones.length || project.PurchasedHours.length)
            "
          >
            <td colspan="7">
              <lcm-milestones
                *ngIf="project.Milestones.length"
                [ownerType]="ownerType"
                [ownerId]="projectOwner"
                [milestones]="project.Milestones || []"
                [project]="project"
                (activate)="activateMilestone($event)"
              ></lcm-milestones>
              <lcm-purchased-hours
                *ngIf="project.PurchasedHours.length"
                [ownerType]="ownerType"
                [ownerId]="projectOwner"
                [hours]="project.PurchasedHours!"
                [project]="project"
                (update)="invoiceHoursOptions = $event"
                (remove)="invoiceHoursOptions = $event"
              ></lcm-purchased-hours>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-template>
</lcm-listing>

<project-modals
  [formModel]="formModel"
  (remove)="remove(removedId)"
  (save)="afterSave()"
></project-modals>

<milestone-modals
  *ngIf="useMilestones()"
  (update)="milestoneUpdated.emit()"
  (remove)="milestoneDeleted.emit()"
  (save)="milestoneAdded.emit()"
  [ownerType]="ownerType"
  [ownerId]="projectOwner"
  [activated]="activatedMilestone"
  [projects]="projectsForMilestones"
></milestone-modals>

<hours-modals
  hoursType="purchased"
  [commisionable]="commisionable"
  [invoiceHoursOptions]="invoiceHoursOptions.options"
  [ownerType]="ownerType"
  [ownerId]="projectOwner"
  (save)="hoursSaved.emit()"
  (update)="hoursSaved.emit()"
  (remove)="hoursRemoved.emit()"
  [projects]="projectsForPurchasedHours"
></hours-modals>
