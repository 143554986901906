import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { JiraService } from '../../../service/jira.service';
import { BroadcastService } from '../../../service/broadcast.service';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { saveAs } from 'file-saver';

@Component({
  templateUrl: 'csv-export-form.template.html',
  selector: 'csv-export-form',
})
export class CsvExportComponent implements OnInit {
  public form = this.formBuilder.group({
    fromDate: ['', [Validators.required, this.fromDateValidator]],
    tillDate: ['', [Validators.required, this.tillDateValidator]],
  });
  protected eventType: string = 'hours-export';
  protected triggerBroadcaster!: Observable<string>;

  @Input() jiraId!: string;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private jiraService: JiraService,
    private broadcastService: BroadcastService
  ) {}

  ngOnInit(): void {
    this.triggerBroadcaster = this.broadcastService.getObservable().pipe(
      filter(([type]: string[]) => type === this.eventType),
      map(([, event]: string[]) => event)
    );
  }

  tillDateValidator(control: AbstractControl): { [key: string]: any } | null {
    if (!control.parent) return null;
    const end_val = control.value;

    const start_val = control.parent.controls['fromDate'].value;
    if (!start_val || !end_val) return null;

    let res =
      new Date(end_val) > new Date(start_val)
        ? null
        : { greater: { val: end_val } };
    return res;
  }

  fromDateValidator(control: AbstractControl): { [key: string]: any } | null {
    if (!control.parent) return null;
    control.parent.controls['tillDate'].updateValueAndValidity();
    return null;
  }

  onSubmit() {
    const id = this.jiraId;
    const data = {
      from_date: this.form.controls['fromDate'].value,
      till_date: this.form.controls['tillDate'].value,
    };

    this.jiraService.downloadCsv(id, data).subscribe(
      (response) => {
        let file = new Blob([response], { type: 'text/csv' });
        saveAs(file, `${id}_time_report.csv`);
      },
      (error) => {
        console.error(error);
      }
    );

    // Close the form and reset it's content
    this.broadcastService.next([this.eventType, 'close']);
    this.form.reset();
  }
}
