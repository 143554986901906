import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

import { MessengerService } from "./messenger.service";
import { LambdaService } from "./lambda.service";
import { FetchAbstractService } from "./fetch-abstract.service";
import { AuthService } from "./auth.service";

import { IClient } from "../prototype/client.prototype";

@Injectable()
export class ClientService extends FetchAbstractService<IClient> {
  protected uniqueKeyName: string = "ClientCode";

  constructor(
    protected http: HttpClient,
    protected lambda: LambdaService,
    protected auth: AuthService,
    protected messenger: MessengerService
  ) {
    super();
    this.defaultOrder = "Asc";
    this.defaultOrderColumn = "ClientCode";
    this.filterBy = ["ClientName", "ClientCode"];
  }

  canCreate(): boolean {
    return this.auth.isAdminOrEditor();
  }

  getLoadAllUrl(): string {
    return this.lambda.getClientsUrl();
  }

  getSaveUrl(forEdit: boolean = false): string {
    return this.lambda.getClientsUrl();
  }

  getEditUrl(client: IClient): string {
    return this.getSaveUrl() + "/" + client.ClientCode;
  }

  getLoadAllErrorMsg(): string {
    return "List of clients is unavailable";
  }

  getClientName(clientCode: string): Observable<string> {
    let clientName = this.getAll().pipe(
      map(
        (clients: IClient[]) =>
          clients.filter((c: IClient) => c.ClientCode === clientCode)[0]
      ),
      map((client: IClient) => (client.ClientName ))
    );

    return clientName;
  }

  getClientCodes(): Observable<Set<string>> {
    return this.getAll().pipe(
      map(
        (items: IClient[]) =>
          new Set(items.map((item: IClient) => item.ClientCode))
      )
    );
  }
  getCommissionableCodes(): Observable<Set<string>> {
    return this.getAll().pipe(
      map(
        (items: IClient[]) =>
          new Set(
            items
              .filter((item: IClient) => item.Commissionable)
              .map((item: IClient) => item.ClientCode)
          )
      )
    );
  }
}
