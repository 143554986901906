import { Pipe, PipeTransform } from '@angular/core';
import { IProject } from './projects';

@Pipe({ name: 'toBeInvoicedTotal', standalone: true })
export class ToBeInvoicedTotal implements PipeTransform {
  transform(items: any[]): number {
    return items
      .filter((p) => p['_notInvoiced'])
      .map((p) => p['_toBeInvoiced'] || 0)
      .reduce((sum, n) => sum + n, 0);
  }
}

@Pipe({ name: 'overdueInvoices', standalone: true })
export class OverdueInvoices implements PipeTransform {
  transform(items: any[]): number {
    return items
      .map((p) => p['_overdueInvoices'] || 0)
      .reduce((sum, n) => sum + n, 0);
  }
}

@Pipe({ name: 'upcomingInvoices', standalone: true })
export class UpcomingInvoices implements PipeTransform {
  transform(items: any[], start: any, end: any): number {
    const invoices = items
      .flatMap((p) => p['_invoices'] || [])
      .filter(
        ({ Status, DueDate }) =>
          +Status !== 20 && DueDate >= start && DueDate <= end
      );

    return invoices.reduce(
      (sum, { Value = 0, Currency, Rate }) =>
        sum + (Currency === 'AUD' ? Value : Value / Rate),
      0
    );
  }
}
