<modal-dialog
  [modalId]="'csv-export-form-modal'"
  heading="Export time reports"
  [broadcaster]="triggerBroadcaster"
>
  <form
    [formGroup]="form"
    class="form-horizontal form-label-left"
    (ngSubmit)="onSubmit()"
  >
    <div class="form-group">
      <label class="control-label col-sm-4" for="csv-start-date"
        >Start Date</label
      >
      <div class="col-sm-6">
        <input
          type="text"
          id="csv-start-date"
          datetimepicker
          data-format="Y-MM-DD"
          formControlName="fromDate"
          (valueChanged)="form.controls.fromDate.setValue($event)"
          required
          class="form-control"
        />
        <ul
          class="parsley-error-list"
          [hidden]="form.controls.fromDate.valid || form.controls.fromDate.pristine"
        >
          <li>This field is required</li>
        </ul>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-4" for="csv-end-date">End Date</label>
      <div class="col-sm-6">
        <input
          type="text"
          id="csv-end-date"
          datetimepicker
          data-format="Y-MM-DD"
          formControlName="tillDate"
          (valueChanged)="form.controls.tillDate.setValue($event)"
          required
          class="form-control"
        />
        <ul
          class="parsley-error-list"
          [hidden]="form.controls.tillDate.valid || !form.controls.tillDate.value"
        >
          <li [hidden]="!form.controls.tillDate.hasError('required')">
            This field is required
          </li>
          <li [hidden]="!form.controls.tillDate.hasError('greater')">
            Must be greater than From Date
          </li>
        </ul>
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-8 col-sm-offset-4">
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="(!form.controls.fromDate || form.invalid )"
        >
          Export
        </button>
      </div>
    </div>
  </form>
</modal-dialog>
