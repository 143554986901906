import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { OpportunityComponent } from "./opportunity.component";
import { OpportunityDetailComponent } from "./opportunity-detail.component";
import { OpportunityResolver } from "./opportunity-resolver";

export const routes: Routes = [
  { path: "", component: OpportunityComponent, pathMatch: "full" },
  { path: ":clientCode", component: OpportunityComponent, pathMatch: "full" },
  {
    path: "view/:entityId",
    component: OpportunityDetailComponent,
    pathMatch: "full",
    resolve: {
      entity: OpportunityResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OpportunityRoutingModule {}
