import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  IUser,
  User,
  States,
  Roles,
  Teams,
  Capacities,
} from '../../prototype/user.prototype';

import {
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';

import { AbstractEntityFormComponent } from '../../prototype/entity-form.component';

import { MessengerService } from '../../service/messenger.service';
import { UserService } from '../../service/user.service';

import { Observable } from 'rxjs';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.template.html',
})
export class UserFormComponent extends AbstractEntityFormComponent<
  IUser,
  User
> {
  @Input()
  set model(item: User) {
    this.modelInstance = item;
    this.initForm();
  }

  @Input() broadcaster!: Observable<string>;

  @Output() formSaved = new EventEmitter<void>();

  States = Object.keys(States).map((k) => ({
    value: k,
    label: States[k],
  }));
  Roles = Object.keys(Roles).map((k) => ({
    value: k,
    label: Roles[k],
  }));
  Teams = Object.keys(Teams).map((k) => ({
    value: k,
    label: Teams[k],
  }));

  constructor(
    protected messengerService: MessengerService,
    private formBuilder: FormBuilder,
    protected itemService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscription = this.broadcaster.subscribe((event: string) => {
      switch (event) {
        case 'edit':
          (<FormControl>this.form.controls['Email']).disable();
          this.forEdit = true;
          break;
        case 'prepare':
          (<FormControl>this.form.controls['Email']).enable();
          this.forEdit = false;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      Email: ['', [Validators.required]],
      FullName: ['', [Validators.required]],
      Role: ['', [Validators.required]],
      State: ['', [Validators.required]],
      Team: ['', []],
      Capacity: ['', []],
      SalesCapacity: ['', []],
      CkanCapacity: ['', []],
      DrupalCapacity: ['', []],
      JsCapacity: ['', []],
      OtherCapacity: ['', []],
      JiraId: ['', []],
    });

    let data = {
      Email: '',
      FullName: '',
      Role: '',
      State: '',
      Team: '',
      Capacity: '',
      SalesCapacity: Capacities.Sales,
      CkanCapacity: Capacities.Ckan,
      DrupalCapacity: Capacities.Drupal,
      JsCapacity: Capacities.Js,
      OtherCapacity: Capacities.Other,
      JiraId: '',
    };
    this.cleanEmpty(Object.assign(data, this.modelInstance), '');
    delete data['at'];
    this.form.setValue(data);
  }
}
