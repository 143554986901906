<form
  [formGroup]="form"
  class="form-horizontal form-label-left"
  (ngSubmit)="onSubmit()"
>
  <div class="form-group">
    <label class="control-label col-sm-4" for="agreement-client-code"
      >Client Code</label
    >
    <div class="col-sm-6">
      <select
        formControlName="ClientCode"
        id="agreement-client-code"
        (changeValue)="clientCodeChanged($event)"
        [value]="form.value.ClientCode"
        type="text"
        id="code"
        class="form-control select2"
        required
      >
        <option value="" [selected]="!form.value.ClientCode"></option>
        <option
          *ngFor="let code of clientCodes"
          [selected]="code === form.value.ClientCode"
          [value]="code"
        >
          {{ code }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="job-number"
      >Product Number</label
    >
    <div class="col-sm-6">
      <select
        formControlName="JobNumber"
        id="job-number"
        (changeValue)="jobNumberChanged($event)"
        [value]="form.value.JobNumber"
        type="text"
        class="form-control select2"
      >
        <option value="" [selected]="!form.value.JobNumber"></option>
        <option
          *ngFor="let job of jobNumbers"
          [selected]="job === form.value.JobNumber"
          [value]="job"
        >
          {{ job }}
        </option>
      </select>
    </div>
  </div>

  <div *ngIf="form.controls['AgreementNumber'].value" class="form-group">
    <label class="control-label col-sm-4">Agreement Number</label>
    <div class="col-sm-6">
      <input
        type="text"
        formControlName="AgreementNumber"
        required
        class="form-control"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="service-type"
      >Service Type</label
    >
    <div class="col-sm-6">
      <select
        type="text"
        id="service-type"
        formControlName="ServiceType"
        required
        class="form-control"
      >
        <option
          *ngFor="let service of collections.ServiceTypes"
          [value]="service"
        >
          {{ service }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="agreement-start-date"
      >Start Date</label
    >
    <div class="col-sm-6">
      <input
        type="text"
        id="agreement-start-date"
        datetimepicker
        data-format="Y-MM-DD"
        formControlName="StartDate"
        [class.parsley-error]="form.controls['StartDate'].invalid && form.controls['StartDate'].dirty"
        (valueChanged)="form.controls['StartDate'].setValue($event)"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['StartDate'].valid || form.controls['StartDate'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="agreement-end-date"
      >End Date</label
    >
    <div class="col-sm-6">
      <input
        type="text"
        id="agreement-end-date"
        datetimepicker
        data-format="Y-MM-DD"
        formControlName="EndDate"
        [class.parsley-error]="form.controls['EndDate'].invalid && form.controls['EndDate'].value"
        (valueChanged)="form.controls['EndDate'].setValue($event)"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['EndDate'].valid || !form.controls['EndDate'].value"
      >
        <li [hidden]="!form.controls['EndDate'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['EndDate'].hasError('greater')">
          Must be greater than Start Date
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="billing-cycle"
      >Billing Cycle</label
    >
    <div class="col-sm-6">
      <select
        type="text"
        id="billing-cycle"
        formControlName="BillingCycle"
        required
        class="form-control"
      >
        <option *ngFor="let cycle of collections.BillingCycles" [value]="cycle">
          {{ cycle }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="agreement-status">Status</label>
    <div class="col-sm-6">
      <select
        type="text"
        id="agreement-status"
        formControlName="Status"
        required
        class="form-control"
      >
        <option *ngFor="let status of collections.Statuses" [value]="status">
          {{ status }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="invoice"
      >Total Contract Cost</label
    >
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="InvoiceAmount"
        id="invoice"
        step="0.01"
        [class.parsley-error]="form.controls['InvoiceAmount'].invalid && form.controls['InvoiceAmount'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['InvoiceAmount'].valid || form.controls['InvoiceAmount'].pristine"
      >
        <li [hidden]="!form.controls['InvoiceAmount'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['InvoiceAmount'].hasError('positive')">
          Value must be greater than 0
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="hosting-amount"
      >Infrastructure Amount</label
    >
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="HostingAmount"
        id="hosting-amount"
        step="0.01"
        [class.parsley-error]="form.controls['HostingAmount'].invalid && form.controls['HostingAmount'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['HostingAmount'].valid || form.controls['HostingAmount'].pristine"
      >
        <li [hidden]="!form.controls['HostingAmount'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['HostingAmount'].hasError('positive')">
          Value must be greater or equal than 0
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="service-amount"
      >Service Amount</label
    >
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="ServiceAmount"
        id="service-amount"
        step="0.01"
        [class.parsley-error]="form.controls['ServiceAmount'].invalid && form.controls['ServiceAmount'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['ServiceAmount'].valid || form.controls['ServiceAmount'].pristine"
      >
        <li [hidden]="!form.controls['ServiceAmount'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['ServiceAmount'].hasError('positive')">
          Value must be greater or equal than 0
        </li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-sm-4" for="support-maintenance-amount"
      >Support & Maintenance Amount</label
    >
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="SupportMaintenanceAmount"
        id="support-maintenance-amount"
        step="0.01"
        [class.parsley-error]="form.controls['SupportMaintenanceAmount'].invalid && form.controls['SupportMaintenanceAmount'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['SupportMaintenanceAmount'].valid || form.controls['SupportMaintenanceAmount'].pristine"
      >
        <li
          [hidden]="!form.controls['SupportMaintenanceAmount'].hasError('required')"
        >
          This field is required
        </li>
        <li
          [hidden]="!form.controls['SupportMaintenanceAmount'].hasError('positive')"
        >
          Value must be greater or equal than 0
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="sla">Has SLA</label>
    <div class="col-sm-6">
      <select
        type="text"
        id="sla"
        formControlName="HasSLA"
        required
        class="form-control"
      >
        <option [ngValue]="false" [selected]="!form.value.HasSLA">No</option>
        <option [ngValue]="true" [selected]="form.value.HasSLA">Yes</option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="support">Support Hours</label>
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="SupportHours"
        id="support"
        [class.parsley-error]="form.controls['SupportHours'].invalid && form.controls['SupportHours'].dirty"
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['SupportHours'].valid"
      >
        <li [hidden]="!form.controls['SupportHours'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['SupportHours'].hasError('positive')">
          Value must be greater than 0
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="jira-licence"
      >Jira Licenses</label
    >
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="JiraLicence"
        id="jira-licence"
        [class.parsley-error]="form.controls['JiraLicence'].invalid && form.controls['JiraLicence'].dirty"
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['JiraLicence'].valid"
      >
        <li [hidden]="!form.controls['JiraLicence'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['JiraLicence'].hasError('positive')">
          Value must be equal or greater than 0
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="title">Title</label>
    <div class="col-sm-6">
      <input
        type="text"
        formControlName="Title"
        id="title"
        [class.parsley-error]="form.controls['Title'].invalid && form.controls['Title'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Title'].valid || form.controls['Title'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="client-code"
      >Product Owner</label
    >
    <div class="col-sm-6">
      <select
        formControlName="ProductOwner"
        id="product-owner"
        (changeValue)="productOwnerChanged($event)"
        [value]="form.value.ProductOwner"
        type="text"
        class="form-control select2 pull-left select-with-remove"
      >
        <option value="" [selected]="!form.value.ProductOwner"></option>
        <option
          *ngFor="let user of usersList"
          [selected]="user === form.value.ProductOwner"
          [value]="user"
        >
          {{ user }}
        </option>
      </select>
      <div class="pull-left">
        <button
          class="btn btn-primary"
          type="button"
          (click)="cleanSelect('#product-owner', 'ProductOwner')"
        >
          <i class="glyphicon glyphicon-remove"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="documents">Documents</label>
    <div class="col-sm-6">
      <div class="row">
        <h6>
          <input
            type="file"
            ng2FileSelect
            [uploader]="uploader"
            multiple
          /><br />
        </h6>
      </div>
    </div>
    <div class="col-sm-10 col-sm-offset-1">
      <div [hidden]="!uploader.queue.length">
        <div style="margin-bottom: 40px">
          <div>Queue length: {{ uploader.queue.length }}</div>
          <table class="table">
            <tbody>
              <tr *ngFor="let item of uploader.queue">
                <td>
                  <strong>{{ item?.file?.name }}</strong>
                </td>
                <td>
                  <div class="progress" style="margin-bottom: 0">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      [ngStyle]="{ 'width': item.progress + '%' }"
                    ></div>
                  </div>
                </td>
                <td class="text-center">
                  <span *ngIf="item.isSuccess"
                    ><i class="fa fa-check"></i
                  ></span>
                  <span *ngIf="item.isError"><i class="fa fa-remove"></i></span>
                </td>
                <td nowrap>
                  <button
                    type="button"
                    class="btn btn-danger btn-xs"
                    (click)="item.remove()"
                  >
                    <span class="fa fa-minus-square"></span> Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <button
              type="button"
              class="btn btn-danger btn-s"
              (click)="uploader.clearQueue()"
              [disabled]="!uploader.queue.length"
            >
              <span class="fa fa-minus-square"></span> Remove all
            </button>
          </div>
        </div>
      </div>

      <div formArrayName="Documents">
        <div
          *ngFor="let document of form.controls['Documents']['controls']; let i = index"
        >
          <div [formGroupName]="i" class="form-group">
            <button
              (click)="removeDocument(i)"
              type="button"
              class="btn btn-primary btn-xs"
            >
              <i class="fa fa-remove"></i>
              {{ document.value.DocumentName }}
            </button>
            <button
              type="button"
              (click)="downloadDocument(i)"
              class="btn btn-success btn-xs"
            >
              <i class="fa fa-eye"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="commissionable"
      >Commissionable</label
    >
    <div class="col-sm-6">
      <select
        type="text"
        id="commissionable"
        formControlName="Commissionable"
        required
        class="form-control"
      >
        <option [ngValue]="false" [selected]="!form.value.Commissionable">
          No
        </option>
        <option [ngValue]="true" [selected]="form.value.Commissionable">
          Yes
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="agreement-notes">Notes</label>
    <div class="col-sm-6">
      <textarea
        rows="3"
        class="autogrow form-control transition-height"
        formControlName="Notes"
        id="agreement-notes"
        placeholder="Try to add few new lines.."
      ></textarea>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="agreement-budget-note"
      >Hours/budget note</label
    >
    <div class="col-sm-6">
      <textarea
        rows="3"
        class="autogrow form-control transition-height"
        formControlName="BudgetNote"
        id="agreement-budget-note"
        placeholder="Try to add few new lines.."
      ></textarea>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-8 col-sm-offset-4">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="1 && (!form.controls['ClientCode'] || form.invalid || formPending || uploader.isUploading)"
      >
        Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</form>
