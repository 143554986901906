import { Injectable, isDevMode } from '@angular/core';

const urls = {
  dev: {
    authentication:
      'https://w8q3jmltoa.execute-api.ap-southeast-2.amazonaws.com/dev',
    records: 'https://0lzmcdas0i.execute-api.ap-southeast-2.amazonaws.com/dev',
    clients: 'https://2nfvnzupy1.execute-api.ap-southeast-2.amazonaws.com/dev',
    opportunities:
      'https://zuj05lmm57.execute-api.ap-southeast-2.amazonaws.com/dev',
    agreements:
      'https://pyoy758ki8.execute-api.ap-southeast-2.amazonaws.com/dev',
    documents:
      'https://pyoy758ki8.execute-api.ap-southeast-2.amazonaws.com/dev',
    jira: 'https://whp5voc0d5.execute-api.ap-southeast-2.amazonaws.com/dev',
    resources:
      'https://u1sdvq8duk.execute-api.ap-southeast-2.amazonaws.com/dev',
    resource_requests:
      'https://tgckszyi9f.execute-api.ap-southeast-2.amazonaws.com/dev',
    projects: 'https://8tp1qrn6sh.execute-api.ap-southeast-2.amazonaws.com/dev',
    mail: 'https://82r63x24d8.execute-api.ap-southeast-2.amazonaws.com/dev',
  },
  prod: {
    authentication:
      'https://vhkt8b2gze.execute-api.ap-southeast-2.amazonaws.com/prod',
    records: 'https://ac6azhifvl.execute-api.ap-southeast-2.amazonaws.com/prod',
    clients: 'https://rm1fia79qc.execute-api.ap-southeast-2.amazonaws.com/prod',
    opportunities:
      'https://bmnqngj5g9.execute-api.ap-southeast-2.amazonaws.com/prod',
    agreements:
      'https://gpjr88s86f.execute-api.ap-southeast-2.amazonaws.com/prod',
    documents:
      'https://gpjr88s86f.execute-api.ap-southeast-2.amazonaws.com/prod',
    jira: 'https://ohn7jhcv6c.execute-api.ap-southeast-2.amazonaws.com/prod',
    resources:
      'https://z0hn1p7upb.execute-api.ap-southeast-2.amazonaws.com/prod',
    resource_requests:
      'https://k6ga48cv1m.execute-api.ap-southeast-2.amazonaws.com/prod',
    projects:
      'https://odo5nqzc19.execute-api.ap-southeast-2.amazonaws.com/prod',
    mail: 'https://14oanuom08.execute-api.ap-southeast-2.amazonaws.com/prod',
  },
};


function url(service: string): string {
  return urls[isDevMode() ? 'dev' : 'prod'][service]
}

@Injectable()
export class LambdaService {
  private projectsEndpoint: string = url('projects');
  private resourcesEndpoint: string = url('resources');
  private resourceRequestsEndpoint: string = url('resource_requests');
  private clientEndpoint: string = url('clients');
  private opportunityEndpoint: string = url('opportunities');
  private agreementEndpoint: string = url('agreements');
  private authenticationEndpoint: string = url('authentication');
  private documentsEndpoint: string = url('documents');
  private recordEndpoint: string = url('records');
  private jiraEndpoint: string = url('jira');
  private mailEndpoint: string = url('mail');

  private opportunityUri: string = 'opportunities';
  private agreementUri: string = 'agreements';
  private authenticationUri: string = 'authentication';
  private documentsUri: string = 'documents';
  private recordUri: string = 'get';
  private jiraUri: string = 'report';
  private jiraCsvUri: string = 'report/csv';
  private userUri: string = 'users';

  getClientsUrl(): string {
    return `${this.clientEndpoint}`;
  }
  getMailUrl(): string {
    return `${this.mailEndpoint}`;
  }

  getResourcesUrl(): string {
    return `${this.resourcesEndpoint}`;
  }
  getResourceRequestsUrl(): string {
    return `${this.resourceRequestsEndpoint}`;
  }
  getProjectsUrl(): string {
    return `${this.projectsEndpoint}`;
  }
  getOpportunitiesUrl(): string {
    return `${this.opportunityEndpoint}/${this.opportunityUri}`;
  }

  getAgreementsUrl(): string {
    return `${this.agreementEndpoint}/${this.agreementUri}`;
  }

  getRecordsUrl(): string {
    return `${this.recordEndpoint}/${this.recordUri}`;
  }

  getJiraUrl(): string {
    return `${this.jiraEndpoint}/${this.jiraUri}`;
  }

  getJiraCsvUrl(): string {
    return `${this.jiraEndpoint}/${this.jiraCsvUri}`;
  }

  getSigninUrl(): string {
    return `${this.authenticationEndpoint}/${this.authenticationUri}/signin/google`;
  }

  getRefreshTokenUrl(token: string): string {
    return `${this.authenticationEndpoint}/${this.authenticationUri}/refresh/${token}`;
  }
  getSigninCallbackUrl(): string {
    return `${this.authenticationEndpoint}/${this.authenticationUri}/callback/google`;
  }

  getUsersUrl(): string {
    return `${this.authenticationEndpoint}/${this.userUri}`;
  }

  getDocumentsUrl(): string {
    return `${this.documentsEndpoint}/${this.documentsUri}`;
  }
}
