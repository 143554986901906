import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef
} from "@angular/core";

@Component({
  selector: "lcm-listing",
  templateUrl: "./listing.component.html"
})
export class ListingComponent {
  @Input() heading!: string;

  @Input() canRefresh = false;
  @Input() canCreate = false;
  @Input() isUpdating = false;

  @Input() items: Array<any> = [];

  @Input() contentTpl!: TemplateRef<any>;
  @Input() emptyTpl!: TemplateRef<any>;
  @Input() pagerTpl!: TemplateRef<any>;
  @Input() addModalTarget!: string;


  @Output() refresh = new EventEmitter<void>();
  @Output() add = new EventEmitter<void>();

  refreshListing() {
    this.refresh.emit();
  }
  addItem() {
    this.add.emit();
  }
}
