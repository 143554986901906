import { AbstractControl } from "@angular/forms";

export let positiveValidator = (
  control: AbstractControl
): { [key: string]: any }|null => {
  const val = control.value;
  return isNaN(parseInt(val)) || val > 0 ? null : { positive: { val } };
};

export let positiveOrZeroValidator = (
  control: AbstractControl
): { [key: string]: any } | null => {
  const val = control.value;
  return isNaN(parseInt(val)) || val >= 0 ? null : { positive: { val } };
};

export let endDateValidator = (
  control: AbstractControl
): { [key: string]: any } | null => {
  if (!control.parent) return null;
  const end_val = control.value;

  const start_val = control.parent.controls["StartDate"].value;
  if (!start_val || !end_val) return null;

  let res =
    new Date(end_val) > new Date(start_val)
      ? null
      : { greater: { val: end_val } };
  return res;
};

export let startDateValidator = (
  control: AbstractControl
): { [key: string]: any } | null => {
  if (!control.parent) return null;
  control.parent.controls["EndDate"].updateValueAndValidity();
  return null;
};

export let expireMonthValidator = (
  control: AbstractControl
): { [key: string]: any } | null => {
  if (!control.parent) return null;
  let month = control.parent.controls["ExpireMonth"];
  let year = control.parent.controls["ExpireYear"];

  if (month.value && !year.value) {
    return { no_year: { val: "select expire year" } };
  } else if (year.value && year.errors) {
    year.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  return null;
};

export let expireYearValidator = (
  control: AbstractControl
): { [key: string]: any } | null => {
  if (!control.parent) return null;
  let month = control.parent.controls["ExpireMonth"];
  let year = control.parent.controls["ExpireYear"];

  if (!month.value && year.value) {
    return { no_month: { val: "select expire month" } };
  } else if (month.value && month.errors) {
    month.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  return null;
};
