import {
  FileUploader as OriginFileUploader,
  FileItem,
  ParsedResponseHeaders
} from "ng2-file-upload";

import { ReflectiveInjector } from "@angular/core";
import { MessengerService } from "../service/messenger.service";

export class FileUploader extends OriginFileUploader {
  public onAfterAddingFile(fileItem: FileItem): any {
    fileItem.withCredentials = false;
    return fileItem;
  }

  public onSuccessItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    let cb = this.options.additionalParameter!["additionalSuccessCallback"];
    if (cb) cb((<any>item).key, item.file.name);
    return { item, response, status, headers };
  }

  public onComplete(
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    return { response, status, headers };
  }

  public onWhenAddingFileFailed(item: any, filter: any, options: any): any {
    let injector = ReflectiveInjector.resolveAndCreate([MessengerService]);
    let messenger = injector.get(MessengerService);
    messenger.postError(
      `${item.name}: Only doc, docx, pdf, jpg formats are acceptable`
    );
    return { item, filter, options };
  }

  public uploadItem(value: FileItem): void {
    let url = this.options.url;
    let agreementService = this.options.additionalParameter!["agreementService"];
    if (!agreementService) return;
    agreementService
      .prepareDocumentUpload(value.file.name, value.file.type)
      .subscribe(({ url, key }: any) => {
        (<any>value).key = key;
        value.url = url;
        value.method = "PUT";
        super.uploadItem(value);
      });
  }

  public onCompleteAll(): any {
    this.options.additionalParameter!["completedSubject"].next(true);
  }
}
