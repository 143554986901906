import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TDatesList, TTimeDistribution} from '.';

@Component({
  template: `
    <div #tableContainer class="table-container">
      <mat-table [dataSource]="data">
        <ng-container sticky matColumnDef="labels">
          <mat-header-cell *matHeaderCellDef>Periods</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.label }}</mat-cell>
        </ng-container>

        <ng-container *ngFor="let month of dates" [matColumnDef]="month">
          <mat-header-cell *matHeaderCellDef>
            {{ time[month].long_label || month }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row" [matTooltip]="row.tooltips[month]">{{
            row[month]
          }}</mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="['labels'].concat(dates)"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: ['labels'].concat(dates)"
        ></mat-row>
      </mat-table>
    </div>
  `,
  styles: [
    '.table-container {overflow-x: auto;}',
    'mat-table {width: max-content;}',
    'mat-header-cell:nth-child(6n), mat-header-cell:nth-child(6n-1), mat-header-cell:nth-child(6n+1) { }',
    'mat-header-cell:nth-child(6n-3), mat-header-cell:nth-child(6n-4), mat-header-cell:nth-child(6n-2) { background: #c2185b; }',
  ],
  standalone: true,
  selector: 'cm-tmt-view[dates][data][time]',
  imports: [CommonModule, MatTableModule, MatTooltipModule],
})
export class TimeManagementTableView {
  private shouldScroll = false;

  @ViewChild('tableContainer') tableContainer?: ElementRef;

  @Input() data!: any[];
  @Input() time!: TTimeDistribution;
  @Input() dates!: TDatesList;

  ngAfterViewChecked() {
    if (this.shouldScroll) {
      this.scrollTable();
      this.shouldScroll = false;
    }
  }
  ngOnChanges() {
    this.shouldScroll = true;
  }
  scrollTable() {
    if (this.tableContainer) {
      this.tableContainer.nativeElement.scrollLeft =
        this.tableContainer.nativeElement.scrollWidth;
    }
  }
}
