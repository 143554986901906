import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Resource } from "./resource";
import { IResource } from "../../prototype";

import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";

import { AbstractEntityFormComponent } from "../../prototype/entity-form.component";

import { MessengerService } from "../../service/messenger.service";
import { ResourcesService } from "../../service/resources.service";

import { Observable } from "rxjs";

@Component({
  selector: "resource-form",
  templateUrl: "./resource-form.template.html"
})
export class ResourceFormComponent extends AbstractEntityFormComponent<
  IResource,
  Resource
> {
  @Input()
  set model(item: Resource) {
    this.modelInstance = item;

    this.forEdit = !!item.Id;
    this.initForm();
  }

  @Input() broadcaster!: Observable<string>;

  @Output() formSaved = new EventEmitter<void>();

  constructor(
    protected messengerService: MessengerService,
    private formBuilder: FormBuilder,
    protected itemService: ResourcesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscription = this.broadcaster.subscribe((event: string) => {
      switch (event) {
        case "edit":
          (<FormControl>this.form.controls["Id"]).disable();
          this.forEdit = true;
          break;
        case "prepare":
          (<FormControl>this.form.controls["Id"]).enable();
          this.forEdit = false;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  initForm(): void {
    const urlPattern = "(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
    this.form = this.formBuilder.group({
      Id: ["", []],
      Title: ["", [Validators.required]],
      Url: ["", [Validators.required, Validators.pattern(urlPattern)]]
    });

    if (this.forEdit) {
      (<FormControl>this.form.controls["Id"]).disable();
    } else {
      (<FormControl>this.form.controls["Id"]).enable();
    }
    let data = {
      Id: "",
      Title: "",
      Url: ""
    };
    this.cleanEmpty(Object.assign(data, this.modelInstance), "");

    this.form.setValue(data);
  }
}
