import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AgreementRoutingModule } from './agreement-routing.module';
import { AgreementComponent } from './agreement.component';
import { DirectiveModule } from '../directive/directive.module';
import { ComponentModule } from '../components/component.module';
import { AgreementResolver } from './agreement-resolver';
import { AgreementDetailComponent } from './agreement-detail.component';
import { ResourceRequestsModule } from '../resource-requests/resource-requests.module';

import { ProjectsModule } from '../modules/projects/projects.module';
import { ReservationComponent } from './reservation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  imports: [
    CommonModule,
    AgreementRoutingModule,
    DirectiveModule,
    ComponentModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ResourceRequestsModule,
    ProjectsModule,

    MatPaginatorModule,
  ],
  declarations: [
    AgreementComponent,
    AgreementDetailComponent,
    ReservationComponent,
  ],
  providers: [AgreementResolver],
})
export class AgreementModule {}
