import { Component, Input } from '@angular/core';

import { AgreementService, BroadcastService, MailService } from '../service';

@Component({
  selector: 'lcm-reservation',
  templateUrl: './reservation.component.html',
})
export class ReservationComponent {
  @Input('agreement-id') agreementId!: string;
  notes: string = '---';

  constructor(
    private mail: MailService,
    private agreements: AgreementService,
    private broadcast: BroadcastService
  ) {}

  makeReservation() {
    this.agreements
      .getByUniqueKey(this.agreementId)
      .toPromise()
      .then((agreement: any) =>
        this.mail.sendReservation(
          this.notes,
          agreement.AgreementNumber,
          agreement.Title
        )
      )
      .then(() => this.broadcast.next(['reservation-popup', 'close']));
  }
}
