import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, of, from, firstValueFrom } from 'rxjs';
import { switchMap, flatMap } from 'rxjs/operators';
import { tap, zip, map, concatMap, delay } from 'rxjs/operators';

import {
  UserService,
  AgreementService,
  OpportunityService,
  JiraService,
  AuthService,
} from '../service';
import { IOpportunity, IAgreement, Teams, States, IUser } from '../prototype';

import * as moment from 'moment';

const ejectStartDate = (entity) => {
  if ('AgreementNumber' in entity) return entity.StartDate;
  let date = moment();
  date.subtract(6, 'months');
  let allHours = []
    .concat(
      entity.PurchasedHours || [],
      entity.UnchargeableHours || [],
      entity.ProfitableHours || []
    )
    .map((item: any) => moment(`${item.Year} ${item.Month}`, 'YYYY MMMM'))
    .concat(
      ((entity as any).Milestones || []).map((m: any) => moment(m.CreatedAt))
    );

  for (let itemDate of allHours) {
    if (date.isAfter(itemDate)) {
      date = itemDate;
    }
  }
  return String(`${date.year()}-${('0' + (date.month() + 1)).slice(-2)}-01`);
};

const isVisible = (item) =>
  ~['Active', 'Pending', 'On Hold'].indexOf(item.Status);

const prepareForJiraRequest = (item) => ({
  id: item.JiraTitle,
  dates: [
    item.Type === "Agreement"
      ? item.StartDate
      : moment().subtract(96, 'months').format('YYYY-MM-DD'),
  ],
  force: false,
  payload: item.TypedHours,
});

@Component({
  templateUrl: 'chargeable-hours.template.html',
  styles: [
    'td.bg-separator, th.bg-separator {background: #444;}',
    'td.bg-score, th.bg-score{background: rgb(115 146 194);}',
  ],
})
export class ChargeableHoursComponent implements OnInit, OnDestroy {
  initialItems: any[] = [];
  poLogged: Object = {};
  poOwnLogged: Object = {};
  userMap: Object = {};
  loadingPoReport: number = 0;
  items: any[] = [];
  fixedItems: any[] = []; // won't be filtered
  timeTable: any = {};
  teamReport?: Object = {};
  hoursSubscription?: Subscription;
  teamStart: string = '';
  teamEnd: string = '';
  poStart: string = '';
  poEnd: string = '';

  Teams: any[] = Object.keys(Teams).map((key) => ({ key, name: Teams[key] }));
  teamSubscription: any;
  usersList: IUser[] = [];
  sortOrder: string = 'Desc';
  expandedCapacity: { [key: string]: boolean } = {};
  expandedPo: { [key: string]: boolean } = {};

  constructor(
    private users: UserService,
    private agreements: AgreementService,
    private opportunities: OpportunityService,
    protected authService: AuthService,
    private jira: JiraService
  ) {
    let end = new Date();
    end.setDate(end.getDate() - (end.getDay() - 2));
    let start = new Date(end);
    start.setDate(start.getDate() - 6);
    this.setTeamStart(start);
    this.setTeamEnd(end);
    this.setPoStart(start);
    this.setPoEnd(end);
  }

  isProjectRow(idx: number, row: any): boolean {
    return row instanceof Array;
  }
  setTeamStart(start: Date) {
    this.teamStart = this.isoDate(start);
  }

  setTeamEnd(end: Date) {
    this.teamEnd = this.isoDate(end);
  }
  setPoStart(start: Date) {
    this.poStart = this.isoDate(start);
  }

  setPoEnd(end: Date) {
    this.poEnd = this.isoDate(end);
  }

  isoDate(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  businessDaysDiff(a: string, b: string): number {
    const x = moment(a);
    const y = moment(b);
    const days = x.diff(y, 'days') + 1;
    const weekends =
      2 * Math.floor((y.day() + days) / 7) +
      Number(y.day() === 0) -
      Number(x.day() === 6);
    return days - weekends;
  }

  async recalculatePoLogged() {
    const users = await firstValueFrom(this.users.getAll());
    this.userMap = Object.fromEntries(
      users.map((u) => [u.FullName, { id: u.JiraId, capacity: u.Capacity, salesCapacity: u.SalesCapacity }])
    );

    from(Object.keys(this.poLogged))
      .pipe(
        tap(() => ++this.loadingPoReport),
        concatMap((name) => of(name).pipe(delay(2000))),
        map((name) => {
          if (!name || name === 'loading') {
            --this.loadingPoReport;
            return;
          }
          const projects = this.poLogged[name].map(([p, _]) => p);
          projects.push("SALES")
          return this.jira
            .getProjectsTime(
              this.poStart,
              this.poEnd,
              projects,
              this.userMap[name]?.id
            )
            .subscribe(
              ({ report, success, poReport }) => {

                this.poOwnLogged[name] = Object.fromEntries(
                  Object.entries(poReport).map(([k, v]: any[]) => [k, v / 3600])
                );
                --this.loadingPoReport;

                if (!success) {
                  console.warn(`Cannot pull PO report for ${name}`);
                  return;
                }
                this.poLogged = {
                  ...this.poLogged,
                  [name]: this.poLogged[name].map(([p, _, info, owner]) => [
                    p,
                    report[p] / 3600,
                    info,
                    owner,
                  ]),
                };
              },
              (...args) => {
                console.error(...args);
                return --this.loadingPoReport;
              }
            );
        })
      )
      .subscribe(() => {}, console.warn);
  }

  recalculateReportedVsCapacity() {
    let report = {};
    this.teamReport = undefined;
    if (this.teamSubscription) this.teamSubscription.unsubscribe();
    this.teamSubscription = this.users
      .getAll()
      .pipe(
        map((users) =>
          users
            .filter((user) => user.Team && States[user.State] === States.active)
            .map(({ Email, Capacity, Team, FullName, JiraId }) =>
              this.jira
                .getUserTime(this.teamStart, this.teamEnd, JiraId || Email)
                .pipe(
                  map((t) => [
                    +(t.total_time / 3600).toFixed(2),
                    +(t.chargeable_time / 3600).toFixed(2),
                    +(
                      ((Capacity || 0) / 5) *
                      this.businessDaysDiff(this.teamEnd, this.teamStart)
                    ).toFixed(2),
                    Team,
                    FullName,
                  ])
                )
            )
        ),

        switchMap((i) => i),
        flatMap((i) => i)
      )
      .subscribe(
        (rec: any) => {
          const [total_time, chargeable_time, cap, team, name] = rec;
          let row = report[team] || {
            cap: 0,
            total_time: 0,
            chargeable_time: 0,
            details: [],
          };
          row.cap += cap;
          row.chargeable_time += chargeable_time;
          row.total_time += total_time;
          let userRow = row.details.find(({ name: n }) => name === n);
          if (!userRow) {
            userRow = { name, cap: 0, total_time: 0, chargeable_time: 0 };
            row.details = [...row.details, userRow];
          }
          userRow.cap += cap;
          userRow.chargeable_time += chargeable_time;
          userRow.total_time += total_time;
          report = Object.assign({}, report, { [team]: row });
        },
        (err) => {
          console.warn(err);
        },
        () => (this.teamReport = Object.assign({}, report))
      );
  }

  changePoDate(val) {
    this.recalculatePoLogged();
  }
  changeTeamDate(val) {
    this.recalculateReportedVsCapacity();
  }

  ngOnInit() {
    this.recalculateReportedVsCapacity();
    this.hoursSubscription = this.jira.timeTableSubject.subscribe(
      ([key, data]) => {
        let record: any = {
          [key]: {
            time: 0,
            timestamp: null,
            lastUpdated: null,
            isPotentiallyHolded: true,
          },
        };
        if (data) {
          let dates: any[] = Object.keys(data.time).sort((left, right) => {
            const l = moment(left);
            const r = moment(right);
            if (l.isBefore(r)) {
              return -1;
            }
            if (r.isBefore(l)) {
              return 1;
            }
            return 0;
          });
          let spentRecently = Math.max(
            ...dates.slice(-3).map((d) => data.time[d].spent)
          );

          record = {
            [key]: {
              time: data.time[dates.pop()].remaining,
              timestamp: moment(data.lastUpdated).utc().local().format('X'),
              lastUpdated: moment(data.lastUpdated).utc().local().calendar(),
              isPotentiallyHolded: spentRecently <= 0,
            },
          };
        }
        this.timeTable = Object.assign({}, this.timeTable, record);

        this.initialItems.map((item) => {
          if (item.JiraTitle in record) {
            item['Timestamp'] = record[item.JiraTitle].timestamp;
            item['Time'] = record[item.JiraTitle].time;
          }
        });
      }
    );

    this.agreements
      .getAll()
      .pipe(
        zip(this.opportunities.getAll()),
        map(([a, o]: [IAgreement[], IOpportunity[]]) => [
          a.filter(isVisible),
          o.filter(isVisible),
        ]),
        map(([a, o]) =>
          [...a, ...o].map((item) => ({
            JiraTitle: item.JiraTitle,
            ClientCode: item.ClientCode,
            BudgetNote: item.BudgetNote,
            ServiceType:
              'ServiceType' in item ? (item as any).ServiceType : null,
            JobNumber:
              'AgreementNumber' in item
                ? (item as any).AgreementNumber
                : item.JobNumber,
            Title: item.Title,
            Type: 'AgreementNumber' in item ? 'Agreement' : 'Product',
            LinkBase:
              'AgreementNumber' in item
                ? '/app/agreements/view'
                : '/app/products/view',
            ProductOwner: item.ProductOwner ? item.ProductOwner : '',
            Status: item.Status,
            LastRefreshed: 'Loading...',
            Hours: 'Loading...',
            StartDate: ejectStartDate(item),
            Milestones: (item as any).Milestones || [],
            NotInvoicedYet: (item.PurchasedHours || [])
              .concat(
                ((item as any).Milestones || []).map((ms) => {
                  return {
                    Hours: ~~(ms.Value / (ms.HourlyRate || 175)),
                    Status: +ms.Status,
                  };
                })
              )
              .filter(({ Status }) => Status !== 20)
              .reduce((result: number, { Hours }) => result + Hours, 0),
            TypedHours: {
              purchased: (item.PurchasedHours || []).concat(
                ((item as any).Milestones || []).map((ms) => {
                  const date = moment(ms.CreatedAt);

                  return {
                    Month: date.format('MMMM'),
                    Year: date.format('YYYY'),
                    Hours: ~~(ms.Value / (ms.HourlyRate || 175)),
                  };
                })
              ),
              uncharged: item.UnchargeableHours || [],
              uncharged_initial: item.UnchargeableHours || [],
              profited: item.ProfitableHours || [],
              profited_initial: item.ProfitableHours || [],
              support: item.SupportHours || 0,
            },
          }))
        )
      )
      .subscribe((items) => {
        this.initialItems = items;
        this.poLogged = items.reduce(
          (acc, i) => ({
            ...acc,
            [i.ProductOwner]: [
              ...(acc[i.ProductOwner] || []),
              [i.JiraTitle, 0, i, i.ProductOwner],
            ],
          }),
          {}
        );

        this.items = this.fixedItems = items;
        this.refreshAll();
      });

    this.users
      .getAll()
      .pipe(map((all) => all.filter((u) => u.State === 'active')))
      .subscribe(
        (usersList: IUser[]) =>
          (this.usersList = usersList.sort(({ Email: a }, { Email: b }) => {
            if (a === b) return 0;
            if (a === this.authService.email) return -1;
            if (b === this.authService.email) return 1;
            return a > b ? 1 : -1;
          }))
      );
  }

  refreshAll(force: boolean = false) {
    this.timeTable = {};
    this.jira.massTimeTableUpdate(
      this.items.map((item) =>
        Object.assign(prepareForJiraRequest(item), { force })
      )
    );
    this.recalculateReportedVsCapacity();
    this.recalculatePoLogged();
  }

  refreshOne(item, force: boolean = false) {
    delete this.timeTable[item.JiraTitle];
    this.jira.massTimeTableUpdate([
      Object.assign(prepareForJiraRequest(item), { force }),
    ]);
  }

  ngOnDestroy() {
    const hs = this.hoursSubscription;
    if (hs) hs.unsubscribe();
  }

  userChanged(val: string): void {
    val === 'any'
      ? (this.items = this.initialItems)
      : (this.items = this.initialItems.filter(
          (item) => item.ProductOwner === val
        ));
  }

  removeSort(): void {
    let th_s: any = document.querySelectorAll(
      'th.sortable a.ascending, th.sortable a.descending'
    );

    for (let th of th_s) {
      th.classList.remove('descending');
      th.classList.remove('ascending');
    }
  }

  sortItems(column: string, event: Event): void {
    this.removeSort();
    this.sortOrder = this.sortOrder === 'Asc' ? 'Desc' : 'Asc';

    (<HTMLElement>event.target).classList.add(
      this.sortOrder === 'Asc' ? 'ascending' : 'descending'
    );
    let k = this.sortOrder === 'Asc' ? 1 : -1;

    this.items.sort(
      (
        { [column]: lcolumn, Title: ltitle },
        { [column]: rcolumn, title: rtitle }
      ) => {
        return lcolumn > rcolumn
          ? k
          : lcolumn < rcolumn
          ? -k
          : ltitle > rtitle
          ? k
          : ltitle < rtitle
          ? -k
          : 0;
      }
    );
  }
}
