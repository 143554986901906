import { PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, tap, share, catchError } from 'rxjs/operators';
import { LambdaService } from './lambda.service';
import { MessengerService } from './messenger.service';
import { AuthService } from './auth.service';

export abstract class FetchAbstractService<T> {
  public items: Array<T> = [];
  public filteredItems?: Array<T> = [];
  public ownerFilteredItems?: Array<T> = [];
  public clientFilter!: PipeTransform;
  public defaultOrder!: string;
  public defaultOrderColumn!: string;
  public filterBy: Array<string> = [];

  protected lastUpdated!: Date;
  protected filter: Object = {};

  protected http!: HttpClient;
  protected lambda!: LambdaService;
  protected messenger!: MessengerService;
  protected auth!: AuthService;

  protected length: number = 0;

  private pendingRequest?: Observable<T[]>;

  abstract getLoadAllUrl(): string;
  abstract getSaveUrl(): string;
  abstract getLoadAllErrorMsg(): string;
  abstract getEditUrl(instance: T): string;

  protected abstract uniqueKeyName: string;

  get totalAmount(): number {
    return this.length;
  }

  getDefaultHeaders() {
    return {
      headers: new HttpHeaders({ authorization: this.auth.authToken }),
    };
  }

  exists(propName, propValue) {
    return this.items.some((item: T) => item[propName] === propValue);
  }

  save(instance: T, forEdit: boolean = false): Observable<T> {
    let request: Observable<T> = this.http[forEdit ? 'put' : 'post']<T>(
      forEdit ? this.getEditUrl(instance) : this.getSaveUrl(),
      instance,
      this.getDefaultHeaders()
    );

    return request;
  }

  loadAllError(): void {
    this.messenger.error(this.getLoadAllErrorMsg());
  }

  setFilter(filter: Object): FetchAbstractService<T> {
    this.filter = filter;
    return this;
  }

  patchFilter(filter: Object): FetchAbstractService<T> {
    this.filter = {...this.filter, ...filter};
    return this;
  }

  setItems(items: Array<T>): void {
    this.items = items;
  }

  reset(): void {
    this.items = [];
    this.length = 0;
  }

  getBaseRequest(): Observable<T[]> {
    if (this.pendingRequest) return this.pendingRequest;

    let request: Observable<T[]> = this.http
      .get<T[]>(this.getLoadAllUrl(), this.getDefaultHeaders())
      .pipe(
        tap(() => (this.pendingRequest = undefined)),
        map((data: any) => {
          // we are rewriting services so that they returns collection directly
          return (data.Items || data) as T[];
        }),
        catchError((err: any, caught: Observable<T[]>) => {
          this.loadAllError();
          return from([<T[]>[]]);
        })
      );
    this.pendingRequest = request.pipe(share());
    return this.pendingRequest;
  }

  loadAll(): Observable<T[]> {
    let request = this.getBaseRequest().pipe(
      tap((items: Array<T>) => {
        this.setItems(items);
        this.lastUpdated = new Date();
      }),
      map((items: Array<T>) =>
        this.sort(this.defaultOrderColumn, this.defaultOrder, items)
      )
    );
    return request;
  }

  filterPipe(key: string): any {
    return map((i: Array<T>) => i.filter((x) => {
      let v = x[key];
      const filter = this.filter[key];
      if (v === undefined) {
        v = '';
      }
      if (Array.isArray(filter) && !Array.isArray(v)) return filter.includes(v);
      return v === filter;
    }))
  }

  getAll(withFilters: boolean = true): Observable<T[]> {
    let items = !this.items.length ? this.loadAll() : from([this.items]);

    if (withFilters) {
      for (let key in this.filter) {
        items = items.pipe(
          this.filterPipe(key)
        );
      }
    }

    return items.pipe(tap((items: Array<T>) => (this.length = items.length)));
  }

  getQty(
    limit: number = 10,
    offset: number = 0,
    client: string = '',
    pageChange: boolean = false,
    owner: [string, boolean] = ['any', false]
  ) {
    let itemsList: Observable<T[]>;

    if (owner[0] !== 'any' && owner[1] === true) {
      itemsList = this.searchProductOwner(owner[0], pageChange).pipe(
        map((items: Array<T>) => items.slice(offset, offset + limit))
      );
    } else if (!client && owner[0] === 'any') {
      this.ownerFilteredItems = undefined;
      this.filteredItems = undefined;
      itemsList = this.getAll().pipe(
        map((items: Array<T>) => items.slice(offset, offset + limit))

      );

    } else {
      itemsList = this.searchClient(client, pageChange).pipe(
        map((items: Array<T>) => items.slice(offset, offset + limit))
      );
    }

    return itemsList;
  }

  getByUniqueKey(key: string): Observable<T | null> {
    return this.getAll(false).pipe(
      map((items: T[]) => {
        let enity;
        for (let item of items) {
          if (item[this.uniqueKeyName] === key) {
            return item;
          }
        }
        return null;
      })
    );
  }

  searchClient(
    client: string,
    pageChange: boolean,
    withFilters: boolean = true
  ): Observable<T[]> {
    let items = from([
      pageChange
        ? this.filteredItems
        : this.ownerFilteredItems
        ? this.ownerFilteredItems
        : this.items,
    ]) as Observable<T[]>;
    items = items.pipe(
      map((items: T[]) => {
        this.filteredItems = items.filter((item: any) => {
          for (let filter of this.filterBy) {
            if (
              ~(item[filter] || '').toLowerCase().indexOf(client.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        });

        return this.filteredItems;
      })
    );

    if (withFilters) {
      for (let key in this.filter) {
        items = items.pipe(
          this.filterPipe(key)
        );
      }
    }

    return items.pipe(tap((items:T[]) => (this.length = items.length)));
  }

  searchProductOwner(
    owner: string,
    pageChange: boolean,
    withFilters: boolean = true
  ): Observable<T[]> {
    let items = from([pageChange ? this.ownerFilteredItems : this.items]) as Observable<T[]>;
    items = items.pipe(
      map((items: Array<T>) => {
        this.ownerFilteredItems = items.filter((item: any) => {
          if (item['ProductOwner']) {
            if (
              ~item['ProductOwner'].toLowerCase().indexOf(owner.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        });

        return this.ownerFilteredItems;
      })
    );

    if (withFilters) {
      for (let key in this.filter) {
        items = items.pipe(
          this.filterPipe(key)
        );
      }
    }

    return items.pipe(
      tap((items: Array<T>) => (this.length = items.length)),
      );

  }

  sortItems(
    column: string,
    sortOrder,
    limit: number,
    offset: number,
    withFilters: boolean = true
  ): Observable<T[]> {
    let items = from([
      this.filteredItems
        ? this.filteredItems
        : this.ownerFilteredItems
        ? this.ownerFilteredItems
        : this.items,
    ]);
    items = items.pipe(
      map((items: Array<T>) => this.sort(column, sortOrder, items))
    );
    if (withFilters) {
      for (let key in this.filter) {
        items = items.pipe(
          this.filterPipe(key)
        );
      }
    }
    return items.pipe(
      map((items: Array<T>) => items.slice(offset, offset + limit))
    );
  }

  sort(column: string, sortOrder: string, items: Array<T>): Array<T> {
    return items.sort((a, b) => {
      if (a[column] && b[column]) {
        if (typeof a[column] === 'string' || typeof b[column] === 'string') {
          if (a[column].toLowerCase() > b[column].toLowerCase())
            return sortOrder === 'Desc' ? -1 : 1;
          if (a[column].toLowerCase() < b[column].toLowerCase())
            return sortOrder === 'Desc' ? 1 : -1;
        } else {
          return sortOrder === 'Desc'
            ? b[column] - a[column]
            : a[column] - b[column];
        }
      } else {
        if (a[column] && !b[column]) return sortOrder === 'Desc' ? -1 : 1;
        if (!a[column] && b[column]) return sortOrder === 'Desc' ? 1 : -1;
      }

      return 0;
    });
  }
}
