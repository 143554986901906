import { NgModule } from '@angular/core';
import { WidgetDirective } from './widget.directive';
import { DatetimepickerDirective } from './datetimepicker.directive';
import { Select2Directive } from './select2.directive';

import { MomentPipe } from './moment.pipe';
import { SumByPipe } from './sum-by.pipe';

@NgModule({
  declarations: [
    WidgetDirective,
    DatetimepickerDirective,
    Select2Directive,

    MomentPipe,
    SumByPipe,
  ],
  exports: [
    WidgetDirective,
    DatetimepickerDirective,
    Select2Directive,

    MomentPipe,
    SumByPipe,
  ],
})
export class DirectiveModule {}
