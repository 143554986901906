<mat-toolbar color="primary">
  <div class="logo">
    <a routerLink="/">
      <span class="link-logo-highlight">LINK</span>DIGITAL Contracts Manager
    </a>
  </div>
  <span class="spacer"></span>

  <button mat-icon-button matTooltip="Toggle sidebar" (click)="showSidebar = !showSidebar">
    <mat-icon>corporate_fare</mat-icon>
  </button>

  <button mat-icon-button [matTooltip]="auth.name + '&#10;' + auth.email||''">
    <mat-icon>person</mat-icon>
  </button>

  <a color="warn" (click)="logout()" matTooltip="Logout" routerLink="/login" mat-icon-button>
    <mat-icon>power_settings_new</mat-icon>
  </a>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav [(opened)]="showSidebar" mode="side">
    <sidebar></sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <div>
      <main id="content" class="content view-animate fade-up" role="main">
        <router-outlet></router-outlet>
      </main>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
