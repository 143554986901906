<form
  [formGroup]="form"
  class="form-horizontal form-label-left"
  (ngSubmit)="onSubmit()"
>
  <div class="form-group">
    <label class="control-label col-sm-4" for="title">Title</label>
    <div class="col-sm-6">
      <input
        type="text"
        id="title"
        formControlName="Title"
        [class.parsley-error]="form.controls['Title'].invalid && form.controls['Title'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Title'].valid || form.controls['Title'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="url">Url</label>
    <div class="col-sm-6">
      <input
        type="url"
        id="url"
        formControlName="Url"
        [class.parsley-error]="form.controls['Url'].invalid && form.controls['Url'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Url'].valid || form.controls['Url'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-8 col-sm-offset-4">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="form.invalid || formPending"
      >
        Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</form>
