import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ClientAccountComponent } from "./client-account.component";
import { ClientAccountDetailComponent } from "./client-account-detail.component";
import { ClientResolver } from "./client-resolver";

export const routes: Routes = [
  { path: "", component: ClientAccountComponent, pathMatch: "full" },
  {
    path: "view/:entityId",
    component: ClientAccountDetailComponent,
    pathMatch: "full",
    resolve: {
      entity: ClientResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientAccountRoutingModule {}
