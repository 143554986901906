<form [formGroup]="form" class="form-horizontal form-label-left" (ngSubmit)="onSubmit()">

  <div class="form-group">
    <label class="control-label col-sm-4" for="resource-request-start-date">Start Date</label>
    <div class="col-sm-6">
      <input type="text" id="resource-request-start-date" datetimepicker data-format="Y-MM-DD"
        formControlName="StartDate"
        [class.parsley-error]="form.controls['StartDate'].invalid && form.controls['StartDate'].dirty"
        (valueChanged)="form.controls['StartDate'].setValue($event)"
        required class="form-control ">
      <ul class="parsley-error-list"
        [hidden]="form.controls['StartDate'].valid || form.controls['StartDate'].pristine">
        <li>This field is required</li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-sm-4" for="resource-request-end-date">End Date</label>
    <div class="col-sm-6">
      <input type="text" id="resource-request-end-date" datetimepicker data-format="Y-MM-DD"
        formControlName="EndDate"
        [class.parsley-error]="form.controls['EndDate'].invalid && form.controls['EndDate'].dirty"
        (valueChanged)="form.controls['EndDate'].setValue($event)"
        required class="form-control ">
      <ul class="parsley-error-list"
        [hidden]="form.controls['EndDate'].valid || form.controls['EndDate'].pristine">
        <li>This field is required</li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-sm-4" for="linked-project">Linked Project</label>
    <div class="col-sm-6">
      <select type="text" id="linked-project"
        [value]="form.value.LinkedProject"
        formControlName="LinkedProject"
        (changeValue)="linkedProjectChanged($event)"
        [class.parsley-error]="form.controls['LinkedProject'].invalid && form.controls['LinkedProject'].dirty"
        required class="form-control select2">
        <option value="" [selected]="!form.value.LinkedProject"></option>
        <option
          *ngFor="let client of availableProjects['clients']"
          [selected]="client.ClientCode === 'c'+form.value.LinkedProject"
          [value]="'c' + client.ClientCode">[client] {{ client.ClientName }}</option>
        <option
          *ngFor="let pr of availableProjects['products']"
          [selected]="pr.JobNumber === 'p'+form.value.LinkedProject"
          [value]="'p' + pr.JobNumber">[product] {{ pr.Title }}</option>

        <option
          *ngFor="let ag of availableProjects['agreements']"
          [selected]="ag.AgreementNumber === 'a'+form.value.LinkedProject"
          [value]="'a' + ag.AgreementNumber">[agreement] {{ ag.Title }}</option>

      </select>
      <ul class="parsley-error-list"
        [hidden]="form.controls['LinkedProject'].valid || form.controls['LinkedProject'].pristine">
        <li>This field is required</li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-sm-4" for="status">Status</label>
    <div class="col-sm-6">
      <select type="text" id="status"
        formControlName="Status"
        required class="form-control ">
        <option *ngFor="let item of resourceRequestStatuses" [ngValue]="item[0]">{{ item[1] }}</option>
      </select>

      <ul class="parsley-error-list"
        [hidden]="form.controls['Status'].valid || form.controls['Status'].pristine">
        <li>This field is required</li>
      </ul>
    </div>
  </div>



  <div formArrayName="ResourceTypes">
    <fieldset class="" *ngFor="let resType of form.controls['ResourceTypes']['controls']; let i = index" [formGroupName]="i">
      <legend class="text-primary">
        Resource type
        <button type="button" class="pull-right btn btn-sm btn-danger" (click)="removeResourceTypeForm(i)">
          <i class="fa fa-times fa-cross"></i>
        </button>
      </legend>
      <div class="form-group">
        <label class="control-label col-sm-4" >Name</label>
        <div class="col-sm-6">
          <input type="text"
            formControlName="Name"
            [class.parsley-error]="resType.controls.Name.invalid && resType.controls.Name.dirty"
            required class="form-control ">
          <ul class="parsley-error-list"
            [hidden]="resType.controls.Name.valid || resType.controls.Name.pristine"
          >
            <li>This field is required</li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-4" >Group</label>
        <div class="col-sm-6">
          <select type="text"
            formControlName="Group"
            required class="form-control ">
            <option *ngFor="let opt of GroupOptions" [value]="opt">{{ opt }}</option>
          </select>
          <ul class="parsley-error-list"
            [hidden]="resType.controls.Group.valid || resType.controls.Group.pristine"
          >
            <li>This field is required</li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-4">Allocation ({{ (form.value.ResourceTypes[i].Allocation * form.value.ResourceTypes[i].Expenditure / 100)|number }} expended)</label>
        <div class="col-sm-6">
          <input type="number"
            formControlName="Allocation"
            [class.parsley-error]="resType.controls.Allocation.invalid && resType.controls.Allocation.dirty"
            required class="form-control ">
          <ul class="parsley-error-list"
            [hidden]="resType.controls.Allocation.valid || resType.controls.Allocation.pristine"
          >
            <li>This field is required</li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-4" >Expenditure</label>
        <div class="col-sm-6">
          <div class="cm-range-input">
            <input type="range"
              formControlName="Expenditure"
              [class.parsley-error]="resType.controls.Expenditure.invalid && resType.controls.Expenditure.dirty"
              required class="form-control-range ">
            <span class="form-control-range-value">
              {{ form.value.ResourceTypes[i].Expenditure }}%
            </span>
          </div>
          <ul class="parsley-error-list"
            [hidden]="resType.controls.Expenditure.valid || resType.controls.Expenditure.pristine"
          >
            <li>This field is required</li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-4" >Notes</label>
        <div class="col-sm-6">
          <input type="text"
            formControlName="Notes"
            [class.parsley-error]="resType.controls.Notes.invalid && resType.controls.Notes.dirty"
            class="form-control ">
          <ul class="parsley-error-list"
            [hidden]="resType.controls.Notes.valid || resType.controls.Notes.pristine"
          >
            <li>This field is required</li>
          </ul>
        </div>
      </div>
    </fieldset>
  </div>

  <div class="form-group">
    <div class="col-sm-4">
      <button class="btn btn-success" type="button" (click)="addResourceTypeForm()">
        Add Resource Type
      </button>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-8 col-sm-offset-4">
      <button class="btn btn-primary" type="submit" [disabled]="form.invalid || formPending">
        Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</form>
