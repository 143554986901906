import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { LambdaService } from './lambda.service';
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { MessengerService } from './messenger.service';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';

type LinkUser = {
  FullName: string;
  Email: string;
  Role: string;
  at: string;
};

@Injectable()
export class AuthService {
  name?: string;
  email?: string;
  gender: string = 'male';
  role: string = 'ROLE_USER';
  authToken: string = '';

  redirectUrl: string = '/app/dashboard';
  isLoggedIn: boolean = false;

  constructor(
    private http: HttpClient,
    private lambda: LambdaService,
    private router: Router,
    private social: SocialAuthService,
    private messenger: MessengerService
  ) {
    this.social.authState
      .pipe(
        switchMap((user) => this.socialIntoLinkUser(user)),
        map((details?: LinkUser) => {
          if (!details) {
            return false;
          }
          this.authToken = details.at;
          this.setPerson({
            name: details.FullName,
            email: details.Email,
            role: details.Role,
          });
          return true;
        })
      )
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
        if (isLoggedIn) {
          this.messenger.info(
            `Hello, ${(this.name || '').trim()}! Application is loading..`
          );
          this.router.navigateByUrl(this.redirectUrl);
        }
      });
  }

  private socialIntoLinkUser(
    user?: SocialUser
  ): Observable<LinkUser | undefined> {
    if (!user) {
      this.messenger.info('User is not identified.');
      this.setPerson({});
      return of(undefined);
    }
    this.messenger.info(`User identified as ${user.email}. Authorizing..`);
    return this.http
      .post<LinkUser>(this.lambda.getSigninCallbackUrl(), {
        token: user.idToken,
      })
      .pipe(
        catchError((resp: HttpErrorResponse) => {
          console.warn(resp);
          const err = resp.error;
          this.messenger.error((err && err.error) || err);
          return of(undefined);
        })
      );
  }

  loginWithGoogle(): Promise<SocialUser> {
    return this.social.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  logoutWithGoogle() {
    this.social.signOut();
  }

  setPerson({ name, email, role }: { [key: string]: string }): any {
    this.name = name;
    this.email = email;
    this.role = role;
    localStorage.setItem(
      'person',
      JSON.stringify({
        name,
        email,
        role,
      })
    );
  }

  resolveAccount() {
    return this.social.authState;
  }

  isAdminOrEditor(): boolean {
    return (
      !!~this.role.indexOf('ROLE_ADMIN') || !!~this.role.indexOf('ROLE_EDITOR')
    );
  }

  isAdmin(): boolean {
    return !!~this.role.indexOf('ROLE_ADMIN');
  }
}
