import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from '../service/auth-guard.service';
import { LayoutComponent } from './layout/layout.component';
import { ClientAccountModule } from '../client-account/client-account.module';
import { OpportunityModule } from '../opportunity/opportunity.module';
import { AgreementModule } from '../agreement/agreement.module';
import { ResourcesComponent } from './resources/resources.component';
import { ResourceRequestsModule } from '../resource-requests/resource-requests.module';
import { HoursModule } from '../hours/hours.module';
import { CommissionModule } from './commission/commission.module';
import { ProjectsComponent } from './projects/projects.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardResolver } from './dashboard/dashboard-resolver';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'app',
    canActivate: [AuthGuardService],
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        resolve: {
          data: DashboardResolver,
        },
      },
      {
        path: 'client-accounts',
        loadChildren: () => ClientAccountModule,
      },
      {
        path: 'products',
        loadChildren: () => OpportunityModule,
      },
      {
        path: 'agreements',
        loadChildren: () => AgreementModule,
      },
      {
        path: 'resources',
        loadComponent: () => ResourcesComponent,
      },

      {
        path: 'resource-requests',
        loadChildren: () => ResourceRequestsModule,
      },
      {
        path: 'users',
        loadComponent: () => UsersComponent,
      },
      {
        path: 'hours',
        loadChildren: () => HoursModule,
      },
      {
        path: 'commission',
        loadChildren: () => CommissionModule,
      },
      {
        path: 'projects',
        loadComponent: () => ProjectsComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
