import { Injectable } from "@angular/core";
import { Observable, concat} from "rxjs";
import { filter, toArray, flatMap, map, tap } from "rxjs/operators";
import { AgreementService } from "./agreement.service";
import { OpportunityService } from "./opportunity.service";

@Injectable()
export class InvoiceService {
   statuses = [0, 10, 20];

   statusLabels = {
    0: "Not ready to invoice",
    10: "Ready to invoice",
    20: "Invoiced",
  };

  constructor(
    private opportunityService: OpportunityService,
    private agreementService: AgreementService
  ) {}

  deleteMilestone(type: string, ownerId: string, id: string) {
    return this[type + "Service"].deleteMilestone(ownerId, id);
  }
  updateMilestone(
    type: string,
    ownerId: string,
    { id: Id, status: Status, notes: Notes }
  ) {
    return this[type + "Service"].updateMilestone(ownerId, {
      Id,
      Status,
      Notes,
    });
  }

  updateHours(type: string, ownerId: string, data: any, hoursType: string) {
    const service: AgreementService|OpportunityService = this[type + "Service"];
    const request = service.updateHours(ownerId, data, hoursType);
    return request.pipe(tap(() => {
      service.reset();
      service.getAll();
    }));
  }

  deleteHours(
    type: string,
    ownerId: string,
    [idx, hoursType, hours]: [number, string, any[]]
  ) {
    let [removed] = hours.slice(0).splice(idx, 1);
    let id = removed.Id || idx;
    return this[type + "Service"].removeHours(ownerId, hoursType, id);
  }

  getInvoicablePurchasedHours(force = false): Observable<any[]> {
    if (force) {
      this.opportunityService.reset();
      this.agreementService.reset();
    }
    return concat(
      this.opportunityService.getAll(),
      this.agreementService.getAll()
    ).pipe(
      flatMap((v) => <any>v),
      flatMap(({ JobNumber, AgreementNumber, PurchasedHours }: any) =>
        (PurchasedHours || []).map((item) =>
          Object.assign(
            {
              _owner: {
                type: AgreementNumber ? "agreement" : "opportunity",
                id: AgreementNumber || JobNumber,
              },
            },
            item
          )
        )
      ),
      filter(({ Status }: any) => +Status === 10),
      toArray()
    );
  }

  getInvoicableMilestones(force = false) {
    if (force) this.opportunityService.reset();
    return this.opportunityService
      .getAll()
      .pipe(
        map((opportunities) =>
          [].concat(
            ...opportunities.map(({ JobNumber, Milestones }) =>
              (Milestones || []).filter(({ Status }) => Status === 10).map(
                (milestone) => Object.assign({ JobNumber }, milestone)
              ) as any
            )
          )
        )
      );
  }
}
