import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'totalCommissionAmount' })
export class TotalCommissionAmountPipe implements PipeTransform {
  transform(item: any): number {
    const hours = item.PurchasedHours || [];
    const invoices = item.Invoices || [];
    const totalHours = hours
      .filter(({ Status, Commissionable }) => Status === 20 && Commissionable)
      .map(({ Hours, HourlyRate }) => Hours * (HourlyRate || 0))
      .reduce((total, next) => total + next, 0);
    const totalInvoices = invoices.reduce(
      (total, next) => total + next.InvoiceAmount,
      0
    );
    return totalHours + totalInvoices;
  }
}
