<form
  [formGroup]="form"
  class="form-horizontal form-label-left"
  (ngSubmit)="onSubmit()"
>
  <div class="form-group">
    <label class="control-label col-sm-4" for="client-code">Client Code</label>
    <div class="col-sm-6">
      <input
        formControlName="ClientCode"
        [class.parsley-error]="form.controls['ClientCode'].invalid && form.controls['ClientCode'].dirty"
        type="text"
        id="client-code"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['ClientCode'].valid || form.controls['ClientCode'].pristine"
      >
        <li [hidden]="!form.controls['ClientCode'].hasError('required')">
          This field is required
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="client-name">Client Name</label>
    <div class="col-sm-6">
      <input
        type="text"
        id="client-name"
        formControlName="ClientName"
        [class.parsley-error]="form.controls['ClientName'].invalid && form.controls['ClientName'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['ClientName'].valid || form.controls['ClientName'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="xero">Xero ID</label>
    <div class="col-sm-6">
      <input
        formControlName="XeroId"
        type="text"
        id="xero"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="insightly">Insightly ID</label>
    <div class="col-sm-6">
      <input
        type="text"
        id="insightly"
        formControlName="InsightlyId"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="aws">AWS ID</label>
    <div class="col-sm-6">
      <input
        type="text"
        id="aws"
        formControlName="AwsId"
        class="form-control"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="commissionable"
      >Commissionable</label
    >
    <div class="col-sm-6">
      <select
        type="text"
        id="commissionable"
        formControlName="Commissionable"
        required
        class="form-control"
      >
        <option [ngValue]="false" [selected]="!form.value.Commissionable">
          No
        </option>
        <option [ngValue]="true" [selected]="form.value.Commissionable">
          Yes
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="client-notes">Notes</label>
    <div class="col-sm-6">
      <textarea
        rows="3"
        class="autogrow form-control transition-height"
        formControlName="Notes"
        id="client-notes"
        placeholder="Try to add few new lines.."
      ></textarea>
    </div>
  </div>
  <div class="form-group">
    <div class="col-sm-8 col-sm-offset-4">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="form.invalid || formPending"
      >
        Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</form>
