<h1 class="page-title">Projects</h1>

<lcm-listing
  heading="Projects"
  [canCreate]="false"
  [canRefresh]="false"
  [isUpdating]="isUpdating"
  [items]="items"
  [contentTpl]="contentTpl"
  [pagerTpl]="pagerTpl"
  [emptyTpl]="contentTpl"
  (refresh)="reload()"
>
  <ng-template #pagerTpl> </ng-template>

  <ng-template #contentTpl>
    <button class="btn btn-transparent btn-xs" (click)="exportProjects('csv')">
      <i class="fa fa-table"></i>
      Export CSV
    </button>

    <div class="width-300 inline-filter pull-right margin-bottom">
      <div
        class="input-group input-group-sm input-group-transparent input-group-rounded"
      >
        <label class="control-label pull-left po-label">PO</label>

        <select
          id="product-owner-filter"
          (changeValue)="userChanged($event)"
          type="text"
          class="form-control select2 pull-left"
        >
          <option value="any">Any</option>
          <option *ngFor="let user of usersList" [value]="user.FullName">
            {{
              (authService.email === user.Email &&
                "Me (" + user.FullName + ")") ||
                user.FullName
            }}
          </option>
        </select>
      </div>
    </div>

    <table
      id="datatable-table"
      class="table table-striped table-hover table-editable"
    >
      <thead>
        <tr>
          <th class="editable sortable renderable client-code-col">
            <a (click)="sortItems('Title', $event)">
              Title
              <b class="sort-caret"></b>
            </a>
          </th>
          <th>Description</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>To be invoiced</th>
          <th>Product Owner</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let project of items">
          <tr *ngIf="project['_notInvoiced']">
            <td>{{ project.Title }}</td>
            <td>{{ project.Description }}</td>
            <td>
              {{
                project.StartDate &&
                  (project.StartDate | moment).format("MMM DD, YYYY")
              }}
            </td>
            <td>
              {{
                project.EndDate &&
                  (project.EndDate | moment).format("MMM DD, YYYY")
              }}
            </td>
            <td [class]="{ 'color-red': project['_overdueInvoices'] > 0 }">
              ${{ project["_toBeInvoiced"] || 0 | number : ".0-2" }}
            </td>
            <td>{{ project["_productOwner"] }}</td>
            <td>
              <a
                class="btn btn-info btn-xs"
                [routerLink]="[
                  '/app/' +
                    productOwnerRouterMapping[project['_owner'].type] +
                    '/view',
                  project['_owner'].id
                ]"
              >
                <span class="glyphicon glyphicon-link"></span>
              </a>
              <button
                *ngIf="
                  project.Milestones.length || project.PurchasedHours.length
                "
                title="Toggle"
                class="btn btn-link btn-xs"
                (click)="expandRow(project.Id)"
              >
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-caret-down': !expandedRows[project.Id!],
                    'fa-caret-up': expandedRows[project.Id!]
                  }"
                ></i>
              </button>
            </td>
          </tr>
          <tr
            *ngIf="
              expandedRows[project.Id!] &&
              (project.Milestones.length || project.PurchasedHours.length)
            "
          >
            <td colspan="7">
              <lcm-milestones
                *ngIf="project.Milestones.length"
                [ownerType]="project['_owner'].type"
                [ownerId]="project['_owner'].id"
                [milestones]="project.Milestones"
                (activate)="activateMilestone($event)"
              ></lcm-milestones>
              <lcm-purchased-hours
                *ngIf="project.PurchasedHours.length"
                [ownerType]="project['_owner'].type"
                [ownerId]="project['_owner'].id"
                [hours]="project.PurchasedHours"
                (update)="invoiceHoursOptions = $event"
                (remove)="invoiceHoursOptions = $event"
              ></lcm-purchased-hours>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr>
          <th>
            <!--title-->
          </th>
          <th>
            <!--description-->
          </th>
          <th>
            <!--end date-->
          </th>
          <th>
            <!--end date-->
          </th>
          <th>
            <!--to be invoiced-->
            To be invoiced total:
            <br />
            <strong>
              ${{ items | toBeInvoicedTotal | number : ".0-2" }}
            </strong>
            <br />
            Overdue invoices:
            <br />

            <strong [class]="{ 'color-red': items | overdueInvoices }">
              ${{ items | overdueInvoices | number : ".0-2" }}
            </strong>
          </th>
          <th>
            <!--owner-->
          </th>
          <th>
            <!--actions-->
          </th>
        </tr>
      </tfoot>
    </table>
  </ng-template>
</lcm-listing>

<div class="col-lg-12">
  <section class="widget">
    <h3 class="head heading">Upcoming invoices</h3>
    <div class="body">
      <div class="form-group clearfix">
        <label class="control-label col-sm-4" for="start-date">
          Start Date
        </label>
        <div class="col-sm-6">
          <input
            type="text"
            datetimepicker
            data-format="Y-MM-DD"
            [value]="upcomingStart"
            (valueChanged)="upcomingStart = $event"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group clearfix">
        <label class="control-label col-sm-4" for="start-date">
          End Date
        </label>
        <div class="col-sm-6">
          <input
            type="text"
            datetimepicker
            data-format="Y-MM-DD"
            [value]="upcomingEnd"
            (valueChanged)="upcomingEnd = $event"
            class="form-control"
          />
        </div>
      </div>
      <div class="clearfix"></div>
      <h3 class="text-center">
        Total value:
        ${{ items | upcomingInvoices : upcomingStart : upcomingEnd | number:".0"}}
      </h3>
    </div>
  </section>
</div>

<project-modals
  [formModel]="formModel"
  (remove)="remove(removedId)"
  (save)="afterSave()"
></project-modals>

<milestone-modals
  (save)="afterSave()"
  (update)="afterSave()"
  (remove)="afterSave()"
  [ownerType]="activatedMilestone.ownerType"
  [ownerId]="activatedMilestone.ownerId"
  [activated]="activatedMilestone"
  [projects]="items"
></milestone-modals>

<hours-modals
  hoursType="purchased"
  [commisionable]="true"
  [invoiceHoursOptions]="invoiceHoursOptions.options"
  [ownerType]="invoiceHoursOptions.ownerType"
  [ownerId]="invoiceHoursOptions.ownerId"
  (save)="afterSave()"
  (update)="afterSave()"
  (remove)="afterSave()"
  [projects]="items"
></hours-modals>
