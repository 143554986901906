<modal-dialog
  modalId="{{ hoursType }}-form-modal"
  heading="Add {{ hoursType | titlecase }} Hours"
>
  <hours-form
    [agreement-number]="ownerId"
    type="{{ hoursType }}"
    (formSaved)="save.emit()"
    [using-service]="ownerType"
    [commissionable]="commisionable"
    [projects]="projects"
    [hours-options]="invoiceHoursOptions"
  ></hours-form>
</modal-dialog>

<modal-dialog
  modalId="{{ hoursType }}-hours-remove-modal"
  heading="Remove {{ hoursType }} hours"
  [withControls]="true"
  confirm-text="Delete"
  (acceptModal)="onRemove()"
>
  Please confirm you'd like to delete this item. This action cannot be undone.
</modal-dialog>

<modal-dialog
  modalId="{{ hoursType }}-hours-invoice-modal"
  heading="Update status"
  [withControls]="true"
  [confirm-text]="item.Status === 20 ? 'Confirm' : 'Update'"
  (acceptModal)="updateHours(item)"
  (rejectModal)="approvedChange = false"
>
  <p *ngIf="approvedChange">
    Please confirm you'd like to mark this item as invoiced.
  </p>
  <form
    *ngIf="!approvedChange"
    class="form-horizontal form-label-left"
    name="purchased-hours-update-form"
  >
    <div class="form-group">
      <label class="control-label col-sm-4" for="purchased-hours-status-change"
        >Status</label
      >
      <div class="col-sm-6">
        <select
          id="milestone-status-change"
          [ngModelOptions]="{ standalone: true }"
          required
          class="form-control"
          [(ngModel)]="item.Status"
        >
          <option *ngFor="let item of statuses" [value]="item[0]">
            {{ item[1] }}
          </option>
        </select>
      </div>
    </div>
    <div *ngIf="item.Status == 20" class="form-group">
      <label class="control-label col-sm-4" for="purchased-hours-notes"
        >Notes</label
      >
      <div class="col-sm-6" id="purchased-hours-notes" name="notes">
        <textarea
          class="form-control"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="item.Notes"
        ></textarea>
      </div>
    </div>
    <div *ngIf="item.Status == 10" class="form-group">
      <label class="control-label col-sm-4" for="milestone-notes"
        >Message</label
      >
      <div class="col-sm-6" id="milestone-notes" name="notes">
        <textarea
          class="form-control"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="message"
        ></textarea>
      </div>
    </div>
  </form>
</modal-dialog>
