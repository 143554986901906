import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { IAgreement } from '../../prototype/agreement.prototype';
import { Observable } from 'rxjs';
import { filter, tap, map } from 'rxjs/operators';
import {
  OpportunityService,
  AgreementService,
  AuthService,
  BroadcastService,
  InvoiceService,
} from '../../service';
import * as moment from 'moment';
import { HoursModule } from '../../modules/hours/hours.module';
import { ComponentModule } from 'src/app/components';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { MatTableModule } from '@angular/material/table';

@Component({
  standalone: true,
  imports: [
    HoursModule,
    ComponentModule,
    FormsModule,
    CommonModule,
    RouterModule,
    DirectiveModule,
    MatTableModule,
  ],
  templateUrl: './dashboard.template.html',
})
export class DashboardComponent {
  protected pending: boolean = false;
  public expiringAgreements: IAgreement[] = [];
  public dueInvoices: IAgreement[] = [];
  public addons: any[] = [];
  public milestones: any[] = [];
  public purchasedHours: any[] = [];
  protected triggerBroadcaster!: Observable<string>;

  protected invoiceHoursOptions: any = {};

  isMilestoneRemoving = false;
  approveMilestoneChange = false;

  currentAddon: any;
  currentAgreement: any;
  activeMilestone: any;
  activeMilestoneStatus: any;
  activeMilestoneNotes: any;

  constructor(
    private agreementService: AgreementService,
    public opportunityService: OpportunityService,
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    public authService: AuthService,
    private broadcastService: BroadcastService
  ) {}

  setData(
    expired: IAgreement[],
    invoices: IAgreement[],
    addons: any[],
    milestones: any[],
    purchasedHours: any[]
  ): void {
    this.expiringAgreements = expired;
    this.dueInvoices = invoices;
    this.addons = addons;
    this.milestones = milestones;
    this.purchasedHours = purchasedHours;
  }

  transformDate(date: string): string {
    return moment(date).format('DD/MM/Y');
  }

  ngOnInit(): void {
    const { expired, invoices, addons, milestones, purchasedHours } =
      this.route.snapshot.data['data'];
    this.setData(expired, invoices, addons, milestones, purchasedHours);

    this.triggerBroadcaster = this.broadcastService.getObservable().pipe(
      filter(([type]: string[]) => type === 'due-invoices'),
      map(([, event]: string[]) => event)
    );
  }

  updateNextInvoiceDate(agreement: IAgreement, addon: any): void {
    const addonId = addon && addon.Id;

    this.agreementService
      .updateNextInvoiceDate(agreement.AgreementNumber, addonId)
      .subscribe((data: any) => {
        if (addon) {
          addon.NextInvoiceDate = data.NextInvoiceDate;
          this.getAddons();
        } else {
          agreement.NextInvoiceDate = data.NextInvoiceDate;
          this.getDueInvoices();
        }

        this.broadcastService.next(['due-invoices', 'close']);
      });
  }

  getAddons(force: boolean = false): void {
    this.pending = true;
    this.agreementService
      .getSeparateAddons(force)
      .pipe(tap(() => (this.pending = false)))
      .subscribe((addons: any[]) => (this.addons = addons));
  }

  getDueInvoices(force: boolean = false): void {
    this.pending = true;
    this.agreementService
      .getDueInvoices(force)
      .pipe(tap(() => (this.pending = false)))
      .subscribe((agreements: IAgreement[]) => (this.dueInvoices = agreements));
  }

  getMilestones(force = false) {
    this.pending = true;
    this.invoiceService
      .getInvoicableMilestones(force)
      .pipe(tap(() => (this.pending = false)))
      .subscribe((milestones) => (this.milestones = milestones));
  }
  getPurchasedHours(force = false) {
    this.pending = true;
    this.invoiceService
      .getInvoicablePurchasedHours(force)
      .pipe(tap(() => (this.pending = false)))
      .subscribe((hours) => (this.purchasedHours = hours));
  }
  invoiceMilestone(milestone, status, notes) {
    if (status === 20 && !this.approveMilestoneChange) {
      this.approveMilestoneChange = true;
      return;
    }
    this.isMilestoneRemoving = true;
    this.opportunityService
      .updateMilestone(milestone.JobNumber, {
        Id: milestone.Id,
        Status: status,
        Notes: notes,
      })
      .subscribe(() => {
        this.getMilestones(true);
        this.broadcastService.next(['due-invoices', 'close']);
      });
  }
  afterHoursInvoice() {
    this.broadcastService.next(['dashboard', 'close']);
    this.getPurchasedHours(true);
  }
  getExpired(force: boolean = false): void {
    this.pending = true;
    this.agreementService
      .getExpired(force)
      .pipe(tap(() => (this.pending = false)))
      .subscribe(
        (agreements: IAgreement[]) => (this.expiringAgreements = agreements)
      );
  }
}
