import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { positiveValidator } from "../../prototype/validator";

import { AgreementService } from "../../service/agreement.service";
import { OpportunityService } from "../../service/opportunity.service";
import { Project } from "../projects";

@Component({
  templateUrl: "milestone-form.component.html",
  selector: "milestone-form",
})
export class MilestoneFormComponent implements OnChanges {
  @Input() id!: string;
  @Input("using-service") usingService: string = "opportunity";
  @Input() projects: Project[] = [];
  @Input() modelInstance;

  @Output() formSaved = new EventEmitter();

  form!: FormGroup;
  formPending: boolean = false;

  currencies = ["AUD", "EUR", "USD", "NZD", "CAD"];
  milestoneStatuses = [
    [0, "Not ready to invoice"],
    [10, "Ready to invoice"],
    [20, "Invoiced"],
  ];

  constructor(
    private formBuilder: FormBuilder,
    private agreementService: AgreementService,
    private opportunityService: OpportunityService
  ) { }

  ngOnInit(): void {
    this.form = this.initForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    const model = changes['modelInstance'];
    if (model) {
      this.fillForm(model.currentValue);
    }
  }
  fillForm(instance: any = {}) {
    if (!this.form) {
      return;
    }
    const data = {
      Id: "",
      Name: "",
      DueDate: "",
      Status: 0,
      HourlyRate: 175,
      Value: 0,
      Currency: "AUD",
      Rate: 1,
      Notes: "",
      ProjectId: "",
      PurchaseOrder: "",
    };
    for (let key in instance) {
      if (key in data && instance[key]) {
        data[key] = instance[key];
      }
    }
    if (!data.Id) {
      data.HourlyRate = 195;
    }

    this.form.setValue(data);
  }

  initForm(): FormGroup {
    let form = this.formBuilder.group({
      Id: ["", []],
      Name: ["", [Validators.required]],
      DueDate: ["", [Validators.required]],
      Status: [0, [Validators.required]],
      HourlyRate: [195, [Validators.required, positiveValidator]],
      Value: [0, [Validators.required]],
      Currency: ["AUD", [Validators.required]],
      Rate: [1, [Validators.required, positiveValidator]],
      Notes: ["", []],
      ProjectId: ["", []],
      PurchaseOrder: ["", []],
    });

    form.controls["ProjectId"].valueChanges.subscribe((data) => {
      let ctrl = form.controls["PurchaseOrder"];
      if (!ctrl) {
        return;
      }
      if (!ctrl.value) {
        let project = this.projects.find(({ Id }) => Id === data);
        if (project) ctrl.setValue(project.PurchaseOrder);
      }
    });
    return form;
  }

  onSubmit(): void {
    let service =
      this.usingService === "opportunity"
        ? this.opportunityService
        : this.agreementService;
    this.formPending = true;

    const data = this.form.value;

    service[data.Id ? "updateMilestone" : "addMilestone"](
      this.id,
      data
    ).subscribe(
      () => {
        this.formPending = false;
        this.form = this.initForm();
        this.formSaved.emit();
      },
      (err) => {
        console.error(err);
        this.formPending = false;
        this.form = this.initForm();
        this.formSaved.emit();
      }
    );
  }
}
