import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import {
  ResourceRequest,
  ResourceRequestsService,
  IResourceRequest,
  IResourceType,
  GroupOptions,
} from '.';
import { Validators, FormBuilder } from '@angular/forms';

import { AbstractEntityFormComponent } from '../prototype/entity-form.component';

import { MessengerService } from '../service/messenger.service';

import { Observable } from 'rxjs';

@Component({
  selector: 'resource-requests-type-form',
  templateUrl: './resource-requests-type-form.component.html',
})
export class ResourceRequestTypeFormComponent extends AbstractEntityFormComponent<
  IResourceRequest,
  ResourceRequest
> {
  forEdit = true;

  @Input()
  set model(item) {
    item = item || [undefined, undefined];
    let [idx, model] = item as [number, ResourceRequest];
    this.idx = idx;
    this.modelInstance = model;
    this.initForm();
  }

  @Input() broadcaster!: Observable<string>;

  @Output() formSaved = new EventEmitter<void>();
  @Output() resourceTypeUpdated = new EventEmitter<[number, IResourceType]>();
  GroupOptions: Array<string> = GroupOptions;
  idx: number = 0;

  ngOnInit() {}

  constructor(
    protected messengerService: MessengerService,
    private formBuilder: FormBuilder,
    protected itemService: ResourceRequestsService
  ) {
    super();
  }

  getResourceTypeForm() {
    return this.formBuilder.group({
      Name: ['', [Validators.required]],
      Group: [this.GroupOptions[0], [Validators.required]],
      Allocation: [0, [Validators.required]],
      Expenditure: [0, [Validators.required]],
      Notes: ['', []],
    });
  }

  initForm(): void {
    this.form = this.getResourceTypeForm();
    let data = {
      Name: '',
      Group: '',
      Allocation: 0,
      Expenditure: 0,
      Notes: '',
    };
    if (this.modelInstance && this.idx !== undefined) {
      let types = (<IResourceRequest>this.modelInstance).ResourceTypes;
      if (types) {
        this.cleanEmpty(Object.assign(data, types[this.idx]), '');
      }
    }
    this.form.setValue(data);
  }
  onSubmit() {
    if (this.modelInstance && this.idx !== undefined) {
      let types = (<IResourceRequest>this.modelInstance).ResourceTypes;
      if (types) {
        types[this.idx] = this.form.value;
      }
    }
    super.onSubmit();
  }
}
