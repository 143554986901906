
import { Component, OnInit } from '@angular/core';

import { UserService } from '../service';
import { IUser, Capacities } from '../prototype';

class Record {
  activeDev = null;
  ProjectName = '';
  CkanCapacity = 0;
  DrupalCapacity = 0;
  JsCapacity = 0;
  OtherCapacity = 0;
  DevTeam: any[] = [];

  EstimatedDays = 0;
  Efficiency = 0;

  constructor(data = {}) {
    Object.assign(this, data);
  }
  recalculate() {
    const techs = ['Ckan', 'Drupal', 'Js', 'Other'];
    const capacities = techs.map((tech) =>
      this.DevTeam.map((dev) => dev[tech + 'Capacity'])
        .map((capacity) => (capacity == null ? Capacities[tech] : capacity))
        .reduce((a, b) => a + b, 0)
    );

    this.EstimatedDays =
      techs
        .map((tech, idx) => this[tech + 'Capacity'] / capacities[idx] || 0)
        .reduce((a, b) => a + b, 0) * 22;

    const totalEfficiency = techs
      .map((tech, idx) =>
        this[tech + 'Capacity'] ? capacities[idx] / 30 || 0 : 0
      )
      .reduce((a, b) => a + b, 0);
    const numberOfActiveTechs = techs
      .map((tech) => this[tech + 'Capacity'])
      .filter(Boolean).length;
    this.Efficiency =
      (totalEfficiency / numberOfActiveTechs / this.DevTeam.length) * 100 || 0;
  }
}

const STORAGE_KEY = 'resourceCalculatorRecords';

const restoreRecords: () => Record[] = () =>
  JSON.parse(window.localStorage.getItem(STORAGE_KEY) || '[]');
const saveRecords = (records) =>
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(records));

@Component({
  selector: 'resource-calculator',
  templateUrl: 'resource-calculator.component.html',
  styles: [
    `
      .dev-team-members li {
        margin-bottom: 10px;
      }
    `,
    `
      .dev-team-members li {
        margin-bottom: 20px;
      }
    `,
    `
      .dev-team-members li {
        display: inline-block;
        margin-right: 10px;
      }
    `,
  ],
})
export class ResourceCalculatorComponent implements OnInit {
  record = new Record();
  records: any[] = [];
  devTeam: any[] = [];
  constructor(private userService: UserService) {}

  ngOnInit() {
    const items = restoreRecords();
    const users$ = this.userService
      .getAll(true)
      .toPromise()
      .then((users = []) => {
        this.devTeam = users.filter(
          (user) => user.Team === 'dev' && user.State === 'active'
        );
        const devMapping = this.devTeam.reduce((team, dev) => {
          team[dev.Email] = dev;
          return team;
        }, {});
        this.records = items.map((record) => {
          record.DevTeam = record.DevTeam.map((dev) => devMapping[dev.Email]);
          record = new Record(record);
          record.recalculate();
          return record;
        });
      });
  }

  resetRecord() {
    this.record = new Record();
  }
  addDev(record: Record, dev: IUser|null) {
    if (!dev) {
      return;
    }
    record.DevTeam = [...record.DevTeam, dev];
  }
  dropDev(record: Record, dev: IUser) {
    record.DevTeam = record.DevTeam.filter((member) => member !== dev);
    record.activeDev = null;
  }
  recalculateRecord(record: Record) {
    record.recalculate();
    this.takeSnapshot();
  }
  addRecord(record: Record) {
    this.records.unshift(record);
    this.resetRecord();
    this.takeSnapshot();
  }
  dropRecord(record: Record) {
    this.records = this.records.filter((r) => r !== record);
    this.takeSnapshot();
  }
  takeSnapshot() {
    saveRecords(this.records);
  }
}
