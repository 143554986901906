import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import { MessengerService } from "./messenger.service";
import { LambdaService } from "./lambda.service";
import { FetchAbstractService } from "./fetch-abstract.service";
import { AuthService } from "./auth.service";

import { IUser } from "../prototype/user.prototype";

@Injectable()
export class UserService extends FetchAbstractService<IUser> {
  protected uniqueKeyName: string = "Email";

  constructor(
    protected http: HttpClient,
    protected lambda: LambdaService,
    protected auth: AuthService,
    protected messenger: MessengerService
  ) {
    super();
  }

  getLoadAllUrl(): string {
    return this.lambda.getUsersUrl();
  }

  getSaveUrl(forEdit: boolean = false): string {
    return this.lambda.getUsersUrl();
  }

  getEditUrl(user: IUser): string {
    return this.getSaveUrl() + "/" + user.Email;
  }

  getDeleteUrl(user: IUser): string {
    return this.getEditUrl(user);
  }

  getLoadAllErrorMsg(): string {
    return "List of users is unavailable";
  }

  delete(user: IUser): Observable<IUser> {
    let request: Observable<IUser> = this.http
      .delete<IUser>(this.getDeleteUrl(user), this.getDefaultHeaders());
    return request;
  }

  getAllUsers(onlyActive: boolean = false): Observable<Set<string>> {
    let users = this.getAll();
    if (onlyActive) {
      users = users.pipe(map(all => all.filter(u => u.State === "active")));
    }

    return users.pipe(
      map(
        (items: IUser[]) => new Set(items.map((item: IUser) => item.FullName))
      )
    );
  }
}
