import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import {
  AgreementService,
  InvoiceService,
  MailService,
  OpportunityService,
} from "../../service";
import * as moment from "moment";

@Component({
  templateUrl: "hours-modals.component.html",
  selector: "hours-modals",
})
export class HoursModalsComponent {
  @Input() id;

  @Input() hoursType;
  @Input() commisionable;
  @Input() invoiceHoursOptions;
  @Input() projects;
  @Input() ownerType;
  @Input() ownerId;
  @Output() save = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() remove = new EventEmitter();

  protected message: string;
  protected approvedChange = false;
  protected item: any;
  protected statuses = [
    [0, "Not ready to invoice"],
    [10, "Ready to invoice"],
    [20, "Invoiced"],
  ];

  constructor(
    private agreementService: AgreementService,
    private opportunityService: OpportunityService,
    private invoiceService: InvoiceService,
    private mailService: MailService
  ) {
    this.message = this.mailService.getReadyToInvoiceMsg();
  }

  ngOnChanges(changes: SimpleChanges) {
    const options = changes['invoiceHoursOptions'];
    if (options && options.currentValue) {
      const [idx, , list] = options.currentValue;
      this.item = Object.assign({}, list && list[idx]);
    } else {
      this.item = {};
    }
  }

  onRemove() {
    this.invoiceService
      .deleteHours(this.ownerType, this.ownerId, this.invoiceHoursOptions)
      .toPromise()
      .then(() => this.remove.emit());
  }
  updateHours(data) {
    data.Status = +data.Status;

    if (data.Status === 20 && !this.approvedChange) {
      this.approvedChange = true;
      return;
    }

    data.InvoiceDate = moment().format("YYYY-MM-DD");
    this.invoiceService
      .updateHours(this.ownerType, this.ownerId, data, this.hoursType)
      .toPromise()
      .then(() => {
        this.update.emit();
        this.approvedChange = false;

        const project =
          data.ProjectId &&
          this.projects.find(({ Id }) => Id === data.ProjectId);

        if (data.Status === 10) {
          this.mailService.sendPendingInvoice(
            this.message,
            project,
            this.ownerType,
            this.ownerId
          );
        }
      });
  }
}
