<table *ngIf="milestones.length" class="table table-bordered table-lg table-hover">
    <thead>
      <tr>
        <th colspan="7" class="text-center">Milestones</th>
      </tr>
        <tr>
            <th>Name</th>
            <th>Notes</th>
            <th>Int. Value</th>
            <th>Value (AUD)</th>
            <th>Due Date</th>
            <th>PO</th>
            <th>Invoicing</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr  *ngFor="let m of milestones; let i = index">
            <td>{{ m.Name }}</td>
            <td>
                {{ m.Notes }}
            </td>
            <td>{{ m.Value | number:'1.2' }} {{ m.Currency }}</td>
            <td>${{ (m.Currency === 'AUD' ? m.Value : m.Value / m.Rate) | number:'1.2' }}</td>
            <td [class]="{'color-red': (m.DueDate | moment).isBefore()}">{{ m.DueDate | date: 'dd/MM/y' }}</td>
            <td>{{ m.PurchaseOrder }}</td>
            <td [ngSwitch]="m.Status" [attr.data-status]="m.Status">
                <div *ngSwitchCase="20" class="invoiced-label">Invoiced</div>
                <button *ngSwitchDefault
                        type="button" class="btn btn-transparent btn-xs"
                        [ngClass]="'milestone-status-' + m.Status"
                        data-toggle="modal" data-target="#update-milestone-modal"
                        (click)="onActivate(m)"
                >
                    <i class="glyphicon glyphicon-check"></i>
                    {{ milestoneStatuses[m.Status] }}
                </button>
            </td>
            <td>
              <button *ngIf="canCreate()" type="button" class="btn btn-success btn-xs"
                data-toggle="modal" data-target="#milestone-form-modal"
                (click)="onActivate(m)">
                <i class="glyphicon glyphicon-pencil"></i>
              </button>

              <button *ngIf="canDelete()" type="button" class="btn btn-danger btn-xs"
                data-toggle="modal" data-target="#delete-milestone-modal"
                (click)="onActivate(m)" >
                  <i class="glyphicon glyphicon-trash"></i>
                </button>
            </td>

        </tr>
    </tbody>
</table>
