import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Client } from './client-account';
import { IClient } from '../../prototype/client.prototype';

import { FormControl, Validators, FormBuilder } from '@angular/forms';

import { AbstractEntityFormComponent } from '../../prototype/entity-form.component';

import { MessengerService } from '../../service/messenger.service';
import { ClientService } from '../../service/client.service';

import { Observable } from 'rxjs';

@Component({
  selector: 'client-account-form',
  templateUrl: './client-form.template.html',
})
export class ClientFormComponent extends AbstractEntityFormComponent<
  IClient,
  Client
> {
  @Input()
  set model(item: Client) {
    this.modelInstance = item;

    this.forEdit = !!item.ClientCode;
    this.initForm();
  }

  @Input() broadcaster!: Observable<string>;

  @Output() formSaved = new EventEmitter<void>();

  constructor(
    protected messengerService: MessengerService,
    private formBuilder: FormBuilder,
    protected itemService: ClientService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscription = this.broadcaster.subscribe((event: string) => {
      switch (event) {
        case 'edit':
          (<FormControl>this.form.controls['ClientCode']).disable();
          this.forEdit = true;
          break;
        case 'prepare':
          (<FormControl>this.form.controls['ClientCode']).enable();
          this.forEdit = false;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      ClientCode: ['', [Validators.required]],
      ClientName: ['', [Validators.required]],
      XeroId: ['', []],
      InsightlyId: ['', []],
      AwsId: ['', []],
      Commissionable: [false, [Validators.required]],
      Notes: ['', []],
    });

    if (this.forEdit) {
      (<FormControl>this.form.controls['ClientCode']).disable();
    } else {
      (<FormControl>this.form.controls['ClientCode']).enable();
    }
    let data = {
      ClientCode: '',
      ClientName: '',
      XeroId: '',
      InsightlyId: '',
      AwsId: '',
      Commissionable: false,
      Notes: '',
    };
    this.cleanEmpty(Object.assign(data, this.modelInstance), '');

    this.form.setValue(data);
  }
}
