export interface IResourceType {
  Name: string;
  Group: string;
  Allocation: number;
  Expenditure: number;
  Notes: string;
}

export interface IResourceRequest {
  Id?: string;
  RequestedAt: string;

  StartDate: string;
  EndDate: string;

  LinkedProject: string;
  Status: number;
  ResourceTypes: Array<IResourceType>;
}

export class ResourceRequest {
  Id?: string = "";
  Status: number = 0;
  ResourceTypes = []

  RequestedAt = ""
  StartDate = ""
  EndDate = ""

  constructor(public LinkedProject: string = "") {}
}

export class ResourceType implements IResourceType {
  constructor(
    public Name: string = "",
    public Group: string = "",
    public Allocation: number = 0,
    public Expenditure: number = 0,
    public Notes: string = ""
  ) {}
}

export const GroupOptions = [
  "Designer",
  "Developer",
  "Management",
  "Contractor",
  "Devops",
  "Analyst/Archetect",
  "Other"
];
