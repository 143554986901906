import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  Statuses,
  BillingCycles,
  ServiceTypes,
  AddonNames,
} from '../../prototype/agreement.prototype';

import { AgreementService } from '../../service/agreement.service';
import {
  positiveValidator,
  endDateValidator,
  startDateValidator,
} from '../../prototype/validator';

@Component({
  templateUrl: 'addon-form.template.html',
  selector: 'addon-form',
})
export class AddonFormComponent {
  @Input('agreement-number') agreementNumber!: string;

  @Output() formSaved = new EventEmitter();

  collections: any = {
    Statuses,
    BillingCycles,
    ServiceTypes,
    AddonNames,
  };

  form!: FormGroup;
  formPending: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private agreementService: AgreementService
  ) {}

  ngOnInit(): void {
    this.form = this.initForm();
  }

  initForm(): FormGroup {
    let currentDate = new Date();
    let form = this.formBuilder.group({
      AddonName: ['', Validators.required],
      StartDate: ['', [Validators.required, startDateValidator]],
      EndDate: ['', [Validators.required, endDateValidator]],
      BillingCycle: ['', Validators.required],
      Status: ['', Validators.required],
      InvoiceAmount: ['', [Validators.required, positiveValidator]],
      InvoiceSeparately: [''],
      NextInvoiceDate: [''],
    });
    return form;
  }

  onSubmit(): void {
    this.formPending = true;
    this.agreementService
      .addAddon(this.agreementNumber, this.form.value)
      .subscribe(
        () => {
          this.formPending = false;
          this.form = this.initForm();
          this.formSaved.emit();
        },
        (err) => (this.formPending = false)
      );
  }
}
