import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  IOpportunity,
  Opportunity,
  StatusOptions,
} from '../../prototype/opportunity.prototype';

import { FormControl, Validators, FormBuilder } from '@angular/forms';

import { MessengerService } from '../../service/messenger.service';
import { ClientService } from '../../service/client.service';
import { UserService } from '../../service/user.service';
import { OpportunityService } from '../../service/opportunity.service';

import { Observable } from 'rxjs';

import { AbstractEntityFormComponent } from '../../prototype/entity-form.component';

@Component({
  selector: 'opportunity-form',
  templateUrl: './opportunity-form.template.html',
})
export class OpportunityFormComponent extends AbstractEntityFormComponent<
  IOpportunity,
  Opportunity
> {
  @Input()
  set model(item: Opportunity) {
    this.modelInstance = item || new Opportunity();
    this.forEdit = !!this.modelInstance.JobNumber;
    this.initForm();
  }

  @Input() broadcaster!: Observable<string>;

  @Output() formSaved = new EventEmitter<void>();

  clientCodes: Set<string> = new Set<string>();
  private commissionableCodes: Set<string> = new Set<string>();
  StatusOptions: Array<string> = StatusOptions;
  usersList: Set<string> = new Set<string>();

  constructor(
    protected messengerService: MessengerService,
    private formBuilder: FormBuilder,
    protected itemService: OpportunityService,
    private clientService: ClientService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientService
      .getClientCodes()
      .subscribe(
        (clientCodes: Set<string>) => (this.clientCodes = clientCodes)
      );
    this.clientService
      .getCommissionableCodes()
      .subscribe(
        (commissionableCodes: Set<string>) =>
          (this.commissionableCodes = commissionableCodes)
      );
    this.userService
      .getAllUsers(true)
      .subscribe((usersList: Set<string>) => (this.usersList = usersList));

    this.subscription = this.broadcaster.subscribe((event: string) => {
      switch (event) {
        case 'edit':
          this.forEdit = true;
          break;
        case 'prepare':
          this.forEdit = false;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      ClientCode: [this.clientCodes[0], [Validators.required]],
      JobNumber: ['', []],
      JiraTitle: ['', []],
      Status: [this.StatusOptions[0], [Validators.required]],
      Title: ['', [Validators.required]],
      ProductOwner: ['', []],
      Commissionable: [false, [Validators.required]],
      Notes: ['', []],
      BudgetNote: ['', []],
    });

    let data = {
      ClientCode: '',
      Title: '',
      Commissionable: false,
      Notes: '',
      BudgetNote: '',
      Status: '',
      PurchasedHours: [],
      UnchargeableHours: [],
      ProfitableHours: [],
    };
    this.cleanEmpty(Object.assign(data, this.modelInstance), '');

    // Status field has to have default value
    data.Status = data.Status ? data.Status : this.StatusOptions[0];
    this.form.patchValue(data);
  }

  beforeSubmit(data: any): void {
    delete data.UnchargeableHours;
    delete data.ProfitableHours;
    delete data.PurchasedHours;
  }

  clientCodeChanged(val: string): void {
    (<FormControl>this.form.controls['ClientCode']).setValue(val);
    this.form.controls['Commissionable'].setValue(
      this.commissionableCodes.has(val)
    );
  }

  productOwnerChanged(val: string): void {
    (<FormControl>this.form.controls['ProductOwner']).setValue(val);
  }
}
