import { Component, Input, Output, EventEmitter } from "@angular/core";
import { InvoiceService, MailService } from "../../service";

@Component({
  templateUrl: "milestone-modals.component.html",
  selector: "milestone-modals",
})
export class MilestoneModalsComponent {
  @Input() activated;

  @Input() ownerType;
  @Input() ownerId;

  @Input() projects: any[] = [];

  @Output() save = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() remove = new EventEmitter();

  protected message: string;
  constructor(
    protected invoiceService: InvoiceService,
    protected mailService: MailService
  ) {
    this.message = this.mailService.getReadyToInvoiceMsg();
  }
  saveMilestone() {
    this.save.emit();
  }
  updateMilestone(id: string, status: number, notes: string) {
    if (status === 20 && !this.activated.approveChange) {
      this.activated.approveChange = true;
      return;
    }
    this.invoiceService
      .updateMilestone(this.ownerType, this.ownerId, { id, status, notes })
      .toPromise()
      .then(() => this.update.emit());
    const milestone = this.activated.milestone;

    const project =
      milestone.ProjectId &&
      this.projects.find(({ Id }) => Id === milestone.ProjectId);

    if (status === 10) {
      this.mailService.sendPendingInvoice(
        this.message,
        project,
        this.ownerType,
        this.ownerId
      );
    }
  }

  deleteMilestone(id: string) {
    this.invoiceService
      .deleteMilestone(this.ownerType, this.ownerId, id)
      .toPromise()
      .then(() => this.remove.emit());
  }
}
