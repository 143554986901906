import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  ClientService,
  ResourcesService,
  MailService,
  InvoiceService,
  ExporterService,
} from './service';
import { OpportunityService } from './service/opportunity.service';
import { AgreementService } from './service/agreement.service';
import { LambdaService } from './service/lambda.service';
import { MessengerService } from './service/messenger.service';
import { AuthService } from './service/auth.service';
import { AuthGuardService } from './service/auth-guard.service';
import { BroadcastService } from './service/broadcast.service';
import { HistoryRecordService } from './service/history-record.service';
import { JiraService } from './service/jira.service';
import { UserService } from './service/user.service';

import { ResourceRequestsModule } from './resource-requests/resource-requests.module';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ResourceRequestsModule,
    BrowserAnimationsModule,

    MatSnackBarModule,
    MatDialogModule,
  ],
  providers: [
    ClientService,
    OpportunityService,
    AgreementService,
    LambdaService,
    MessengerService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '836850100882-rmkcr41lqd31d0eck3jas8fqqo6q9e1i.apps.googleusercontent.com',
              { oneTapEnabled: true }
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    AuthService,
    AuthGuardService,
    BroadcastService,
    HistoryRecordService,
    JiraService,
    UserService,
    ResourcesService,
    MailService,
    ExporterService,
    InvoiceService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
