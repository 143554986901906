import { Injectable } from '@angular/core';

import * as fileSaver from "file-saver";

type CsvData = {
  headers: string[],
  rows: Array<string[] | object>
}

@Injectable()
export class ExporterService {
  asFormat(data: string, name: string, mimetype: string, extension?: string) {
    let file = new Blob([data], { type: mimetype });
    const ext = extension || mimetype.split('/')[1]
    fileSaver.saveAs(file, `${name}.${ext}`);
  }

  asCsv(data: CsvData, name: string) {
    let content = data.headers.join(',') + '\n';
    for (let row of data.rows) {
      let values: string[];
      if (!Array.isArray(row)) {
        values = data.headers.map(col => row[col] || '')
      } else {
        values = row;
      }
      content += values.map(v => '"' + v.replace(/"/g, '""') + '"').join(',') + '\n'
    }
    this.asFormat(content, name, 'text/csv')
  }
}
