import { Component } from '@angular/core';

import {
  AuthService,
  BroadcastService,
  MessengerService,
  UserService,
} from '../../service';

import {
  IUser,
  User,
  Roles,
  States,
  Teams,
} from '../../prototype/user.prototype';
import { AbstractEntityComponent } from '../../prototype/entity-component';
import { MatTableModule } from '@angular/material/table';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { ComponentModule } from 'src/app/components';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [MatTableModule, DirectiveModule, ComponentModule, MatPaginatorModule, CommonModule],
  templateUrl: './users.component.html',
})
export class UsersComponent extends AbstractEntityComponent<IUser, User> {
  protected eventType: string = 'userAccount';
  protected formModel: User = new User();

  isDeleting: boolean = false;
  States = States;
  Roles = Roles;
  Teams = Teams;

  constructor(
    protected authService: AuthService,
    protected itemService: UserService,
    protected broadcastService: BroadcastService,
    private messengerService: MessengerService
  ) {
    super();
  }

  afterSave(): void {
    this.formModel = new User();
    this.pull(true);
    super.afterSave();
  }

  prepareForm(user?: IUser): void {
    this.formHeading = `${user ? 'Update' : 'Create'} User Account`;
    this.formModel = user || new User();
    super.prepareForm(user);
  }

  edit(user: IUser) {
    this.prepareForm(user);
    super.edit(user);
  }

  delete(user: IUser) {
    this.isDeleting = true;
    this.itemService.delete(user).subscribe(() => this.afterSave());
  }
}
