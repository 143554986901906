import {
  Component,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  getYears,
  getMonths,
  getYearsRange,
} from "../../prototype/agreement.prototype";
import { FormControl } from "@angular/forms";

import { AgreementService } from "../../service/agreement.service";
import { OpportunityService } from "../../service/opportunity.service";

import {
  positiveValidator,
  expireMonthValidator,
  expireYearValidator,
} from "../../prototype/validator";
import { Project } from "../../modules/projects";

declare var jQuery: any;

@Component({
  templateUrl: "hours-form.template.html",
  selector: "hours-form",
})
export class HoursComponent implements OnChanges {
  @Input("agreement-number") idNumber!: string;
  @Input("hours-options") hoursOptions;
  @Input("using-service") usingService: string = "agreement";
  @Input() type!: string;
  @Input() commissionable!: boolean;
  @Input() projects: Project[] = [];
  @Input() modelInstance;

  @Output() formSaved = new EventEmitter();

  statuses = [
    [0, "Not ready to invoice"],
    [10, "Ready to invoice"],
    [20, "Invoiced"],
  ];

  form!: FormGroup;
  formPending: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private agreementService: AgreementService,
    private opportunityService: OpportunityService
  ) {}

  ngOnInit(): void {
    this.form = this.initForm();
  }

  collections: any = {
    getYears,
    getMonths,
    getYearsRange,
  };

  ngOnChanges(changes: SimpleChanges) {
    const options = changes['hoursOptions'];

    if (options && options.currentValue) {
      let [idx, , items] = options.currentValue;
      this.fillForm(items && items[idx]);
    }
  }

  fillForm(instance: any = {}) {
    if (!this.form) {
      return;
    }
    let currentDate = new Date();
    const data = {
      Id: "",
      Month: getMonths[currentDate.getMonth()],
      Year: currentDate.getFullYear(),
      Hours: "",
      Notes: "",
      ExpireMonth: "",
      ExpireYear: "",
      DueDate: "",
      Commissionable: this.commissionable || false,
      HourlyRate: 175,
      Value: 0,
      Currency: "AUD",
      Rate: 1,
      Status: 0,
      ProjectId: "",
      PurchaseOrder: "",
    };
    for (let key in instance) {
      if (key in data && instance[key]) {
        data[key] = instance[key];
      }
    }
    this.form.setValue(data);
  }

  monthChanged(val: string): void {
    (<FormControl>this.form.controls["Month"]).setValue(val);
  }

  expireMonthChanged(val: string): void {
    (<FormControl>this.form.controls["ExpireMonth"]).setValue(val);
  }

  isCharged() {
    return this.type === "purchased";
  }

  isUnforeseen(): boolean {
    return this.type === 'unforeseen';
  }


  initForm(): FormGroup {
    let currentDate = new Date();
    let form = this.formBuilder.group({
      Id: ["", []],
      Month: [getMonths[currentDate.getMonth()], [Validators.required]],
      Year: [currentDate.getFullYear(), [Validators.required]],
      Hours: ["", this.isUnforeseen() ? [Validators.required] : [Validators.required, positiveValidator]],
      Notes: ["", [Validators.required]],
      ExpireMonth: ["", [expireMonthValidator]],
      ExpireYear: ["", [expireYearValidator]],
      DueDate: ["", this.isCharged() ? [Validators.required] : []],
      Commissionable: [this.commissionable || false, []],
      HourlyRate: [
        175,
        this.commissionable ? [Validators.required, positiveValidator] : [],
      ],
      Value: [0, this.isUnforeseen() ? [] : [Validators.required]],
      Currency: [
        "AUD",
        this.commissionable ? [Validators.required, positiveValidator] : [],
      ],
      Rate: [1, [Validators.required, positiveValidator]],
      Status: [this.isCharged() ? 0 : 20, []],
      ProjectId: ["", []],
      PurchaseOrder: ["", []],
    });

    form.controls["HourlyRate"].valueChanges.subscribe((data: any) => {
      let ctrl = form.controls["Value"];
      if (!ctrl) {
        return;
      }
      ctrl.setValue(form.controls["Hours"].value as any * data);
    });
    form.controls["Hours"].valueChanges.subscribe((data: any) => {
      let ctrl = form.controls["Value"];
      if (!ctrl) {
        return;
      }
      ctrl.setValue(form.controls["HourlyRate"].value! * data);
    });

    form.controls["ProjectId"].valueChanges.subscribe((data) => {
      let ctrl = form.controls["PurchaseOrder"];
      if (!ctrl) {
        return;
      }
      if (!ctrl.value) {
        let project = this.projects.find(({ Id }) => Id === data);
        if (project) ctrl.setValue(project.PurchaseOrder);
      }
    });

    form.controls["Commissionable"].valueChanges.subscribe((data) => {
      let ctrl = form.controls["HourlyRate"];
      if (!ctrl) {
        return;
      }
      ctrl.setValidators(data ? [Validators.required, positiveValidator] : []);
      ctrl.updateValueAndValidity();
    });

    return form;
  }

  removeExpire(): void {
    (<FormControl>this.form.controls["ExpireMonth"]).setValue("");
    jQuery("select#expire-month.select2").select2().trigger("change");
    (<FormControl>this.form.controls["ExpireYear"]).setValue("");
  }

  onSubmit(): void {
    let service =
      this.usingService === "opportunity"
        ? this.opportunityService
        : this.agreementService;
    this.formPending = true;
    const data = this.form.value;
    service[data.Id ? "updateHours" : "addHours"](
      this.idNumber,
      data,
      this.type
    ).subscribe(
      () => {
        this.formPending = false;
        this.form = this.initForm();
        jQuery("select#expire-month.select2").select2("val", "");
        this.formSaved.emit();
      },
      (err) => (this.formPending = false)
    );
  }
}
