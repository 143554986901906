import { Component, Input, EventEmitter, Output } from '@angular/core';

import {
  ProjectsService,
  IProject,
  Project,
  // IResourceType
} from '../../modules/projects';
import { MessengerService } from '../../service/messenger.service';
import { BroadcastService } from '../../service/broadcast.service';
import { AuthService } from '../../service/auth.service';

import { AbstractEntityComponent } from '../../prototype/entity-component';
import {
  AgreementService,
  ExporterService,
  OpportunityService,
  UserService,
} from '../../service';

import { map, tap, zip } from 'rxjs';
import * as moment from 'moment';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ComponentModule } from 'src/app/components';
import { RouterModule } from '@angular/router';
import { HoursModule, MilestoneModule } from 'src/app/modules';
import { ProjectsModule } from 'src/app/modules/projects/projects.module';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { IUser } from 'src/app/prototype';
import {
  OverdueInvoices,
  ToBeInvoicedTotal,
  UpcomingInvoices,
} from 'src/app/modules/projects/projects.pipe';

function endOfQuarter() {
  const date = moment();
  if (date.month() === 11) {
    date.year(date.year() + 1);
  }
  date.month(Math.floor(((date.month() + 1) % 12) / 3) * 3 + 1).endOf('month');
  return date;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    ComponentModule,
    RouterModule,
    MilestoneModule,
    HoursModule,
    ProjectsModule,
    DirectiveModule,
    ToBeInvoicedTotal,
    OverdueInvoices,
    UpcomingInvoices,
  ],
  templateUrl: './projects.component.html',
})
export class ProjectsComponent extends AbstractEntityComponent<
  IProject,
  Project
> {
  protected formModel: Project = new Project();
  protected eventType: string = 'project';

  protected pageLimit: number = 500;
  protected activatedMilestone: any = {};
  protected invoiceHoursOptions: any = {};

  projectsForMilestones: any[] = [];
  projectsForPurchasedHours: any[] = [];

  usersList: IUser[] = [];

  protected upcomingStart = moment().format('Y-MM-DD');
  // compute the end of the quarter. You can use switch here, but this expression is shorter.
  protected upcomingEnd = endOfQuarter().format('Y-MM-DD');

  @Input() milestones = [];
  @Input() purchasedHours = [];
  @Input() commisionable = [];

  @Output() milestoneAdded = new EventEmitter();
  @Output() milestoneUpdated = new EventEmitter();
  @Output() milestoneDeleted = new EventEmitter();
  @Output() hoursSaved = new EventEmitter();
  @Output() hoursRemoved = new EventEmitter();

  expandedRows = {};
  activeTypeIdx: number = 0;
  removedId: any;

  productOwnerRouterMapping = {
    opportunity: 'products',
    agreement: 'agreements',
  };

  constructor(
    protected authService: AuthService,
    protected itemService: ProjectsService,
    protected broadcastService: BroadcastService,
    private messengerService: MessengerService,
    private opportunityService: OpportunityService,
    private agreementService: AgreementService,
    private exporterService: ExporterService,
    private users: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.users
      .getAll()
      .pipe(map((all) => all.filter((u) => u.State === 'active')))
      .subscribe(
        (usersList: IUser[]) =>
          (this.usersList = usersList.sort(({ Email: a }, { Email: b }) => {
            if (a === b) return 0;
            if (a === this.authService.email) return -1;
            if (b === this.authService.email) return 1;
            return a > b ? 1 : -1;
          }))
      );
  }

  canCreate(): boolean {
    return false;
  }
  canDelete(): boolean {
    return this.authService.isAdmin();
  }

  expandRow(id) {
    this.expandedRows[id] = !this.expandedRows[id];
  }

  pull(force: boolean = false): void {
    if (force) {
      this.itemService.reset();
    }
    this.isUpdating = true;

    this.itemService
      .getAll()
      .toPromise()
      .then((items: any) => {
        this.items = items.sort(
          (l, r) => l.Id.localeCompare(r.Id) || l.Title.localeCompare(r.Title)
        );
        this.isUpdating = false;
        this.attachInvoices();
      });
  }
  attachInvoices() {
    for (let project of this.items) {
      const [type, id] = project.Owner.split(':');
      project.Milestones = [];
      project.PurchasedHours = [];
      project['_owner'] = { type, id }; // used by router in template
      const service =
        type === 'opportunity'
          ? this.opportunityService
          : this.agreementService;
      const byProject = ({ ProjectId }) => ProjectId === project.Id;
      const req = (<any>service).getByUniqueKey(id).toPromise();
      req.then((item?: any) => {
        if (!item) return;
        project.Milestones = (item.Milestones || []).filter(byProject);
        project.PurchasedHours = (item.PurchasedHours || []).filter(byProject);
        const invoices = project.Milestones.concat(project.PurchasedHours);

        project['_invoices'] = invoices;

        project['_toBeInvoiced'] = invoices.reduce(
          (total, { Value = 0, Status, Currency, Rate }) =>
            +Status === 20
              ? total
              : total + (Currency === 'AUD' ? Value : Value / Rate),
          0
        );

        project['_overdueInvoices'] = invoices.reduce(
          (total, { Value = 0, Status, Currency, Rate, DueDate }) =>
            +Status === 20 || moment(DueDate).isAfter()
              ? total
              : total + (Currency === 'AUD' ? Value : Value / Rate),
          0
        );
        project['_productOwner'] = item.ProductOwner;
        project['_notInvoiced'] = invoices.some((i) => Number(i.Status) !== 20);

        this.items = this.items.slice();
      });
    }
  }

  afterSave(): void {
    zip(
      this.opportunityService.loadAll(),
      this.agreementService.loadAll()
    ).subscribe(() => this.attachInvoices());
    this.pull(true);
    super.afterSave();
  }

  edit(project: Project) {
    this.prepareForm(project);
    super.edit(project);
  }

  remove(id: string) {
    this.itemService.delete(id).subscribe(() => {
      this.pull(true);
      this.broadcastService.next([this.eventType, 'close']);
    });
  }

  sortTable(field: string) {}
  activateMilestone({ milestone, ownerType, ownerId }) {
    this.activatedMilestone = {
      milestone,
      ownerType,
      ownerId,
      approveChange: false,
      status: milestone.Status,
      notes: milestone.Notes,
    };
  }

  _formatDate(date?: string): string | undefined {
    return date && moment(date).format('MMM DD, YYYY');
  }
  exportProjects(format: string) {
    const data = {
      headers: [
        'Title',
        'Description',
        'Start Date',
        'End Date',
        'To Be Invoiced',
        'Product Owner',
      ],
      rows: this.items
        .filter((item) => item['_notInvoiced'])
        .map((item) => [
          item.Title,
          item.Description,
          this._formatDate(item.StartDate),
          this._formatDate(item.EndDate),
          (item['_toBeInvoiced'] || 0).toLocaleString(),
          item['_productOwner'],
        ]),
    };
    this.exporterService.asCsv(data, 'projects');
  }
}
