import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from './auth.service';
import { LambdaService } from './lambda.service';

const reservationRecipient = 'joel.wigley@linkdigital.com.au';
const expiredRecipient = 'joel.wigley@linkdigital.com.au';
const pendingRecipient = 'accounts@linkdigital.com.au';

@Injectable()
export class MailService {
  protected readyToInvoiceMsg = '---';

  constructor(
    private lambda: LambdaService,
    protected auth: AuthService,
    protected http: HttpClient
  ) {}
  getReadyToInvoiceMsg(): string {
    return this.readyToInvoiceMsg;
  }

  sendReservation(notes, agreement, title) {
    const recipient: string =
      window['LCM_RESERVATION_MAIL'] || reservationRecipient;
    let message = `Hi Joel,

There has been a change made to the following agreement which incicates the reservations need to be renewed

URL: https://contracts.system.links.com.au/#/app/agreements/view/${agreement}
Title: ${title}
Notes: ${notes}
Assigned by: ${this.auth.name}
`;

    return this.sendMail(message, 'LCM: Reservation', recipient);
  }

  sendExpiredAgreement(project) {
    const recipient: string =
      window['LCM_EXPIRED_AGREEMENT_MAIL'] || expiredRecipient;
    let message = `Hi Joel,

The below agreement has been marked as expired.

URL: ${window.location.origin}/#/app/agreements/view/${project.AgreementNumber}
Project: ${project.Title}
Job Number: ${project.JobNumber}
Client Code: ${project.ClientCode}
Changed by: ${this.auth.name}`;

    return this.sendMail(message, 'LCM: Expired agreement', recipient);
  }
  sendPendingInvoice(notes, project, type_, owner) {
    const recipient: string =
      window['LCM_PENDING_INVOICE_MAIL'] || pendingRecipient;
    let message = `Hi Angela,

You've been assigned a new invoice to process.

URL: ${window.location.origin}/#/app/${
      type_ === 'opportunity' ? 'products' : 'agreements'
    }/view/${owner}
Project: ${project ? project.Title : 'Main project'}
Notes: ${notes}
Assigned by: ${this.auth.name}`;

    return this.sendMail(message, 'LCM: Pending invoice', recipient);
  }
  sendMail(message: string, subject: string, receiver: string) {
    const data = {
      message,
      subject,
      receiver,
    };
    return this.http
      .post(this.lambda.getMailUrl(), data, {
        headers: new HttpHeaders({ authorization: this.auth.authToken }),
      })
      .subscribe(() => {});
  }
}
