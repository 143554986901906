import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuthService } from '../../service/auth.service';

@Component({
  standalone: true,
  imports: [SocialLoginModule, CommonModule],
  template: `
    <section *ngIf="!this.auth.isLoggedIn; else spinner">
      <asl-google-signin-button
        type="standard"
        shape="pill"
        size="large"
      ></asl-google-signin-button>
    </section>
    <ng-template #spinner>
      <section class="spin-container">
        <div class="spinner spin-1"></div>
        <div class="spinner spin-2"></div>
        <div class="spinner spin-3"></div>
      </section>
    </ng-template>
  `,
  styles: [
    ':host {display:flex; align-items: center; justify-content: space-around; height: 100vh;}',
  ],
})
export class LoginComponent {
  constructor(public auth: AuthService) {}
}
