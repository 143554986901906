import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MilestoneFormComponent,
  MilestonesComponent,
  MilestoneModalsComponent,
} from ".";
import { CommonModule } from "@angular/common";
import { DirectiveModule } from "../../directive/directive.module";
import { ComponentModule } from "../../components/component.module";


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DirectiveModule,
    FormsModule,
    ComponentModule,
  ],

  declarations: [
    MilestoneFormComponent,
    MilestonesComponent,
    MilestoneModalsComponent,
  ],
  exports: [
    MilestoneFormComponent,
    MilestonesComponent,
    MilestoneModalsComponent,
  ],
})
export class MilestoneModule { }
