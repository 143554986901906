import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AuthService } from '../../service';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';

@Component({
  standalone: true,
  imports: [
    SidebarComponent,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    MatSidenavModule,
    MatButtonModule,
    CommonModule,
  ],
  templateUrl: './layout.template.html',
  styles: [
    '.link-logo-highlight { font-weight: bold; color: #17cbe2; }',
    '.mat-drawer-container {background: transparent;}',
  ],
})
export class LayoutComponent {
  showSidebar = true;

  constructor(public auth: AuthService) {}

  logout() {
    this.auth.logoutWithGoogle();
  }
}
