import { Component, OnInit } from '@angular/core';

import { BroadcastService } from '../service/broadcast.service';
import { AuthService } from '../service/auth.service';
import { FetchAbstractService } from '../service/fetch-abstract.service';

import { Observable } from 'rxjs';
import { filter, tap, map } from 'rxjs/operators';

import { PageEvent } from '@angular/material/paginator';

@Component({ template: '' })
export abstract class AbstractEntityComponent<IT, T> implements OnInit {
  protected items: IT[] = [];
  protected pageLimit: number = 20;
  protected currentPage: number = 0;
  protected searchQ: string = '';
  protected owner: [string, boolean] = ['any', false];
  protected sortOrder: string = 'Asc';
  protected formHeading!: string;
  protected formModel!: T;
  protected clientQ: string = '';
  protected singleUser: string = 'any';

  public isUpdating: boolean = false;
  protected triggerBroadcaster!: Observable<string>;

  protected authService!: AuthService;
  protected itemService!: FetchAbstractService<IT>;
  protected broadcastService!: BroadcastService;

  protected abstract eventType: string;

  get currentOffset() {
    return this.currentPage * this.pageLimit;
  }

  canCreate(): boolean {
    return this.authService.isAdmin();
  }

  canEdit(): boolean {
    return this.authService.isAdmin();
  }

  pull(force: boolean = false): void {
    if (force && !this.searchQ) {
      this.itemService.reset();
    }
    this.isUpdating = true;
    this.itemService
      .getQty(
        this.pageLimit,
        this.currentOffset,
        this.searchQ,
        false,
        this.owner
      )
      .subscribe((items: IT[]) => {
        this.items = items;
        this.isUpdating = false;
      });
  }

  searchClient(
    client: string,
    owner: [string, boolean] = ['any', false]
  ): void {
    this.removeSort();
    this.searchQ = client;
    this.owner = owner;
    this.currentPage = 0;
    this.itemService
      .getQty(
        this.pageLimit,
        this.currentOffset,
        this.searchQ,
        false,
        this.owner
      )
      .subscribe((items: IT[]) => {
        this.items = items;
      });
  }

  sortItems(column: string, event: Event): void {
    this.removeSort();
    let target: DOMTokenList = (<HTMLElement>event.target).classList;

    if (this.sortOrder === 'Asc') {
      target.add('ascending');
    } else {
      target.add('descending');
    }

    this.itemService
      .sortItems(column, this.sortOrder, this.pageLimit, this.currentOffset)
      .pipe(
        tap((items: IT[]) => {
          this.sortOrder = this.sortOrder === 'Asc' ? 'Desc' : 'Asc';
        })
      )
      .subscribe((items: IT[]) => (this.items = items));
  }

  removeSort(): void {
    let th_s: any = document.querySelectorAll('th.sortable a');

    for (let th of th_s) {
      th.classList.remove('descending');
      th.classList.remove('ascending');
    }
  }

  ngOnInit(): void {
    this.pull();

    this.triggerBroadcaster = this.broadcastService.getObservable().pipe(
      filter(([type]: string[]) => type === this.eventType),
      map(([, event]: string[]) => event)
    );

    this.turnPage();
  }

  onPageChange(page: PageEvent) {
    this.pageLimit = page.pageSize;
    this.currentPage = page.pageIndex;

    this.turnPage();
  }
  turnPage() {
    this.itemService
      .getQty(
        this.pageLimit,
        this.currentOffset,
        this.searchQ,
        true,
        this.owner
      )
      .subscribe((items: IT[]) => {
        this.items = items;
      });
  }

  reload(): void {
    this.pull(true);
  }

  prepareForm(item?: IT): void {
    this.broadcastService.next([this.eventType, 'prepare']);
  }

  afterSave(): void {
    this.broadcastService.next([this.eventType, 'save']);
    this.broadcastService.next([this.eventType, 'close']);
  }

  edit(item: IT) {
    this.broadcastService.next([this.eventType, 'edit']);
  }

  userChanged(val: string): void {
    this.singleUser = val;
    this.clientQ = '';
    this.searchClient(this.clientQ, [val, true]);
  }
}
