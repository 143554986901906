<section class="widget" (widgetReload)="getHistory(true, 10)">
    <header>
        <h4>Feed</h4>
        <div class="widget-controls">
            <a [class.disabled]="isUpdating"
               class="btn btn-xs btn-transparent"
               data-widgster="refresh" title="Refresh" ><i class="glyphicon glyphicon-refresh"></i></a>
            <a [class.disabled]="isUpdating"  class="btn btn-xs btn-transparent" (click)="getHistory(true)">
                <i class="fa fa-download"></i>
                Show All
            </a>
        </div>

    </header>
    <div class="body">

        <span *ngIf="records === undefined" class="loader animated fadeIn handle ui-sortable-handle">
            <span class="spinner">
                <i class="fa fa-spinner fa-spin"></i>
            </span>
        </span>

        <div class="feed">
            <h3 class="text-center"  *ngIf="records?.length === 0">
                There are no any activities here yet.
            </h3>

            <section class="feed-item" *ngFor="let record of records">
                <div class="icon pull-left">
                    <i class="{{ record.Data.icon }}"></i>
                </div>
                <div class="feed-item-body">
                    <div class="text">
                        <em>{{ record.Data.author }}</em>
                        {{ record.Data.humanReadable }}

                    </div>
                    <div class="time pull-left">
                        {{ record.Data.relativeTime }}
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>
