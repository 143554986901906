<form [formGroup]="form" class="form-horizontal form-label-left" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label class="control-label col-sm-4">Name</label>
        <div class="col-sm-6">
            <input
                formControlName="Name"
                [class.parsley-error]="form.controls['Name'].invalid && form.controls['Name'].dirty"
                required class="form-control ">
            <ul class="parsley-error-list"
                [hidden]="form.controls['Name'].valid || form.controls['Name'].pristine"
            >
                <li>This field is required</li>
            </ul>
        </div>
    </div>


    <div class="form-group">
        <label class="control-label col-sm-4" for="milestone-due-date">Due Date</label>
        <div class="col-sm-6">
            <input type="text" id="milestone-due-date" datetimepicker data-format="Y-MM-DD"
                   formControlName="DueDate"
                   [class.parsley-error]="form.controls['DueDate'].invalid && form.controls['DueDate'].dirty"
                   (valueChanged)="form.controls['DueDate'].setValue($event)"
                   required class="form-control ">
            <ul class="parsley-error-list"
                [hidden]="form.controls['DueDate'].valid || form.controls['DueDate'].pristine">
                <li>This field is required</li>
            </ul>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-sm-4" for="milestone-status">Status</label>
        <div class="col-sm-6">
            <select type="text" id="milestone-status"
                    formControlName="Status"
                    required class="form-control ">
                <option *ngFor="let item of milestoneStatuses" [ngValue]="item[0]">{{ item[1] }}</option>
            </select>

            <ul class="parsley-error-list"
                [hidden]="form.controls['Status'].valid || form.controls['Status'].pristine">
                <li>This field is required</li>
            </ul>
        </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-4">Hourly Rate (in selected currency)</label>
      <div class="col-sm-6">
        <input formControlName="HourlyRate" type="number" class="form-control" >
        <ul class="parsley-error-list"
            [hidden]="form.controls['HourlyRate'].valid || !form.controls['HourlyRate'].value">
          <li [hidden]="!form.controls['HourlyRate'].hasError('required')">This field is required</li>
          <li [hidden]="!form.controls['HourlyRate'].hasError('positive')">Value must be greater than 0</li>
        </ul>
      </div>
    </div>

    <div class="form-group">
        <label class="control-label col-sm-4">Value (in selected currency)</label>
        <div class="col-sm-6">
            <input type="number"
                   formControlName="Value"
                   [class.parsley-error]="form.controls['Value'].invalid && form.controls['Value'].dirty"
                   required class="form-control ">
            <ul class="parsley-error-list"
                [hidden]="form.controls['Value'].valid || form.controls['Value'].pristine"
            >
                <li>This field is required</li>
            </ul>
        </div>
    </div>

    <div class="form-group">
        <label class="control-label col-sm-4" for="milestone-currency">Currency</label>
        <div class="col-sm-6">
            <select type="text" id="milestone-currency"
                    formControlName="Currency"
                    required class="form-control ">
                <option *ngFor="let item of currencies" [ngValue]="item">{{ item }}</option>
            </select>

            <ul class="parsley-error-list"
                [hidden]="form.controls['Currency'].valid || form.controls['Currency'].pristine">
                <li>This field is required</li>
            </ul>
        </div>
    </div>
    <div class="form-group" [hidden]="form.value.Currency === 'AUD'">
        <label class="control-label col-sm-4">
            Exchange Rate
            <br/>
            <sub>
                (1 AUD equals ... {{ form.value.Currency }})
            </sub>
        </label>
        <div class="col-sm-6">
            <input type="number"
                   formControlName="Rate"
                   [class.parsley-error]="form.controls['Rate'].invalid && form.controls['Rate'].dirty"
                   required class="form-control ">
            <ul class="parsley-error-list"
                [hidden]="form.controls['Rate'].valid || form.controls['Rate'].pristine"
            >
                <li>This field is required</li>
            </ul>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-sm-4">
            Notes
        </label>
        <div class="col-sm-6">
            <textarea type="number"
                   formControlName="Notes"
                   [class.parsley-error]="form.controls['Notes'].invalid && form.controls['Notes'].dirty"
                      class="form-control ">
            </textarea>
            <ul class="parsley-error-list"
                [hidden]="form.controls['Notes'].valid || form.controls['Notes'].pristine"
            >
                <li>This field is required</li>
            </ul>
        </div>
    </div>

   <div class="form-group">
        <label class="control-label col-sm-4" for="milestone-project-id">Project</label>
        <div class="col-sm-6">
            <select type="text" id="milestone-project-id"
                    formControlName="ProjectId"
                    class="form-control ">
              <option *ngFor="let item of projects" [ngValue]="item.Id">{{ item.Title }}</option>
            </select>
            <ul class="parsley-error-list"
              [hidden]="form.controls['ProjectId'].valid || form.controls['ProjectId'].pristine"
            >
              <li>This field is required</li>
            </ul>

        </div>
   </div>
   <div class="form-group">
     <label class="control-label col-sm-4">Purchase Order</label>
     <div class="col-sm-6">
       <input type="number"
         formControlName="PurchaseOrder"
         [class.parsley-error]="form.controls['PurchaseOrder'].invalid && form.controls['PurchaseOrder'].dirty"
         class="form-control ">
       <ul class="parsley-error-list"
         [hidden]="form.controls['PurchaseOrder'].valid || form.controls['PurchaseOrder'].pristine"
       >
         <li>This field is required</li>
       </ul>
     </div>
   </div>

   <div class="form-group">
        <div class="col-sm-8 col-sm-offset-4">
            <button class="btn btn-primary" type="submit" [disabled]="form.invalid || formPending">
                Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
            </button>
        </div>
    </div>
</form>
