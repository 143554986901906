import { Component, OnInit } from '@angular/core';

import { map, tap } from 'rxjs/operators';
import { AgreementService, OpportunityService } from '../../service';
import { IOpportunity, IAgreement } from '../../prototype';

@Component({
  templateUrl: 'commission.template.html',
})
export class CommissionComponent implements OnInit {
  opportunities: IOpportunity[] = [];
  agreements: IAgreement[] = [];
  expanded: object = {};
  invoices: object = {};

  constructor(
    private opportunity: OpportunityService,
    private agreement: AgreementService
  ) {}

  ngOnInit() {
    this.opportunity
      .loadAll()
      .pipe(
        map((opportunities: IOpportunity[]) =>
          opportunities
            .filter((op) =>
              ((op.PurchasedHours as any[]) || []).some(
                ({ Status, Commissionable }) => Status === 20 && Commissionable
              )
            )
            .map((op) => {
              const hours = op.PurchasedHours.filter(
                ({ Status, Commissionable }) => Status === 20 && Commissionable
              );
              hours.forEach(
                (record) =>
                  (record.InvoiceAmount =
                    record.Hours * (record.HourlyRate || 0))
              );
              this.invoices[op.JobNumber] = hours.sort(
                ({ InvoiceDate: a }, { InvoiceDate: b }) =>
                  (new Date(b) as any) - (new Date(a) as any)
              );
              return op;
            })
        )
      )
      .subscribe(
        (opportunities: IOpportunity[]) => (this.opportunities = opportunities)
      );
    this.agreement
      .loadAll()
      .pipe(
        map((agreements: IAgreement[]) =>
          agreements
            .filter((ag) =>
              // (ag.Invoices =
              //   (typeof ag.Invoices !== 'string' && ag.Invoices) ||
              //   JSON.parse((ag.Invoices as string) || '[]')).length > 0 ||
              ((ag.PurchasedHours as any[]) || []).some(
                ({ Status, Commissionable }) => Status === 20 && Commissionable
              )
            )
            .map((ag) => {
              const hours = (ag.PurchasedHours || []).filter(
                ({ Status, Commissionable }) => Status === 20 && Commissionable
              );
              hours.forEach(
                (record) =>
                  (record.InvoiceAmount =
                    record.Hours * (record.HourlyRate || 0))
              );

              const invoices = ag.Invoices || [];
              this.invoices[ag.AgreementNumber] = hours
                // .concat(invoices)
                .sort(
                  ({ InvoiceDate: a }, { InvoiceDate: b }) =>
                    (new Date(b) as any) - (new Date(a) as any)
                );
              return ag;
            })
        )
      )
      .subscribe((agreements: IAgreement[]) => (this.agreements = agreements));
  }
}
