import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import {
  JiraService,
  BroadcastService,
  AuthService,
  AgreementService,
  ClientService,
} from '../service';

import { Agreement } from '../prototype/agreement.prototype';

import { AbstractDetailComponent } from '../prototype/entity-detail-component';

import * as moment from "moment";
import { Client } from '../components/client-form/client-account';

@Component({
  templateUrl: 'agreement-detail.template.html',
})
export class AgreementDetailComponent extends AbstractDetailComponent<Agreement> {
  protected eventType: string = 'agreement-details';
  protected model?: Agreement;
  protected jiraLink?: string;
  protected isHoursRemoving: boolean = false;
  protected isAddonRemoving: boolean = false;
  protected clientName?: string;
  protected client: Client | null = null;
  entity!: Agreement;

  completedSp = 0;
  projectValue = 0;
  addonIndex: number = 0;
  removeHoursOptions: any;
  removeHoursType: any;
  constructor(
    protected authService: AuthService,
    protected itemService: AgreementService,
    protected route: ActivatedRoute,
    protected location: Location,
    protected broadcastService: BroadcastService,
    protected jira: JiraService,
    protected clientService: ClientService
  ) {
    super();
  }

  transformDate(date: string): string {
    return moment(date).format('DD/MM/Y');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.entity = this.entity!;
    this.jira
      .getProjectSpCompleted(this.entity['JiraTitle'])
      .subscribe(({ Done }) => (this.completedSp = Done), console.warn);
    this.projectValue =
      (this.entity['Invoices'] || []).reduce(
        (total, invoice) => total + invoice.InvoiceAmount,
        0
      ) +
      (this.entity['PurchasedHours'] || []).reduce(
        (total, item) =>
          item.Status === 20 ? total + item.Hours * item.HourlyRate : total,
        0
      );

    if (this.route.snapshot.params['edit-form']) {
      this.model = this.entity;
      setTimeout(
        () =>
          this.broadcastService.next([
            this.eventType,
            'show:agreement-form-modal',
          ]),
        2000
      );
    }
    this.clientService
      .getByUniqueKey(this.entity.ClientCode)
      .subscribe((client) => {
        this.clientName = client?.ClientName || "";
        this.client = client
      });
  }

  viewDocument(id: string): void {
    this.itemService.downloadDocument(id);
  }

  getJiraLink(): string {
    return this.jira.jiraLink + this.entity!.JiraTitle;
  }

  afterSave(renewReservation: boolean = false): void {
    super.afterSave();
    this.reloadEntity();
    if (renewReservation) {
      this.broadcastService.next([
        'reservation-popup',
        'show:reservation-agreement-popup',
      ]);
    }
  }

  private reloadEntity(): void {
    this.itemService.reset();
    this.itemService
      .getByUniqueKey(this.entity!.AgreementNumber)
      .subscribe((entity: any) => {
        this.entity = entity;
        this.isHoursRemoving = false;
        this.isAddonRemoving = false;
      });
  }

  removeAddon(index: number): void {
    this.isAddonRemoving = true;
    let [removed] = this.entity!.Addons.splice(index, 1);
    let id = removed.Id || index;

    this.itemService
      .removeAddon(this.entity!.AgreementNumber, id)
      .subscribe(() => this.afterSave());
  }
  removeHours(options: any[]) {
    const id = this.entity!.AgreementNumber;
    this.isHoursRemoving = true;
    console.debug('Removing hours of %s with options %o', id, options);
    const [idx, type, items] = options;
    const target = items[idx];
    this.itemService.removeHours(id, type, target.Id).subscribe(() => {
      this.afterSave();
    });
  }

  checkExpired(): boolean {
    const result = Math.ceil(
      (new Date(this.entity!.EndDate).getTime() - new Date().getTime()) /
        1000 /
        3600 /
        24
    );

    return result < 0 ? true : false;
  }
}
