import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";

import { MessengerService } from "./messenger.service";
import { LambdaService } from "./lambda.service";
import { FetchAbstractService } from "./fetch-abstract.service";
import { AuthService } from "./auth.service";

import { IResource } from "../prototype";

@Injectable()
export class ResourcesService extends FetchAbstractService<IResource> {
  protected uniqueKeyName: string = "Id";

  constructor(
    protected http: HttpClient,
    protected lambda: LambdaService,
    protected auth: AuthService,
    protected messenger: MessengerService
  ) {
    super();
    this.defaultOrder = "Asc";
    this.defaultOrderColumn = "Title";
    this.filterBy = ["Title"];
  }

  getLoadAllUrl(): string {
    return this.lambda.getResourcesUrl();
  }

  getSaveUrl(forEdit: boolean = false): string {
    return this.lambda.getResourcesUrl();
  }

  getEditUrl(resource: IResource): string {
    return this.getSaveUrl() + "/" + resource.Id;
  }

  getLoadAllErrorMsg(): string {
    return "List of resources is unavailable";
  }

  delete(id: string): Observable<any> {
    let request: Observable<any> = this.http.delete(
      this.getSaveUrl() + "/" + id,
      this.getDefaultHeaders()
    );

    return request;
  }
}
