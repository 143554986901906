<h1 class="page-title">
  Client Account
  <button type="button" class="btn btn-transparent btn-xs" (click)="back()">
    <i class="fa fa-reply"></i>
    Back
  </button>
</h1>

<div class="col-lg-8 client-account-details">
  <section class="widget" *ngIf="entity">
    <header>
      <h4>
        {{ entity.ClientName }}
        <button
          *ngIf="authService.isAdminOrEditor()"
          type="button"
          class="btn btn-transparent btn-xs edit-button"
          data-toggle="modal"
          data-target="#client-form-modal"
        >
          <i class="fa fa-pencil"></i>
          Edit
        </button>
      </h4>
    </header>
    <div class="body">
      <div class="mt">
        <table class="table table-striped table-hover">
          <tbody>
            <tr>
              <td style="width: 20%">Client Code</td>
              <td>{{ entity.ClientCode }}</td>
            </tr>
            <tr>
              <td>Client Name</td>
              <td>{{ entity.ClientName }}</td>
            </tr>
            <tr>
              <td>Xero ID</td>
              <td>
                <a
                  href="https://go.xero.com/Contacts/View.aspx?contactID={{ entity.XeroId }}"
                  target="_blank"
                  title="{{ entity.XeroId }}"
                >
                  {{ entity.XeroId }}
                </a>
              </td>
            </tr>
            <tr>
              <td>Insightly ID</td>
              <td>
                <a
                  href="https://googleapps.insight.ly/details/Organisations/Details/{{ entity.InsightlyId }}"
                  target="_blank"
                  title="{{ entity.InsightlyId }}"
                >
                  {{ entity.InsightlyId }}
                </a>
              </td>
            </tr>
            <tr>
              <td>AWS ID</td>
              <td>{{ entity.AwsId }}</td>
            </tr>
            <tr>
              <td>Commissionable</td>

              <td>{{ entity.Commissionable ? 'Yes' : 'No' }}</td>
            </tr>

            <tr>
              <td>Notes</td>
              <td>{{ entity.Notes }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>
<div class="col-lg-4">
  <activity-stream
    [id]="'client' + entity?.ClientCode"
    [createMessage]="'created client account'"
  ></activity-stream>
</div>

<div class="col-xs-12">
  <resource-requests
    [linkedProject]="entity?.ClientCode"
    projectType="client"
  ></resource-requests>
</div>

<modal-dialog
  *ngIf="entity"
  [modalId]="'client-form-modal'"
  heading="Edit {{ entity.ClientName}}"
  [broadcaster]="triggerBroadcaster"
>
  <client-account-form
    [broadcaster]="triggerBroadcaster"
    [model]="entity"
    (formSaved)="afterSave()"
  ></client-account-form>
</modal-dialog>
