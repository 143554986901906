export interface IOpportunity {
  ClientCode: string;
  JobNumber: string;
  Title: string;
  ProductOwner: string;
  JiraTitle: string;
  Status: string;
  PurchasedHours: any;
  UnchargeableHours: any;
  UnforeseenHours: any;
  ProfitableHours: any;
  StartDate: string; // required for compatibility with time tables
  EndDate: string; // --//--
  SupportHours: number; // --//--
  BudgetNote?: string;
  Milestones?: any[];
  Commissionable?: boolean;
}

export class Opportunity {
  constructor(
    public ClientCode: string = "",
    public Title: string = "",
    public ProductOwner: string = "",
    public JobNumber?: string,
    public JiraTitle?: string,
    public Status: string = "",
    public PurchasedHours: any = [],
    public UnchargeableHours: any = [],
    public ProfitableHours: any = [],
    public Notes?: string,
    public BudgetNote?: string,
    public Commissionable?: boolean,
    public Milestones?: any[],
    public UnforeseenHours?: any[],

  ) { }

  get StartDate(): string {
    let date: Date = new Date();
    date.setMonth(date.getMonth() - 6);
    return String(date);
  }

  get EndDate(): string {
    let date: Date = new Date();
    date.setMonth(date.getMonth() + 6);
    return String(date);
  }

  get SupportHours(): number {
    return 0;
  }
}

export const StatusOptions = ["Prospective", "Active", "Inactive", "On Hold"];
