<form
  [formGroup]="form"
  class="form-horizontal form-label-left"
  (ngSubmit)="onSubmit()"
>
  <div class="form-group">
    <label class="control-label col-sm-4" for="opportunity-client-code"
      >Client Code</label
    >
    <div class="col-sm-6">
      <select
        formControlName="ClientCode"
        (changeValue)="clientCodeChanged($event)"
        [value]="form.value.ClientCode"
        type="text"
        id="opportunity-client-code"
        class="form-control select2"
      >
        <option value="" [selected]="!form.value.ClientCode"></option>
        <option
          *ngFor="let code of clientCodes"
          [selected]="code === form.value.ClientCode"
          [value]="code"
        >
          {{ code }}
        </option>
      </select>
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['ClientCode'].valid || form.controls['ClientCode'].pristine"
      >
        <li [hidden]="!form.controls['ClientCode'].hasError('required')">
          This field is required
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="opportunity-title">Title</label>
    <div class="col-sm-6">
      <input
        type="text"
        id="opportunity-title"
        formControlName="Title"
        [class.parsley-error]="form.controls['Title'].invalid && form.controls['Title'].dirty"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Title'].valid || form.controls['Title'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="client-code"
      >Product Owner</label
    >
    <div class="col-sm-6">
      <select
        formControlName="ProductOwner"
        id="product-owner"
        (changeValue)="productOwnerChanged($event)"
        [value]="form.value.ProductOwner"
        type="text"
        class="form-control select2 pull-left select-with-remove"
      >
        <option value="" [selected]="!form.value.ProductOwner"></option>
        <option
          *ngFor="let user of usersList"
          [selected]="user === form.value.ProductOwner"
          [value]="user"
        >
          {{ user }}
        </option>
      </select>
      <div class="pull-left">
        <button
          class="btn btn-primary"
          type="button"
          (click)="cleanSelect('#product-owner', 'ProductOwner')"
        >
          <i class="glyphicon glyphicon-remove"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="code">Status</label>
    <div class="col-sm-6">
      <select
        type="text"
        id="status-list"
        formControlName="Status"
        required
        class="form-control"
      >
        <option *ngFor="let opt of StatusOptions" [value]="opt">
          {{ opt }}
        </option>
      </select>
      <ul
        class="parsley-error-list"
        [hidden]="!form.controls['Status'].hasError('required')"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="commissionable"
      >Commissionable</label
    >
    <div class="col-sm-6">
      <select
        type="text"
        id="commissionable"
        formControlName="Commissionable"
        required
        class="form-control"
      >
        <option [ngValue]="false" [selected]="!form.value.Commissionable">
          No
        </option>
        <option [ngValue]="true" [selected]="form.value.Commissionable">
          Yes
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="opportunity-notes">Notes</label>
    <div class="col-sm-6">
      <textarea
        rows="3"
        class="autogrow form-control transition-height"
        formControlName="Notes"
        id="opportunity-notes"
        placeholder="Try to add few new lines.."
      ></textarea>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4" for="opportunity-budget-note"
      >Hours/budget note</label
    >
    <div class="col-sm-6">
      <textarea
        rows="3"
        class="autogrow form-control transition-height"
        formControlName="BudgetNote"
        id="opportunity-budget-note"
        placeholder="Try to add few new lines.."
      ></textarea>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-8 col-sm-offset-4">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="!form.controls['ClientCode'] || form.invalid || formPending"
      >
        Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</form>
