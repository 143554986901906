<h1 class="page-title">Products</h1>

<section class="widget" (widgetReload)="reload()">
  <header>
    <h4>
      Products
      <span *ngIf="clientCode">
        (<span class="fw-semi-bold">{{ clientName }}</span>)
      </span>
      <button
        *ngIf="canCreate()"
        type="button"
        class="btn btn-transparent btn-xs"
        data-toggle="modal"
        data-target="#opportunity-form-modal"
        (click)="prepareForm()"
      >
        <i class="fa fa-plus"></i>
        Add Product
      </button>
    </h4>
    <div class="widget-controls sppiner-vert-search">
      <a
        *ngIf="isUpdating && items?.length"
        class="animated fadeIn handle ui-sortable-handle"
      >
        <i class="fa fa-spinner fa-spin"></i>
      </a>
      <a data-widgster="refresh" title="Refresh"
        ><i class="glyphicon glyphicon-refresh"></i
      ></a>
    </div>
    <div class="widget-controls filter-control">
    </div>
  </header>

  <div class="widget-filter">

    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select multiple (selectionChange)="statusChanged($event.value)" [value]="selectedStatuses">
        <ng-container *ngFor="let status of statusOptions">
          <mat-option [value]="status">{{ status || "Unknown" }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Product Owner</mat-label>
      <mat-select (selectionChange)="userChanged($event.value)" panelWidth="null" value="">
        <mat-option value="any">
          <strong>Any</strong>
        </mat-option>
          <mat-option *ngFor="let user of usersList" [value]="user.FullName">
            <strong *ngIf="authService.email === user.Email">
              Me:
            </strong>
            {{ user.FullName }}
          </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-form-field">
      <mat-label>Client Code or Name</mat-label>
      <input matInput type="text" [(ngModel)]="clientQ"
             (input)="searchClient(clientQ, [singleUser, false])">
      <button [hidden]="!clientQ" matSuffix mat-mini-fab aria-label="Clear" (click)="clientQ=''; searchClient(clientQ, [singleUser, false])">
        <mat-icon>close</mat-icon>
      </button>

    </mat-form-field>

  </div>


  <div class="body">
    <div
      *ngIf="items?.length === null"
      class="loader animated fadeIn handle ui-sortable-handle"
    >
      <span class="spinner">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
    </div>
    <div *ngIf="items?.length === 0">
      <h3 class="text-center">There are no any opportunities here yet.</h3>
    </div>

    <div class="mt">
      <table
        *ngIf="items?.length"
        id="datatable-table"
        class="table table-striped table-hover table-editable product-table"
      >
        <thead>
          <tr>
            <th class="editable sortable renderable">
              <a (click)="sortItems('ClientCode', $event)">
                Client Code<b class="sort-caret"></b>
              </a>
            </th>
            <th class="editable renderable hidden-sm">Client Name</th>
            <th class="editable sortable renderable">
              <a (click)="sortItems('JobNumber', $event)">
                Prod#<b class="sort-caret"></b>
              </a>
            </th>
            <th class="editable sortable renderable">
              <a (click)="sortItems('Title', $event)">
                Name<b class="sort-caret"></b>
              </a>
            </th>

            <th class="editable sortable renderable">
              <a (click)="sortItems('Status', $event)">
                Status<b class="sort-caret"></b>
              </a>
            </th>
            <th class="">Jira</th>
            <th class=""></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let opportunity of items">
            <td>{{ opportunity.ClientCode }}</td>
            <td class="hidden-sm">
              {{ clientCodeToNameMapping[opportunity.ClientCode] }}
            </td>
            <td class="job-number">
              <span class="fw-semi-bold">{{ opportunity.JobNumber }}</span>
            </td>
            <td>{{ opportunity.Title }}</td>

            <td>{{ opportunity.Status }}</td>
            <td>
              <a href="{{ getJiraLink(opportunity) }}" target="_blank"
                >{{ opportunity.JiraTitle }}</a
              >
            </td>
            <td>
              <a
                class="btn btn-primary btn-sm"
                [routerLink]="['/app/agreements', opportunity.ClientCode, opportunity.JobNumber]"
                >Agreements</a
              >
              <button
                *ngIf="canEdit()"
                title="Edit"
                class="btn btn-success btn-sm"
                (click)="edit(opportunity)"
                data-toggle="modal"
                data-target="#opportunity-form-modal"
              >
                <span class="glyphicon glyphicon-pencil"></span>
              </button>

              <a
                title="View"
                class="btn btn-success btn-sm"
                [routerLink]="['/app/products/view', opportunity.JobNumber]"
              >
                <span class="glyphicon glyphicon-eye-open"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-paginator
        [length]="itemService.totalAmount"
        [pageSize]="pageLimit"
        [pageSizeOptions]="[10, 20, 50, 100]"
        [showFirstLastButtons]="true"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </div>
  </div>
</section>

<modal-dialog
  [modalId]="'opportunity-form-modal'"
  [heading]="formHeading"
  [broadcaster]="triggerBroadcaster"
>
  <opportunity-form
    [broadcaster]="triggerBroadcaster"
    [model]="formModel"
    (formSaved)="afterSave()"
  ></opportunity-form>
</modal-dialog>
