import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { IAgreement } from '../../prototype/agreement.prototype';
import { Observable, defer, merge, concat } from 'rxjs';
import { skip, bufferCount, map } from 'rxjs/operators';
import {
  OpportunityService,
  AgreementService,
  ClientService,
  InvoiceService,
} from '../../service';

@Injectable()
export class DashboardResolver implements Resolve<any> {
  constructor(
    private agreements: AgreementService,
    private opportunities: OpportunityService,
    private invoices: InvoiceService,
    private clients: ClientService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let invoices = defer(() => this.agreements.getDueInvoices());
    let addons = defer(() => this.agreements.getSeparateAddons());
    let expired = defer(() => this.agreements.getExpired());
    const milestones = defer(() => this.invoices.getInvoicableMilestones());
    const hours = defer(() => this.invoices.getInvoicablePurchasedHours());

    return concat(
      merge(this.clients.getAll(), this.agreements.getAll()).pipe(skip(2)),
      invoices,
      expired,
      addons,
      milestones,
      hours
    ).pipe(
      bufferCount(5),
      map(([invoices, expired, addons, milestones, purchasedHours]) => ({
        expired,
        invoices,
        addons,
        milestones,
        purchasedHours,
      }))
    );
  }
}
