<form
  [formGroup]="form"
  class="form-horizontal form-label-left"
  (ngSubmit)="onSubmit()"
>
  <div class="form-group">
    <label class="control-label col-sm-4">Name</label>
    <div class="col-sm-6">
      <input
        type="text"
        formControlName="Name"
        [class.parsley-error]="
          form.controls['Name'].invalid && form.controls['Name'].dirty
        "
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="form.controls['Name'].valid || form.controls['Name'].pristine"
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-sm-4">Group</label>
    <div class="col-sm-6">
      <select type="text" formControlName="Group" required class="form-control">
        <option *ngFor="let opt of GroupOptions" [value]="opt">
          {{ opt }}
        </option>
      </select>
      <ul
        class="parsley-error-list"
        [hidden]="
          form.controls['Group'].valid || form.controls['Group'].pristine
        "
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-sm-4">
      Allocation ({{
        (form.value.Allocation * form.value.Expenditure) / 100 | number
      }}
      expended)
    </label>
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="Allocation"
        [class.parsley-error]="
          form.controls['Allocation'].invalid &&
          form.controls['Allocation'].dirty
        "
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="
          form.controls['Allocation'].valid ||
          form.controls['Allocation'].pristine
        "
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-sm-4">Expenditure</label>
    <div class="col-sm-6">
      <div class="cm-range-input">
        <input
          type="range"
          formControlName="Expenditure"
          [class.parsley-error]="
            form.controls['Expenditure'].invalid &&
            form.controls['Expenditure'].dirty
          "
          required
          class="form-control-range"
        />
        <span class="form-control-range-value">
          {{ form.value.Expenditure }}%
        </span>
      </div>
      <ul
        class="parsley-error-list"
        [hidden]="
          form.controls['Expenditure'].valid ||
          form.controls['Expenditure'].pristine
        "
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-sm-4">Notes</label>
    <div class="col-sm-6">
      <input
        type="text"
        formControlName="Notes"
        [class.parsley-error]="
          form.controls['Notes'].invalid && form.controls['Notes'].dirty
        "
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="
          form.controls['Notes'].valid || form.controls['Notes'].pristine
        "
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-8 col-sm-offset-4">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="form.invalid || formPending"
      >
        Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</form>
