import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { IOpportunity } from "../prototype/opportunity.prototype";
import { OpportunityService } from "../service/opportunity.service";
import { Observable } from "rxjs";

@Injectable()
export class OpportunityResolver implements Resolve<IOpportunity|null> {
  constructor(private backend: OpportunityService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IOpportunity|null> {
    let id = route.params["entityId"];

    return this.backend.getByUniqueKey(id);
  }
}
