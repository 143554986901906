import { Directive, ElementRef, EventEmitter, Output } from "@angular/core";
declare var jQuery: any;

@Directive({
  selector: ".widget"
})
export class WidgetDirective {
  @Output() widgetReload = new EventEmitter();

  private $el: any;

  constructor(el: ElementRef) {
    this.$el = jQuery(el.nativeElement);

    jQuery.fn.widgster.Constructor.DEFAULTS.bodySelector = ".body";

    jQuery(document).on("close.widgster", (e: Event) => {
      let $colWrap = jQuery(e.target).closest(
        '.content > .row > [class*="col-"]:not(.widget-container)'
      );

      // remove colWrap only if there are no more widgets inside
      if (!$colWrap.find(".widget").not(e.target).length) {
        $colWrap.remove();
      }
    });
  }

  ngOnInit(): void {
    this.$el.widgster();

    let refresh = this.$el
      .find('[data-widgster="refresh"]')
      .on("click", this.reloadWidget.bind(this));
  }

  reloadWidget() {
    this.widgetReload.emit();
  }
}
