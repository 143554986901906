import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class MessengerService {
  constructor(private snackBar: MatSnackBar) {}

  info(msg: string) {
    this.snackBar.open(msg, undefined, {
      horizontalPosition: 'end',
      duration: 10000,
    });
  }
  error(msg: string) {
    this.snackBar.open(msg, 'Close', {
      horizontalPosition: 'end',
    });
  }
}
