import { Component } from '@angular/core';

import { ResourcesService } from '../../service';
import { MessengerService } from '../../service/messenger.service';
import { BroadcastService } from '../../service/broadcast.service';
import { AuthService } from '../../service/auth.service';
import { IResource } from '../../prototype';
import { Resource } from '../../components/resource-form/resource';

import { AbstractEntityComponent } from '../../prototype/entity-component';
import { CommonModule } from '@angular/common';
import { ComponentModule } from 'src/app/components';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';

@Component({
  standalone: true,
  imports: [CommonModule, ComponentModule, MatTableModule, MatPaginatorModule],
  templateUrl: 'resources.component.html',
})
export class ResourcesComponent extends AbstractEntityComponent<
  IResource,
  Resource
> {
  protected formModel: Resource = new Resource();
  protected eventType: string = 'resource';

  removedId: string = '';

  constructor(
    protected authService: AuthService,
    protected itemService: ResourcesService,
    protected broadcastService: BroadcastService,
    private messengerService: MessengerService
  ) {
    super();
  }

  prepareForm(resource?: IResource): void {
    this.formHeading = `${resource ? 'Update' : 'Create'} Resource`;
    this.formModel = resource || new Resource();
    super.prepareForm(resource);
  }

  afterSave(): void {
    this.formModel = new Resource();
    this.pull(true);
    super.afterSave();
  }

  edit(resource: IResource) {
    this.prepareForm(resource);
    super.edit(resource);
  }

  remove(id: string) {
    this.itemService.delete(id).subscribe(() => {
      this.pull(true);
      return this.broadcastService.next([this.eventType, 'close']);
    });
  }
}
