import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatPaginatorModule } from '@angular/material/paginator';

import { DirectiveModule } from '../../directive/directive.module';
import { ComponentModule } from '../../components/component.module';

import { MilestoneModule } from '../milestone';
import { HoursModule } from '../hours';

import { ProjectsService } from './projects.service';
import { ProjectsComponent } from './projects.component';
import { ProjectsFormComponent } from './projects-form.component';
import { ProjectModalsComponent } from './project-modals.component';
import { ToBeInvoicedTotal } from './projects.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    DirectiveModule,
    ComponentModule,

    MilestoneModule,
    HoursModule,

    MatPaginatorModule,
  ],
  exports: [ProjectsComponent, ProjectsFormComponent, ProjectModalsComponent],
  declarations: [
    ProjectsComponent,
    ProjectsFormComponent,
    ProjectModalsComponent,
  ],

  providers: [ProjectsService],
})
export class ProjectsModule {}
