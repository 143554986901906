import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { Subject } from "rxjs";

@Injectable()
export class BroadcastService {
  private subject = new Subject<string[]>();

  getObservable(): Observable<string[]> {
    return this.subject.asObservable();
  }

  next(data: string[]): void {
    this.subject.next(data);
  }
}
