import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { DirectiveModule } from '../directive/directive.module';

import { RouterModule } from '@angular/router';
import { ComponentModule } from '../components/component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResourceRequestsRoutingModule } from './resource-requests.routing';
import { ResourceRequestsComponent } from './resource-requests.component';
import { ResourceRequestFormComponent } from './resource-requests-form.component';
import { ResourceRequestTypeFormComponent } from './resource-requests-type-form.component';
import { ResourceCalculatorComponent } from './resource-calculator.component';
import {
  NotActiveTeamMemberPipe,
  OrderByPipe,
  ResourceRequestsBusinessDurationPipe,
  ResourceRequestsCommitmentPipe,
  ResourceRequestsDurationPipe,
  ResourceRequestsPreprocessPipe,
  ResourceRequestsStatsPipe,
  ResourceRequestsStatusPipe,
  ResourceRequestsTotalCommitmentPipe,
  ResourceRequestsVelocityPipe,
} from './resource-requests.pipe';
import { ResourceRequestsService } from './resource-requests.service';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    DirectiveModule,
    ComponentModule,

    MatPaginatorModule,
  ],
  exports: [
    ResourceRequestsRoutingModule,
    ResourceRequestsComponent,
    ResourceRequestFormComponent,
    ResourceRequestTypeFormComponent,
  ],
  declarations: [
    ResourceCalculatorComponent,
    ResourceRequestsComponent,
    ResourceRequestFormComponent,
    ResourceRequestTypeFormComponent,
    ResourceRequestsStatusPipe,

    ResourceRequestsDurationPipe,

    ResourceRequestsBusinessDurationPipe,
    ResourceRequestsVelocityPipe,
    ResourceRequestsTotalCommitmentPipe,
    ResourceRequestsCommitmentPipe,
    ResourceRequestsStatsPipe,
    ResourceRequestsPreprocessPipe,
    OrderByPipe,
    NotActiveTeamMemberPipe,
  ],
  providers: [ResourceRequestsService],
})
export class ResourceRequestsModule {}
