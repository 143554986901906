import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { DirectiveModule } from '../../directive/directive.module';
import { ComponentModule } from '../../components/component.module';

import { CommissionRoutingModule } from './commission-routing.module';
import { CommissionComponent } from './commission.component';
import { TotalCommissionAmountPipe } from './commission.pipe';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  imports: [
    CommissionRoutingModule,
    HttpClientModule,
    CommonModule,
    ComponentModule,
    DirectiveModule,
    MatTableModule,
  ],
  declarations: [CommissionComponent, TotalCommissionAmountPipe],
})
export class CommissionModule {}
