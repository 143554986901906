import { Pipe, PipeTransform } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { Capacities } from '../prototype';
import { Agreement } from '../prototype/agreement.prototype';

@Pipe({ name: 'totalHours' })
export class TotalHoursPipe implements PipeTransform {
  transform(timeTable: any[], items: any[]): number {
    let total = 0;
    if (!timeTable) return total;

    for (let key in timeTable) {
      let time = timeTable[key].time;
      if (time < 0) continue;
      const item = items.find(({ JiraTitle }) => JiraTitle === key);
      if (!item || item.Status === 'On Hold') continue;
      total += time;
    }
    return total;
  }
}

@Pipe({ name: 'groupTimeByProductOwner' })
export class GroupTimeByProductOwnerPipe implements PipeTransform {
  ACTIVE_IMPACT = 20;
  RESPONSIBILITY_IMPACT = 25;
  TEAM_IMPACT = 25;
  PO_IMPACT = 20;
  SALES_IMPACT = 10;

  /**
   * PO productivity for `Time per product owner` table
   *
   * @param items Agreements and products details
   * @param timeTable Information about total time spent on product
   * @param poLogged Time logged by team members grouped by PO
   * @param poOwnLogged Time logged by POs themselves grouped by PO
   * @param userMap Details of every user registered in the system
   */
  transform(
    items: any[],
    timeTable: {[key: string]: any},
    poLogged: {[key: string]: any},
    poOwnLogged: {[key: string]: any},
    userMap: {[key: string]: any}
  ): any {
    const logged = {};

    for (let name in poLogged) {
      let time = poLogged[name].reduce(
        (acc, [, time]) => acc + (+time || 0),
        0
      );
      logged[name] = time;
    }

    const data = items
      .filter((item) => item.ProductOwner && item.Status !== 'On Hold')
      .reduce((result, product) => {
        const val = result[product.ProductOwner] || {
          name: product.ProductOwner,
          time: 0,
          activeProjects: 0,
          notInvoiced: 0,
          scores: 0,
          salesLogged: 0,
        };
        result[product.ProductOwner] = val;
        const record = timeTable[product.JiraTitle] || { time: 0 };
        const itemTime = record.time > 0 ? record.time : 0;
        val.time += itemTime;

        val.notInvoiced += product.NotInvoicedYet;
        val.teamLogged = logged[val.name];

        val.salesLogged += poOwnLogged[val.name].SALES || 0;
        delete poOwnLogged[val.name].SALES;

        val.poOwnLogged = Object.values(poOwnLogged[val.name] || {}).reduce(
          (s: any, n: any) => s + n,
          0
        );

        // LCM-226: account only projects that have more than 30min logged by PO
        if (product.Status === 'Active' && poOwnLogged[val.name][product.JiraTitle] >= 0.5) {
          ++val.activeProjects;
        }

        return result;
      }, {});
    const result = Object.keys(data).map((key) => data[key]);

    let maxActiveProjects = 0;
    let maxLiability = 0;
    let maxTeamLogged = 0;
    for (let item of result) {
      maxActiveProjects = Math.max(maxActiveProjects, item.activeProjects);
      maxLiability = Math.max(maxLiability, item.time);
      maxTeamLogged = Math.max(maxTeamLogged, item.teamLogged);
    }

    for (let item of result) {
      item.activeScore =
        (item.activeProjects / maxActiveProjects) * this.ACTIVE_IMPACT;
      item.responsibilityScore =
        (item.time / maxLiability) * this.RESPONSIBILITY_IMPACT;

      let capacity = userMap[item.name]?.capacity || 1;
      let salesCapacity = userMap[item.name]?.salesCapacity || Capacities.Sales;

      item.teamScore = (item.teamLogged / maxTeamLogged) * this.TEAM_IMPACT;

      item.poScore =
        (Math.min(item.poOwnLogged, capacity) / capacity) * this.PO_IMPACT;

      item.salesScore =
        (Math.min(item.salesLogged, salesCapacity) / salesCapacity) *
        this.SALES_IMPACT;

      item.totalScore =
        item.activeScore +
        item.responsibilityScore +
        item.teamScore +
        item.poScore +
        item.salesScore;
    }

    let final: any[] = [];
    for (let item of result) {
      final.push(item);
      final = final.concat(poLogged[item.name]);
    }
    return final;
  }
}
