import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { ClientService } from '../service/client.service';

import { Client } from '../components/client-form/client-account';

import { AbstractDetailComponent } from '../prototype/entity-detail-component';
import { AuthService, BroadcastService, JiraService } from '../service';

@Component({
  templateUrl: 'client-account-detail.template.html',
})
export class ClientAccountDetailComponent extends AbstractDetailComponent<Client> {
  protected eventType: string = 'client-details';

  constructor(
    protected authService: AuthService,
    protected itemService: ClientService,
    protected route: ActivatedRoute,
    protected location: Location,
    protected broadcastService: BroadcastService,
    protected jira: JiraService
  ) {
    super();
  }
}
