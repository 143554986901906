import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AgreementComponent } from "./agreement.component";
import { AgreementDetailComponent } from "./agreement-detail.component";
import { AgreementResolver } from "./agreement-resolver";

export const routes: Routes = [
  { path: "", component: AgreementComponent, pathMatch: "full" },
  { path: ":clientCode", component: AgreementComponent, pathMatch: "full" },
  {
    path: "view/:entityId",
    component: AgreementDetailComponent,
    pathMatch: "full",
    resolve: {
      entity: AgreementResolver
    }
  },
  {
    path: ":clientCode/:jobNumber",
    component: AgreementComponent,
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgreementRoutingModule {}
