import { IClient } from "../../prototype/client.prototype";

export class Client {
  constructor(
    public ClientCode: string = "",
    public ClientName: string = "",
    public XeroId?: string,
    public InsightlyId?: string,
    public AwsId?: string,
    public Notes?: string,
    public Commissionable?: boolean,
  ) {}
}
