import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { ResourceFormComponent } from './resource-form/resource-form.component';
import { OpportunityFormComponent } from './opportunity-form/opportunity-form.component';
import { AgreementFormComponent } from './agreement-form/agreement-form.component';
import { AddonFormComponent } from './agreement-form/addon-form.component';
import { UserFormComponent } from './user-form/user-form.component';
import { CsvExportComponent } from './agreement-form/csv-export-form/csv-export-form.component';
import { ActivityStreamComponent } from './activity-stream/activity-stream.component';
import { HoursComponent } from './agreement-form/hours-form.component';
import { TimeManagementTable } from './time-management-table/time-management-table.component';

import { DirectiveModule } from '../directive/directive.module';

import { FileUploadModule } from 'ng2-file-upload';

import { ListingComponent } from '.';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { TimeManagementTableView } from './time-management-table/time-management-table-view.component';

@NgModule({
  declarations: [
    ModalComponent,
    ClientFormComponent,
    ResourceFormComponent,
    OpportunityFormComponent,
    AgreementFormComponent,
    AddonFormComponent,
    UserFormComponent,
    CsvExportComponent,
    ActivityStreamComponent,
    HoursComponent,
    TimeManagementTable,

    // rework
    ListingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectiveModule,
    FileUploadModule,

    MatTooltipModule,
    MatTableModule,

    TimeManagementTableView,

  ],
  exports: [
    ModalComponent,
    ClientFormComponent,
    ResourceFormComponent,
    OpportunityFormComponent,
    AgreementFormComponent,
    AddonFormComponent,
    UserFormComponent,
    CsvExportComponent,
    ActivityStreamComponent,
    HoursComponent,
    TimeManagementTable,

    // rework
    ListingComponent,
  ],
})
export class ComponentModule {}
