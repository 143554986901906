import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FetchAbstractService } from "../service/fetch-abstract.service";
import { LambdaService } from "../service/lambda.service";

import { MessengerService, AuthService } from "../service";
import { IResourceRequest } from ".";


@Injectable()
export class ResourceRequestsService extends FetchAbstractService<
  IResourceRequest
> {
  protected uniqueKeyName: string = "Id";

  constructor(
    protected http: HttpClient,
    protected lambda: LambdaService,
    protected auth: AuthService,
    protected messenger: MessengerService
  ) {
    super();
  }
  getLoadAllErrorMsg(): string {
    return "List of resource requests is unavailable";
  }

  getLoadAllUrl(): string {
    return this.lambda.getResourceRequestsUrl();
  }

  getSaveUrl(): string {
    return this.lambda.getResourceRequestsUrl();
  }

  getEditUrl(req: IResourceRequest): string {
    return this.getSaveUrl() + "/update/" + req.Id;
  }

  forProject(id: string): Observable<any> {
    let request: Observable<any> = this.http
      .get(this.getSaveUrl() + "/" + id, this.getDefaultHeaders())

    return request;
  }

  delete(id: string): Observable<any> {
    let request: Observable<any> = this.http.delete(
      this.getSaveUrl() + "/delete/" + id,
      this.getDefaultHeaders()
    );

    return request;
  }
}
