import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MessengerService } from './messenger.service';
import { LambdaService } from './lambda.service';
import { FetchAbstractService } from './fetch-abstract.service';
import { AuthService } from './auth.service';

import { IOpportunity } from '../prototype/opportunity.prototype';

@Injectable()
export class OpportunityService extends FetchAbstractService<IOpportunity> {
  protected uniqueKeyName: string = 'JobNumber';

  milestoneStatuses = {
    0: 'Not ready to invoice',
    10: 'Ready to invoice',
    20: 'Invoiced',
  };

  constructor(
    protected http: HttpClient,
    protected lambda: LambdaService,
    protected auth: AuthService,
    protected messenger: MessengerService
  ) {
    super();
    this.defaultOrder = 'Desc';
    this.defaultOrderColumn = 'JobNumber';
    this.filterBy = ['Title', 'ClientCode', 'Status', 'JobNumber'];
  }

  iterMilestoneStatuses() {
    return Object.keys(this.milestoneStatuses).map((k) => [
      +k,
      this.milestoneStatuses[k],
    ]);
  }

  canCreate(): boolean {
    return this.auth.isAdminOrEditor();
  }

  getLoadAllUrl(): string {
    return this.lambda.getOpportunitiesUrl();
  }

  getSaveUrl(): string {
    return this.lambda.getOpportunitiesUrl();
  }

  getEditUrl(opportunity: IOpportunity): string {
    return this.getSaveUrl() + '/' + opportunity.JobNumber;
  }
  getMilestoneUrl(id: string) {
    return this.lambda.getOpportunitiesUrl() + '/' + id + '/milestone';
  }
  getUpdateMilestoneUrl(JobNumber: string, Id: string) {
    return this.getMilestoneUrl(JobNumber) + '/' + Id;
  }

  getLoadAllErrorMsg(): string {
    return 'List of opportunities is unavailable';
  }

  addHoursUrl(id: string, type: string): string {
    return this.lambda.getOpportunitiesUrl() + '/' + id + '/hours/' + type;
  }

  updateHoursUrl(id: string, type: string): string {
    return (
      this.lambda.getOpportunitiesUrl() + '/' + id + '/update-hours/' + type
    );
  }

  removeHoursUrl(id: string, type: string, uuid: string | number): string {
    return this.addHoursUrl(id, type) + '/' + uuid;
  }

  getOpportunityTitle(jobNumber: string): Observable<string | null> {
    let opportunityTitle = this.getAll()
      .pipe(
        map(
          (opportunities: IOpportunity[]) =>
            opportunities.filter(
              (c: IOpportunity) => c.JobNumber === jobNumber
            )[0]
        )
      )
      .pipe(map((o: IOpportunity) => (o ? o.Title : null)));

    return opportunityTitle;
  }

  getJobNumbers(): Observable<Set<string>> {
    return this.getAll(false).pipe(
      map(
        (items: IOpportunity[]) =>
          new Set(items.map((item: IOpportunity) => item.JobNumber))
      )
    );
  }

  addHours(id: string, data: any, type: string): Observable<any> {
    let url = this.addHoursUrl(id, type);
    let headers = this.getDefaultHeaders();
    return this.http.put<any>(url, data, headers);
  }

  updateHours(id: string, data: any, type: string): Observable<any> {
    let url = this.updateHoursUrl(id, type);
    let headers = this.getDefaultHeaders();
    return this.http.put<any>(url, data, headers);
  }

  removeHours(
    id: string,
    type: string,
    uuid: string | number
  ): Observable<any> {
    let url = this.removeHoursUrl(id, type, uuid);
    let headers = this.getDefaultHeaders();
    return this.http.delete<any>(url, headers);
  }

  addMilestone(id: string, data: any) {
    return this.http.post<any>(
      this.getMilestoneUrl(id),
      data,
      this.getDefaultHeaders()
    );
  }
  moveMilestoneForward(JobNumber: string, Id: string) {
    return this.http.put(
      this.getUpdateMilestoneUrl(JobNumber, Id),
      {},
      this.getDefaultHeaders()
    );
  }
  updateMilestone(JobNumber: string, data: any) {
    return this.http.put(
      this.getUpdateMilestoneUrl(JobNumber, data.Id),
      data,
      this.getDefaultHeaders()
    );
  }

  deleteMilestone(JobNumber: string, Id: string) {
    return this.http.delete<any>(
      this.getUpdateMilestoneUrl(JobNumber, Id),
      this.getDefaultHeaders()
    );
  }
}
