import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";

import { MessengerService } from "./messenger.service";
import { LambdaService } from "./lambda.service";
import { FetchAbstractService } from "./fetch-abstract.service";
import { AuthService } from "./auth.service";
import { ClientService } from "./client.service";

import { IAgreement } from "../prototype/agreement.prototype";

import { Observable } from "rxjs";
import { tap, map } from "rxjs/operators";

@Injectable()
export class AgreementService extends FetchAbstractService<IAgreement> {
  protected uniqueKeyName: string = "AgreementNumber";

  constructor(
    protected http: HttpClient,
    protected lambda: LambdaService,
    protected auth: AuthService,
    protected client: ClientService,
    protected messenger: MessengerService
  ) {
    super();
    this.defaultOrder = "Desc";
    this.defaultOrderColumn = "AgreementNumber";
    this.filterBy = ["Title", "ClientCode", "JobNumber", "AgreementNumber"];
  }

  getLoadAllUrl(): string {
    return this.lambda.getAgreementsUrl();
  }

  getSaveUrl(): string {
    return this.lambda.getAgreementsUrl();
  }

  getEditUrl(agreement: IAgreement): string {
    return this.getSaveUrl() + "/" + agreement.AgreementNumber;
  }

  getDocumentUploadUrl(): string {
    return this.lambda.getDocumentsUrl();
  }

  getDocumentReadUrl(id: string): string {
    return this.lambda.getDocumentsUrl() + "/" + id;
  }

  getNextInvoiceUrl(id: string): string {
    return this.lambda.getAgreementsUrl() + "/" + id + "/update-invoice-date";
  }
  getMilestoneUrl(id: string) {
    return this.lambda.getAgreementsUrl() + "/" + id + "/milestone";
  }

  addHoursUrl(id: string, type: string): string {
    return this.lambda.getAgreementsUrl() + "/" + id + "/hours/" + type;
  }

  updateHoursUrl(id: string, type: string): string {
    return this.lambda.getAgreementsUrl() + "/" + id + "/update-hours/" + type;
  }

  removeHoursUrl(id: string, type: string, uuid: string | number): string {
    return this.addHoursUrl(id, type) + "/" + uuid;
  }

  addAddonUrl(id: string): string {
    return this.lambda.getAgreementsUrl() + "/" + id + "/addons";
  }

  removeAddonUrl(id: string, uuid: string | number): string {
    return this.addAddonUrl(id) + "/" + uuid;
  }

  getDocumentRemoveUrl(id: string): string {
    return this.getDocumentReadUrl(id);
  }

  getLoadAllErrorMsg(): string {
    return "List of agreements is unavailable";
  }

  removeDocument(
    id: string,
    agreement: string
  ): Observable<HttpResponse<string>> {
    return this.http.delete<HttpResponse<string>>(
      this.getDocumentRemoveUrl(id),
      this.getDefaultHeaders()
    );
  }

  downloadDocument(id: string): void {
    const { headers } = this.getDefaultHeaders();
    this.http
      .get(this.getDocumentReadUrl(id), {
        headers,
        observe: "body",
        responseType: "text",
      })
      .subscribe((url: string) => {
        window.open(url, "_blank");
      });
  }

  prepareDocumentUpload(name: string, type: string): Observable<any> {
    let headers = this.getDefaultHeaders();

    return this.http.post<any>(
      this.getDocumentUploadUrl(),
      { ContentType: type, DocumentName: name },
      headers
    );
  }

  getExpired(force: boolean = false): Observable<IAgreement[]> {
    if (force) this.reset();
    return this.getAll().pipe(
      tap((agreements: IAgreement[]) =>
        agreements.forEach((agreement: IAgreement) => {
          agreement.ExpiringIn = Math.ceil(
            (new Date(agreement.EndDate).getTime() - new Date().getTime()) /
              1000 /
              3600 /
              24
          );
        })
      ),
      map((agreements: IAgreement[]) =>
        agreements.filter(
          (agreement: IAgreement) =>
            ~["Active", "Pending"].indexOf(agreement.Status) &&
            (agreement.ExpiringIn || 0) < 60
        )
      ),
      tap((agreements: IAgreement[]) =>
        agreements.forEach((agreement: IAgreement) =>
          this.client
            .getClientName(agreement.ClientCode)
            .subscribe((name: string) => (agreement.ClientName = name))
        )
      ),
      map((agreements: IAgreement[]) => this.sort("EndDate", "Asc", agreements))
    );
  }

  getDueInvoices(force: boolean = false): Observable<IAgreement[]> {
    if (force) this.reset();
    return this.getAll().pipe(
      tap((agreements: IAgreement[]) =>
        agreements.forEach(
          (agreement: IAgreement) =>
            (agreement.InvoiceIn = Math.ceil(
              (new Date(agreement.NextInvoiceDate||new Date).getTime() -
                new Date().getTime() || 0) /
                1000 /
                3600 /
                24
            ))
        )
      ),
      tap((agreements: IAgreement[]) =>
        agreements.forEach((agreement: IAgreement) =>
          this.client
            .getClientName(agreement.ClientCode)
            .subscribe((name: string) => (agreement.ClientName = name))
        )
      ),
      map((agreements: IAgreement[]) =>
        agreements.filter(
          (agreement: IAgreement) =>
            agreement.Status !== "Expired" && (agreement.InvoiceIn || 0) < 14
        )
      ),
      map((agreements: IAgreement[]) =>
        this.sort("NextInvoiceDate", "Asc", agreements)
      )
    );
  }

  getSeparateAddons(force: boolean = false): Observable<any[]> {
    const supportedStatuses = ["Active", "On Hold"];
    if (force) this.reset();
    return this.getAll().pipe(
      map((agreements: IAgreement[]) =>
        agreements
          .filter(
            ({ Status, Addons }) =>
              ~supportedStatuses.indexOf(Status) && Addons && Addons.length
          )
          .map((item) =>
            Object.assign({}, item, {
              Addons: item.Addons.filter(
                (addon) =>
                  addon.InvoiceSeparately &&
                  (addon.InvoiceIn = Math.ceil(
                    (new Date(addon.NextInvoiceDate||new Date).getTime() -
                      new Date().getTime() || 0) /
                      1000 /
                      3600 /
                      24
                  )) < 14
              ),
            })
          )
          .filter(({ Addons }) => Addons.length)
          .map((agreement) =>
            agreement.Addons.map((addon) => ((addon.parent = agreement), addon))
          )
          .reduce((acc: any, next) => acc.concat(next), [])
          .sort(({ NextInvoiceDate: left }, { NextInvoiceDate: right }) =>
            left < right ? -1 : left > right ? 1 : 0
          )
      )
    );
  }

  updateNextInvoiceDate(
    agreementNumber: string,
    addonId?: string
  ): Observable<any> {
    let headers = this.getDefaultHeaders();
    return this.http.put<any>(
      this.getNextInvoiceUrl(agreementNumber),
      { addonId },
      headers
    );
  }

  addHours(id: string, data: any, type: string): Observable<any> {
    let url = this.addHoursUrl(id, type);
    let headers = this.getDefaultHeaders();
    return this.http.put<any>(url, data, headers);
  }

  updateHours(id: string, data: any, type: string): Observable<any> {
    let url = this.updateHoursUrl(id, type);
    let headers = this.getDefaultHeaders();
    return this.http.put<any>(url, data, headers);
  }

  removeHours(
    id: string,
    type: string,
    uuid: string | number
  ): Observable<any> {
    let url = this.removeHoursUrl(id, type, uuid);
    let headers = this.getDefaultHeaders();
    return this.http.delete<any>(url, headers);
  }

  addAddon(id: string, data: any): Observable<any> {
    let url = this.addAddonUrl(id);
    let headers = this.getDefaultHeaders();
    return this.http.put<any>(url, data, headers);
  }

  removeAddon(id: string, uuid: string | number): Observable<any> {
    let url = this.removeAddonUrl(id, uuid);
    let headers = this.getDefaultHeaders();
    return this.http.delete<any>(url, headers);
  }
  addMilestone(id: string, data: any) {
    return this.http.post<any>(
      this.getMilestoneUrl(id),
      data,
      this.getDefaultHeaders()
    );
  }
}
