import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ClientService } from '../service/client.service';
import { UserService } from '../service/user.service';
import { OpportunityService } from '../service/opportunity.service';
import { AgreementService } from '../service/agreement.service';
import { MessengerService } from '../service/messenger.service';
import { BroadcastService } from '../service/broadcast.service';
import { AuthService } from '../service/auth.service';

import { AbstractEntityComponent } from '../prototype/entity-component';
import { IAgreement, Agreement } from '../prototype/agreement.prototype';
import { IUser } from '../prototype';
import { map } from 'rxjs/operators';
import { JiraService } from '../service';

import * as moment from "moment";

@Component({
  templateUrl: 'agreement.template.html',
})
export class AgreementComponent extends AbstractEntityComponent<
  IAgreement,
  Agreement
> {
  protected formModel: Agreement = new Agreement();
  protected eventType: string = 'agreement';

  clientCode?: string;
  usersList: IUser[] = [];
  clientName?: string;
  jobNumber?: string;
  jobTitle?: string;
  justUpdatedAgreement?: string;
  clientCodeToNameMapping = {};

  constructor(
    protected itemService: AgreementService,
    private clientService: ClientService,
    private userService: UserService,
    private opportunityService: OpportunityService,
    protected broadcastService: BroadcastService,
    private messengerService: MessengerService,
    protected jira: JiraService,
    protected authService: AuthService,
    private route: ActivatedRoute
  ) {
    super();
  }

  canCreate(): boolean {
    return this.authService.isAdmin();
  }

  transformDate(date: string): string {
    return moment(date).format('DD/MM/Y');
  }

  prepareForm(agreement?: IAgreement): void {
    this.formHeading = `${agreement ? 'Update' : 'Create'} Agreement`;
    this.formModel =
      agreement || new Agreement(this.clientCode || '', this.jobNumber || '');
    super.prepareForm(agreement);
  }

  afterSave(): void {
    this.justUpdatedAgreement = this.formModel.AgreementNumber;
    this.formModel = new Agreement();
    this.pull(true);
    super.afterSave();
    this.broadcastService.next([
      'reservation-popup',
      'show:reservation-agreement-popup',
    ]);
  }

  edit(agreement: IAgreement) {
    this.prepareForm(agreement);
    super.edit(agreement);
  }

  ngOnInit(): void {
    let filter: { [key: string]: any } = {};
    this.clientCode = this.route.snapshot.params['clientCode'];
    this.jobNumber = this.route.snapshot.params['jobNumber'];

    this.clientService
      .getAll()
      .pipe(
        map((clients) =>
          clients.reduce((mapping, client) => {
            mapping[client.ClientCode] = client.ClientName;
            return mapping;
          }, {})
        )
      )
      .subscribe((result) => {
        this.clientCodeToNameMapping = result;
      });
    this.userService
      .getAll()
      .pipe(map((all) => all.filter((u) => u.State === 'active')))
      .subscribe(
        (usersList: IUser[]) =>
          (this.usersList = usersList.sort(({ Email: a }, { Email: b }) => {
            if (a === b) return 0;
            if (a === this.authService.email) return -1;
            if (b === this.authService.email) return 1;
            return a > b ? 1 : -1;
          }))
      );

    if (this.clientCode) {
      filter['ClientCode'] = this.clientCode;
      this.updateClientName(this.clientCode);
    }
    if (this.jobNumber) {
      filter['JobNumber'] = this.jobNumber;
      this.updateOpportunityTitle(this.jobNumber);
    }
    this.itemService.setFilter(filter);
    super.ngOnInit();
  }

  getJiraLink(agreement: IAgreement): string {
    return this.jira.jiraLink + agreement.JiraTitle;
  }

  updateClientName(clientCode: string): void {
    this.clientService
      .getClientName(clientCode)
      .subscribe((clientName: string) => (this.clientName = clientName));
  }

  updateOpportunityTitle(jobNumber: string): void {
    this.opportunityService
      .getOpportunityTitle(jobNumber)
      .subscribe((jobTitle: any) => (this.jobTitle = jobTitle));
  }
}
