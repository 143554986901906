import { Component, Input } from '@angular/core';

import {
  ResourceRequestsService,
  IResourceRequest,
  ResourceRequest,
  IResourceType,
} from '.';
import { MessengerService } from '../service/messenger.service';
import { BroadcastService } from '../service/broadcast.service';
import { AuthService } from '../service/auth.service';

import { AbstractEntityComponent } from '../prototype/entity-component';

@Component({
  selector: 'resource-requests',
  templateUrl: 'resource-requests.component.html',
})
export class ResourceRequestsComponent extends AbstractEntityComponent<
  IResourceRequest,
  ResourceRequest
> {
  protected formTypeModel?: IResourceType;

  protected formModel: ResourceRequest = new ResourceRequest();
  protected eventType: string = 'resource-request';

  removedId = '';

  @Input() linkedProject?: string;
  @Input() projectType: string = '';

  sort = {
    stats: { column: 'Name', asc: false },
    request: { column: 'StartDate', asc: false },
    resource: { column: 'Name', asc: false },
  };

  expandedRows = {};
  activeTypeIdx: number = 0;

  constructor(
    protected authService: AuthService,
    protected itemService: ResourceRequestsService,
    protected broadcastService: BroadcastService,
    private messengerService: MessengerService
  ) {
    super();
  }

  pull(force: boolean = false): void {
    if (force && !this.searchQ) {
      this.itemService.reset();
    }
    this.isUpdating = true;

    let collection;
    if (this.linkedProject) {
      collection = this.itemService.forProject(
        this.projectType[0] + this.linkedProject
      );
    } else {
      collection = this.itemService.getAll();
    }

    collection.subscribe((items) => {
      this.items = items;
      this.isUpdating = false;
    });
  }

  turnPage(): void { }

  prepareForm(resourceRequest?: ResourceRequest): void {
    this.formHeading = `${
      resourceRequest ? 'Update' : 'Create'
    } Resource Request`;
    let defaultProject =
      this.projectType && this.linkedProject
        ? this.projectType[0] + this.linkedProject
        : '';
    this.formModel = resourceRequest || new ResourceRequest(defaultProject);
    super.prepareForm(resourceRequest);
  }

  linkedProjectRoute(code): string[] {
    let types = {
      a: 'agreements',
      c: 'client-accounts',
      p: 'products',
    };
    return ['/app', types[code[0]], 'view', code.slice(1)];
  }

  afterSave(): void {
    this.formModel = new ResourceRequest();
    this.pull(true);
    super.afterSave();
  }

  edit(resourceRequest: ResourceRequest) {
    if (!resourceRequest.ResourceTypes) {
      resourceRequest.ResourceTypes = [];
    }
    this.prepareForm(resourceRequest);
    super.edit(resourceRequest);
  }

  remove(id: string) {
    this.itemService.delete(id).subscribe(() => {
      this.pull(true);
      this.broadcastService.next([this.eventType, 'close']);
    });
  }
  editType(resourceRequest: ResourceRequest, idx: number) {
    this.activeTypeIdx = idx;
    this.formTypeModel = resourceRequest.ResourceTypes[idx];
    this.formModel = resourceRequest;
  }

  removeType(resourceRequest: ResourceRequest, idx: number) {
    resourceRequest.ResourceTypes.splice(idx, 1);
    this.itemService.save(resourceRequest, true).subscribe(() => {
      this.pull(true);
      this.broadcastService.next([this.eventType, 'close']);
    });
  }
  onTypeUpdated(payload) {
    const [idx, type_] = payload;
    (this.formModel as IResourceRequest).ResourceTypes[idx] = type_;
    this.afterSave();
  }
  sortTable(type_: string, column: string) {
    const sort = this.sort[type_];
    if (sort.column === column) {
      sort.asc = !sort.asc;
    } else {
      sort.asc = false;
    }
    sort.column = column;
  }
}
