import * as moment from 'moment';

export interface IAgreement {
  ClientCode: string;
  JobNumber: string;
  AgreementNumber: string;
  Title: string;
  ProductOwner: string;
  ServiceType: string;
  StartDate: string;
  EndDate: string;
  BillingCycle: string;
  Status: string;
  InvoiceAmount: number;
  ServiceAmount: number;
  HostingAmount: number;
  SupportMaintenanceAmount: number;
  HasSLA: boolean;
  SupportHours: number;
  JiraLicence: number;
  JiraTitle: string;
  Addons: Addon[];
  Invoices?: string;
  PurchasedHours: any;
  UnchargeableHours: any;
  ProfitableHours: any;
  Documents: Document[];
  UnforeseenHours?: any[];
  Notes?: string;
  BudgetNote?: string;
  ClientName?: string;
  ExpiringIn?: number;
  NextInvoiceDate?: string;
  InvoiceIn?: number;
  Commissionable?: boolean;
}

export interface IAddon {
  AddonName: string;
  Id?: string;
}

export class Addon {
  public Id?: string;
  public InvoiceSeparately: boolean = false;
  public NextInvoiceDate?: string;
  public StartDate?: string;
  public EndDate?: string;
  public BillingCycle?: string;
  public Status?: string;
  public InvoiceAmount?: number;
  public InvoiceIn?: number;
  public parent?: any;
  constructor(public AddonName: string = '') {}
}

export interface IDocument {
  DocumentId: string;
  DocumentName: string;
}

export class Document {
  constructor(
    public DocumentId: string = '',
    public DocumentName: string = ''
  ) {}
}

export class Agreement {
  public ExpiringIn?: number;
  public NextInvoiceDate?: string;
  public BudgetNote?: string;
  public Notes?: string;
  public InvoiceIn?: number;
  public Commissionable?: boolean;
  constructor(
    public ClientCode: string = '',
    public JobNumber: string = '',
    public AgreementNumber: string = '',
    public Title: string = '',
    public ProductOwner: string = '',
    public ServiceType: string = '',
    public StartDate: string = '',
    public EndDate: string = '',
    public BillingCycle: string = '',
    public Status: string = '',
    public InvoiceAmount: number = 0,
    public ServiceAmount: number = 0,
    public HostingAmount: number = 0,
    public SupportMaintenanceAmount: number = 0,
    public HasSLA: boolean = false,
    public SupportHours: number = 0,
    public JiraTitle: string = '',
    public JiraLicence: number = 0,
    public Addons: Addon[] = [],
    public PurchasedHours: any = [],
    public UnchargeableHours: any = [],
    public ProfitableHours: any = [],
    public Documents: Document[] = [],
    Notes?: string,
    ClientName?: string,
    public UnforeseenHours?: any[],
  ) {}
}

export const ServiceTypes = [
  'AWS Hosting',
  'AWS + S&M',
  'DSE Shared Hosting',
  'DSE + S&M',
  'Support & Maintenance',
  'GitHub Subscription',
  'SSL Certificate',
];
export const BillingCycles = ['Monthly', 'Quarterly', 'Bi-annually', 'Yearly'];
export const Statuses = ['Active', 'Pending', 'Expired'];

export const AddonNames = [
  'SSL Certificate',
  'Development Hosting',
  'Reservation Costs',
  'Enterprise Account Management',
  'External Software',
  'Additional Storage',
];

export const getYears: number[] = (() => {
  let currentDate = new Date();
  let pastYear = currentDate.getFullYear() - 1;
  let nextYear = currentDate.getFullYear() + 1;

  return [pastYear, currentDate.getFullYear(), nextYear];
})();

export const getYearsRange: number[] = (() => {
  let currentDate = new Date();
  let datesArr: number[] = [];
  let pastYear = currentDate.getFullYear() - 3;
  let increaseYear = true;
  datesArr.push(pastYear);
  let i = 0;
  while (increaseYear) {
    datesArr.push(datesArr[i] + 1);
    i++;
    if (datesArr[i] - 3 === currentDate.getFullYear()) increaseYear = false;
  }

  return datesArr;
})();

export const getMonths: string[] = (() => {
  return moment.months();
})();
