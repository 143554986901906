
export interface IProject {
  Id?: string;
  Owner: string;
  Title: string;
  Description: string;
  PurchaseOrder: string;
  StartDate: string;
  EndDate: string;

  PurchasedHours: any[];
  Milestones: any[];
}

export class Project implements IProject {
  PurchasedHours: any[] = [];
  Milestones: any[] = [];

  constructor(
    public Id: string = "",
    public Owner: string = "",
    public Title: string = "",
    public Description: string = "",
    public PurchaseOrder: string = "",
    public StartDate: string = "",
    public EndDate: string = ""
  ) {}
}
