import {
  Directive,
  ElementRef,
  OnInit,
  Output,
  EventEmitter
} from "@angular/core";

declare var jQuery: any;

@Directive({
  selector: "[datetimepicker]"
})
export class DatetimepickerDirective implements OnInit {
  private $el: any;

  @Output() valueChanged = new EventEmitter();

  constructor(el: ElementRef) {
    this.$el = jQuery(el.nativeElement);
  }

  ngOnInit(): void {
    this.$el
      .datetimepicker({
        format: this.$el.data("format")
      })
      .on("dp.hide", ({ date }) =>
        this.valueChanged.emit(date.format("Y-MM-DD"))
      );
  }
}
