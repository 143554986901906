import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

import { AuthService } from "../../service/auth.service";
import { SidebarLinkCollectionComponent } from "./sidebar-link-collection.component";
import { SidebarLinkComponent } from "./sidebar-link.component";

@Component({
  standalone: true,
  imports: [SidebarLinkComponent, SidebarLinkCollectionComponent, CommonModule],
  selector: "sidebar",
  templateUrl: "./sidebar.template.html"
})
export class SidebarComponent {
  constructor(public auth: AuthService) {}
}
