import { IOpportunity } from "./opportunity.prototype";
import { IAgreement } from "./agreement.prototype";
import { IUser, Teams, States, Capacities } from "./user.prototype";

export { IOpportunity, IAgreement, IUser, Teams, States, Capacities };

export interface IResource {
  Id?: string;
  Title: string;
  Url: string;
}
