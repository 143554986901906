import { Component, Input } from "@angular/core";

import { ClientService } from "../service/client.service";
import { MessengerService } from "../service/messenger.service";
import { BroadcastService } from "../service/broadcast.service";
import { AuthService } from "../service/auth.service";
import { IClient } from "../prototype/client.prototype";
import { Client } from "../components/client-form/client-account";

import { AbstractEntityComponent } from "../prototype/entity-component";
import { MatDialog } from "@angular/material/dialog";



@Component({
  templateUrl: "client-account.template.html",
  styles: [
    `
      .business-links img {
        width: 28px;
        height: 28px;
      }
      .business-links a:hover {
        text-decoration: none;
      }
    `
  ]
})
export class ClientAccountComponent extends AbstractEntityComponent<
  IClient,
  Client
> {
  protected formModel: Client = new Client();
  protected eventType: string = "clientAccount";

  constructor(
    protected authService: AuthService,
    protected itemService: ClientService,
    protected broadcastService: BroadcastService,
    private messengerService: MessengerService,
    protected dialog: MatDialog,
  ) {
    super();
  }

  canCreate(): boolean {
    return this.authService.isAdminOrEditor();
  }
  canEdit(): boolean {
    return this.authService.isAdminOrEditor();
  }

  prepareForm(client?: IClient): void {
    this.formHeading = `${client ? "Update" : "Create"} Client Account`;
    this.formModel = client || new Client();
    super.prepareForm(client);
  }

  afterSave(): void {
    this.formModel = new Client();
    this.pull(true);
    super.afterSave();
  }

  edit(client: IClient) {
    this.prepareForm(client);
    super.edit(client);
  }
}
