import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BroadcastService } from '../../service';

declare var $: any;

@Component({
  selector: 'modal-dialog',
  templateUrl: './modal.template.html',
})
export class ModalComponent implements OnInit {
  // @Input() set header(value: string) {
  //     this.heading = value;
  // }
  @Input() withControls: boolean = false;
  @Input() heading!: string;
  @Input() modalId!: string;
  @Input() broadcaster!: Observable<string>;
  @Input('cancel-text') cancelText: string = 'Cancel';
  @Input('confirm-text') confirmText: string = 'Confirm';

  @Output() acceptModal = new EventEmitter();
  @Output() rejectModal = new EventEmitter();

  protected subscription!: Subscription;

  constructor(protected broadcastService: BroadcastService) {
    if (!this.broadcaster) {
      this.broadcaster = this.broadcastService
        .getObservable()
        .pipe(map(([, event]) => event));
    }
  }
  ngOnInit(): void {
    this.subscription = this.broadcaster.subscribe((event) => {
      switch (event) {
        case 'close':
          $('.modal').modal('hide');
          break;
        case 'show:' + this.modalId:
          $('#' + this.modalId).modal('show');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  onAccept(modal: any) {
    this.acceptModal.emit($(modal));
  }
}
