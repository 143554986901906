<form
  [formGroup]="form"
  class="form-horizontal form-label-left"
  (ngSubmit)="onSubmit()"
>
  <div class="form-group">
    <label class="control-label col-sm-4">Title</label>
    <div class="col-sm-6">
      <input
        type="text"
        formControlName="Title"
        [class.parsley-error]="
          form.controls['Title'].invalid && form.controls['Title'].dirty
        "
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="
          form.controls['Title'].valid || form.controls['Title'].pristine
        "
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-sm-4">Description</label>
    <div class="col-sm-6">
      <input
        type="text"
        formControlName="Description"
        [class.parsley-error]="
          form.controls['Description'].invalid &&
          form.controls['Description'].dirty
        "
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="
          form.controls['Description'].valid ||
          form.controls['Description'].pristine
        "
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4">Purchase Order</label>
    <div class="col-sm-6">
      <input
        type="number"
        formControlName="PurchaseOrder"
        [class.parsley-error]="
          form.controls['PurchaseOrder'].invalid &&
          form.controls['PurchaseOrder'].dirty
        "
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="
          form.controls['PurchaseOrder'].valid ||
          form.controls['PurchaseOrder'].pristine
        "
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4">Start Date</label>
    <div class="col-sm-6">
      <input
        type="text"
        datetimepicker
        data-format="Y-MM-DD"
        formControlName="StartDate"
        [class.parsley-error]="
          form.controls['StartDate'].invalid && form.controls['StartDate'].dirty
        "
        (valueChanged)="form.controls['StartDate'].setValue($event)"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="
          form.controls['StartDate'].valid ||
          form.controls['StartDate'].pristine
        "
      >
        <li>This field is required</li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4">End Date</label>
    <div class="col-sm-6">
      <input
        type="text"
        datetimepicker
        data-format="Y-MM-DD"
        formControlName="EndDate"
        [class.parsley-error]="
          form.controls['EndDate'].invalid && form.controls['EndDate'].value
        "
        (valueChanged)="form.controls['EndDate'].setValue($event)"
        required
        class="form-control"
      />
      <ul
        class="parsley-error-list"
        [hidden]="
          form.controls['EndDate'].valid || !form.controls['EndDate'].value
        "
      >
        <li [hidden]="!form.controls['EndDate'].hasError('required')">
          This field is required
        </li>
        <li [hidden]="!form.controls['EndDate'].hasError('greater')">
          Must be greater than Start Date
        </li>
      </ul>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-8 col-sm-offset-4">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="form.invalid || formPending"
      >
        Save <i *ngIf="formPending" class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</form>
