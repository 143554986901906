import { Component, Input, EventEmitter, Output } from "@angular/core";

import { Project } from ".";

@Component({
  selector: "project-modals",
  templateUrl: "./project-modals.component.html",
})
export class ProjectModalsComponent {
  @Input() formModel!: Project;

  @Output() remove = new EventEmitter();
  @Output() save = new EventEmitter();
}
