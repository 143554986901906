import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { IResourceRequest } from ".";

class Stats {
  constructor(
    public Name: string,
    public TotalHours = 0,
    public RemainingHours = 0,
    public RemainingAllocation = 0
  ) {}
}

const resourceRequestStatuses = {
  0: "Draft",
  10: "Pending",
  20: "Approved",
  30: "Rejected"
};

function _hiddenProp(obj: Object, name: string, value: any) {
  Object.defineProperty(obj, name, { enumerable: false, value });
}
function _resourceRequestsVelocity(req: IResourceRequest): number {
  let start = moment.utc(req.StartDate);
  let end = moment.utc(req.EndDate).add(1, "day");
  const diff = countBusinessDays(start, end);
  let totalHours = 0;
  for (let type_ of req.ResourceTypes || []) {
    totalHours += type_.Allocation;
  }

  return totalHours / (diff / 5);
}
function _resourceRequestsTotalCommitment(req: IResourceRequest): number {
  let remainingHours = 0;
  for (let type_ of req.ResourceTypes || []) {
    remainingHours += type_.Allocation * (1 - type_.Expenditure / 100);
  }

  const now = moment.utc().startOf("day");
  const start = moment.utc(req.StartDate).startOf("day");
  const end = moment.utc(req.EndDate).add(1, "day");
  if (now.isAfter(end)) {
    return 0;
  }
  const remainingDuration = countBusinessDays(
    start.isBefore(now) ? now : start,
    end
  );
  return remainingHours / 40 / (remainingDuration / 5);
}
function _resourceRequestsCommitment(
  req: IResourceRequest,
  idx: number
): number {
  const type_ = req.ResourceTypes[idx];
  const remainingHours = type_.Allocation * (1 - type_.Expenditure / 100);
  const now = moment.utc().startOf("day");
  const start = moment.utc(req.StartDate).startOf("day");
  const end = moment.utc(req.EndDate).add(1, "day");

  if (now.isAfter(end)) {
    return 0;
  }
  const remainingDuration = countBusinessDays(
    start.isBefore(now) ? now : start,
    end
  );

  return remainingHours / 40 / (remainingDuration / 5);
}
function countBusinessDays(start: moment.Moment, end: moment.Moment) {
  [start, end] = [start.clone(), end.clone()];

  const [sDay, eDay] = [start.weekday(), end.weekday()];
  let offset = -sDay;
  start = start.add(-sDay, "day");
  offset += eDay;
  if (eDay === 0) {
    offset++;
  }

  end = end.add(-eDay, "day");
  return end.diff(start, "week") * 5 + offset;
}

@Pipe({ name: "resourceRequestsStatus" })
export class ResourceRequestsStatusPipe implements PipeTransform {
  transform(status: number): string {
    return resourceRequestStatuses[status];
  }
}

@Pipe({ name: "resourceRequestsVelocity" })
export class ResourceRequestsVelocityPipe implements PipeTransform {
  transform(req: IResourceRequest): number {
    return _resourceRequestsVelocity(req);
  }
}

@Pipe({ name: "resourceRequestsDuration" })
export class ResourceRequestsDurationPipe implements PipeTransform {
  transform(
    req: IResourceRequest,
    unit: moment.unitOfTime.Base = "day"
  ): number {
    let start = moment.utc(req.StartDate);
    let end = moment.utc(req.EndDate).add(1, "day");
    return end.diff(start, unit);
  }
}

function _resourceRequestsBusinesDuration(req: IResourceRequest) {
  let start = moment.utc(req.StartDate);
  let end = moment.utc(req.EndDate).add(1, "day");

  return countBusinessDays(start, end);
}

@Pipe({ name: "resourceRequestsBusinessDuration" })
export class ResourceRequestsBusinessDurationPipe implements PipeTransform {
  transform(req: IResourceRequest): number {
    return _resourceRequestsBusinesDuration(req);
  }
}

@Pipe({ name: "resourceRequestsCommitment" })
export class ResourceRequestsCommitmentPipe implements PipeTransform {
  transform(req: IResourceRequest, idx: number): number {
    return _resourceRequestsCommitment(req, idx);
  }
}
@Pipe({ name: "resourceRequestsTotalCommitment" })
export class ResourceRequestsTotalCommitmentPipe implements PipeTransform {
  transform(req: IResourceRequest): number {
    return _resourceRequestsTotalCommitment(req);
  }
}

@Pipe({ name: "resourceRequestsPreprocess" })
export class ResourceRequestsPreprocessPipe implements PipeTransform {
  transform(requests: IResourceRequest[]): any[] {
    return requests.map((req: any) => {
      req.ResourceTypes = req.ResourceTypes || [];
      _hiddenProp(
        req,
        "BusinessDuration",
        _resourceRequestsBusinesDuration(req)
      );
      _hiddenProp(req, "Velocity", _resourceRequestsVelocity(req));
      _hiddenProp(req, "FTECommitment", _resourceRequestsTotalCommitment(req));
      req.ResourceTypes = req.ResourceTypes.map((t: any, idx: number) => {
        _hiddenProp(t, "FTECommitment", _resourceRequestsCommitment(req, idx));
        return t;
      });
      return req;
    });
  }
}

@Pipe({ name: "resourceRequestsStats" })
export class ResourceRequestsStatsPipe implements PipeTransform {
  transform(
    requests: IResourceRequest[],
    sortColumn?: string,
    sortAsc?: boolean
  ): Array<Stats> {
    const stats: { [key: string]: Stats } = {};
    for (let req of requests) {
      const now = moment.utc().startOf("day");
      let start = moment.utc(req.StartDate).startOf("day");
      start = start.isBefore(now) ? now : start;
      const end = moment.utc(req.EndDate).add(1, "day");

      const remainingBusinessDays = countBusinessDays(start, end);
      for (let type_ of req.ResourceTypes || []) {
        let g = (stats[type_.Group] =
          stats[type_.Group] || new Stats(type_.Group));
        let RemainingHours = type_.Allocation * (1 - type_.Expenditure / 100);
        g.TotalHours += type_.Allocation;
        g.RemainingHours += RemainingHours;
        g.RemainingAllocation +=
          remainingBusinessDays > 0
            ? RemainingHours / 40 / (remainingBusinessDays / 5)
            : 0;
      }
    }

    const all = new Stats("All");
    const result = [all];
    for (let group of Object.keys(stats)) {
      result.push(stats[group]);
      const { TotalHours, RemainingHours, RemainingAllocation } = stats[group];
      all.TotalHours += TotalHours;
      all.RemainingHours += RemainingHours;
      all.RemainingAllocation += RemainingAllocation;
    }

    return result;
  }
}

@Pipe({ name: "orderBy", pure: false })
export class OrderByPipe implements PipeTransform {
  transform(collection: any[], column: string, asc: boolean): Array<any> {
    return collection.sort(({ [column]: left }, { [column]: right }) => {
      let result;
      if (typeof left === "string") {
        result = left.localeCompare(right);
      } else {
        result = left - right;
      }
      return result * (asc ? 1 : -1);
    });
  }
}

@Pipe({ name: 'notActiveTeamMember'})
export class NotActiveTeamMemberPipe implements PipeTransform {
    transform(devTeam, active: any[]): Array<any> {
        const activeSet = new Set(active)
        return devTeam.filter(
            dev => !activeSet.has(dev)
        )
    }
}
