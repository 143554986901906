import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter } from '@angular/core';

import { FormGroup, FormControl } from '@angular/forms';

import { FetchAbstractService } from '../service/fetch-abstract.service';
import { MessengerService } from '../service/messenger.service';

declare var jQuery: any;

export abstract class AbstractEntityFormComponent<IT, T> {
  protected formPending: boolean = false;
  protected form!: FormGroup;
  protected modelInstance!: T;
  protected forEdit: boolean = false;

  protected itemService!: FetchAbstractService<IT>;
  protected messengerService!: MessengerService;

  protected subscription: any;

  abstract model: T;

  abstract formSaved: EventEmitter<void>;

  abstract initForm(): void;

  cleanEmpty(obj: any, convert: any = undefined): any {
    const empty = new Set([null, undefined, '']);
    Object.keys(obj).forEach(
      (key: string) =>
        !empty.has(obj[key]) ||
        (convert === undefined ? delete obj[key] : (obj[key] = convert))
    );
    return obj;
  }

  beforeSave(data: any): void {}

  beforeSubmit(data: any): void {}

  onSubmit(): void {
    this.formPending = true;
    let data = Object.assign({}, this.modelInstance, this.form.value);
    this.cleanEmpty(data, null);

    this.beforeSave(data);

    Object.assign(this.modelInstance as any, data);

    this.beforeSubmit(data);
    this.itemService.save(data, this.forEdit).subscribe(
      (data: any) => {
        this.formPending = false;
        this.formSaved.emit();
      },

      (err: HttpErrorResponse) => {
        let message = 'Error';
        console.log(err);

        let errors = err.error.errors;
        this.formPending = false;
        if (errors) {
          message = errors.join('\n');
        }
        this.messengerService.error(message);
        console.error(err);
      }
    );
  }

  cleanSelect(selector: string, formControl: string): void {
    (<FormControl>this.form.controls[formControl]).setValue('');
    jQuery(`select${selector}.select2`).select2().trigger('change');
  }
}
