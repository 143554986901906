import { Component, Input, Output, EventEmitter } from '@angular/core';

import {
  ResourceRequest,
  ResourceType,
  ResourceRequestsService,
  IResourceRequest,
  GroupOptions,
} from '.';
import {
  FormControl,
  FormArray,
  Validators,
  FormBuilder,
} from '@angular/forms';

import { AbstractEntityFormComponent } from '../prototype/entity-form.component';

import { MessengerService } from '../service/messenger.service';

import {
  ClientService,
  OpportunityService,
  AgreementService,
} from '../service';

import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'resource-requests-form',
  templateUrl: './resource-requests-form.component.html',
})
export class ResourceRequestFormComponent extends AbstractEntityFormComponent<
  IResourceRequest,
  ResourceRequest
> {
  @Input()
  set model(item: ResourceRequest) {
    this.modelInstance = item;
    this.forEdit = !!item.Id;
    this.initForm();
  }

  @Input() broadcaster!: Observable<string>;

  @Output() formSaved = new EventEmitter<void>();

  GroupOptions: Array<string> = GroupOptions;
  resourceRequestStatuses = [
    [0, 'Draft'],
    [10, 'Pending'],
    [20, 'Approved'],
    [30, 'Rejected'],
  ];
  availableProjects: {[key: string]: any[]} = {
    clients: [],
    products: [],
    agreements: [],
  };

  constructor(
    protected messengerService: MessengerService,
    private formBuilder: FormBuilder,
    protected itemService: ResourceRequestsService,
    protected clientService: ClientService,
    protected opportunityService: OpportunityService,
    protected agreementService: AgreementService
  ) {
    super();
  }

  ngOnInit(): void {
    // {code: '', label: ''}
    forkJoin([
      this.clientService.getAll(false),
      this.opportunityService.getAll(false),
      this.agreementService.getAll(false),
    ]).subscribe(
      ([clients, products, agreements]: any[]) =>
        (this.availableProjects = {
          clients,
          products,
          agreements,
        })
    );
    this.subscription = this.broadcaster.subscribe((event: string) => {
      switch (event) {
        case 'edit':
          (<FormControl>this.form.controls['Id']).disable();
          (<FormControl>this.form.controls['RequestedAt']).disable();
          this.forEdit = true;
          break;
        case 'prepare':
          (<FormControl>this.form.controls['Id']).enable();
          (<FormControl>this.form.controls['RequestedAt']).enable();
          this.forEdit = false;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  getResourceTypeForm() {
    return this.formBuilder.group({
      Name: ['', [Validators.required]],
      Group: [this.GroupOptions[0], [Validators.required]],
      Allocation: [0, [Validators.required]],
      Expenditure: [0, [Validators.required]],
      Notes: ['', []],
    });
  }

  removeResourceTypeForm(i: number) {
    (this.form.controls['ResourceTypes'] as FormArray).removeAt(i);
  }
  addResourceTypeForm(type_: any = new ResourceType()) {
    let resForm = this.getResourceTypeForm();
    resForm.setValue(type_);
    (this.form.controls['ResourceTypes'] as FormArray).push(resForm);
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      Id: ['', []],
      RequestedAt: ['', []],

      StartDate: ['', [Validators.required]],
      EndDate: ['', [Validators.required]],

      LinkedProject: ['', [Validators.required]],
      Status: [0, [Validators.required]],

      ResourceTypes: this.formBuilder.array([]),
    });

    if (this.forEdit) {
      (<FormControl>this.form.controls['Id']).disable();
      (<FormControl>this.form.controls['RequestedAt']).disable();
    } else {
      (<FormControl>this.form.controls['Id']).enable();
      (<FormControl>this.form.controls['RequestedAt']).enable();
    }
    let data = {
      Id: '',
      RequestedAt: '',
      StartDate: '',
      EndDate: '',
      LinkedProject: '',
      Status: '',
      ResourceTypes: [],
    };

    this.cleanEmpty(Object.assign(data, this.modelInstance), '');
    for (let type_ of data.ResourceTypes) {
      this.addResourceTypeForm(type_);
    }
    this.form.setValue(data);
  }
  linkedProjectChanged(val: string): void {
    (<FormControl>this.form.controls['LinkedProject']).setValue(val);
  }
}
