export interface IUser {
  Email: string;
  JiraId: string;
  FullName: string;
  Role: string;
  State: string;
  Team: string;
  Capacity: number;
  SalesCapacity?: number;
}

export class User {
  constructor(
    public Email: string = "",
    public FullName: string = "",
    public Role: string = "ROLE_USER",
    public State: string = "active",
    public JiraId: string = ""
  ) {}
}

export const Roles = {
  ROLE_USER: "User",
  ROLE_EDITOR: "Editor",
  ROLE_ADMIN: "Admin"
};

export const States = {
  active: "Active",
  inactive: "Inactive"
};

export const Teams = {
  dev: "DevTeam",
  po: "PO",
  creative: "Creative",
  ops: "SysOps/Support"
};

export const Capacities = {
    Sales: 4,
    Ckan: 25,
    Drupal: 25,
    Js: 15,
    Other: 5
}
