import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Project, ProjectsService, IProject } from ".";

import { Validators, FormBuilder } from "@angular/forms";

import { AbstractEntityFormComponent } from "../../prototype/entity-form.component";

import { MessengerService } from "../../service/messenger.service";

import {
  ClientService,
  OpportunityService,
  AgreementService,
} from "../../service";

@Component({
  selector: "projects-form",
  templateUrl: "./projects-form.component.html",
})
export class ProjectsFormComponent extends AbstractEntityFormComponent<
IProject,
Project
> {
  @Input()
  set model(item: Project) {
    this.modelInstance = item;
    this.forEdit = !!item.Id;
    this.initForm();
  }

  @Output() formSaved = new EventEmitter<void>();

  constructor(
    protected messengerService: MessengerService,
    private formBuilder: FormBuilder,
    protected itemService: ProjectsService,
    protected clientService: ClientService,
    protected opportunityService: OpportunityService,
    protected agreementService: AgreementService
  ) {
    super();
  }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  initForm(): void {
    this.form = this.formBuilder.group({
      Id: ["", []],
      Owner: ["", []],

      Title: ["", [Validators.required]],
      Description: ["", [Validators.required]],
      PurchaseOrder: ["", []],

      StartDate: ["", [Validators.required]],
      EndDate: ["", [Validators.required]],
    });

    let data = {
      Title: "",
      Description: "",
      PurchaseOrder: "",

      StartDate: "",
      EndDate: "",
    };

    this.cleanEmpty(Object.assign(data, this.modelInstance), "");
    delete data["Milestones"];
    delete data["PurchasedHours"];
    this.form.setValue(data);
  }
}
