import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  IAgreement,
  Agreement,
  Statuses,
  BillingCycles,
  ServiceTypes,
} from '../../prototype/agreement.prototype';

import {
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';

import { MessengerService } from '../../service/messenger.service';
import { ClientService } from '../../service/client.service';
import { UserService } from '../../service/user.service';
import { OpportunityService } from '../../service/opportunity.service';
import { AgreementService } from '../../service/agreement.service';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { AbstractEntityFormComponent } from '../../prototype/entity-form.component';
import {
  positiveValidator,
  positiveOrZeroValidator,
  endDateValidator,
  startDateValidator,
} from '../../prototype/validator';

import { FileUploader } from '../../prototype/file-uploader';
import { MailService } from '../../service';

@Component({
  selector: 'agreement-form',
  templateUrl: './agreement-form.template.html',
})
export class AgreementFormComponent extends AbstractEntityFormComponent<
  IAgreement,
  Agreement
> {
  @Input()
  set model(item: Agreement) {
    this.statusBeforeSave = item && item.Status;

    this.modelInstance = item || new Agreement();
    this.forEdit = !!this.modelInstance.AgreementNumber;
    this.initForm();
  }

  private statusBeforeSave: string = '';
  private completedSubject: Subject<boolean> = new Subject<boolean>();
  clientCodes: Set<string> = new Set<string>();
  private commissionableCodes: Set<string> = new Set<string>();
  usersList: Set<string> = new Set<string>();
  jobNumbers: Set<string> = new Set<string>();

  uploader: FileUploader = new FileUploader({
    url: '/doesntmatter',
    disableMultipart: true,
    removeAfterUpload: true,
    allowedMimeType: [
      'application/pdf',
      'application/msword',
      'image/jpeg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    additionalParameter: {
      additionalSuccessCallback: this.addDocument.bind(this),
      agreementService: this.itemService,
      completedSubject: this.completedSubject,
    },
  });

  @Input() broadcaster!: Observable<string>;
  @Output() formSaved = new EventEmitter<void>();

  collections: any = {
    Statuses,
    BillingCycles,
    ServiceTypes,
  };

  private params: any = {};

  constructor(
    protected messengerService: MessengerService,
    private formBuilder: FormBuilder,
    protected opportunityService: OpportunityService,
    private clientService: ClientService,
    private userService: UserService,
    protected itemService: AgreementService,
    private mailService: MailService
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientService
      .getClientCodes()
      .subscribe(
        (clientCodes: Set<string>) => (this.clientCodes = clientCodes)
      );
    this.clientService
      .getCommissionableCodes()
      .subscribe(
        (commissionableCodes: Set<string>) =>
          (this.commissionableCodes = commissionableCodes)
      );

    this.opportunityService
      .getJobNumbers()
      .subscribe((jobNumbers: Set<string>) => (this.jobNumbers = jobNumbers));
    this.userService
      .getAllUsers(true)
      .subscribe((usersList: Set<string>) => (this.usersList = usersList));

    this.subscription = this.broadcaster.subscribe((event: string) => {
      switch (event) {
        case 'edit':
          this.forEdit = true;
          break;
        case 'prepare':
          this.forEdit = false;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      ClientCode: [this.clientCodes[0], [Validators.required]],
      JobNumber: ['', [Validators.required]],

      ServiceType: ['', Validators.required],
      StartDate: ['', [Validators.required, startDateValidator]],
      EndDate: ['', [Validators.required, endDateValidator]],
      BillingCycle: ['', Validators.required],
      Status: ['', Validators.required],
      InvoiceAmount: ['', [Validators.required, positiveValidator]],
      HostingAmount: [0, [Validators.required, positiveOrZeroValidator]],
      ServiceAmount: [0, [Validators.required, positiveOrZeroValidator]],
      SupportMaintenanceAmount: [
        0,
        [Validators.required, positiveOrZeroValidator],
      ],
      HasSLA: [false, [Validators.required]],
      SupportHours: [0, [Validators.required]],
      AgreementNumber: [{ value: '', disabled: true }, []],
      JiraTitle: ['', []],
      JiraLicence: [0, [Validators.required, positiveOrZeroValidator]],
      Title: ['', Validators.required],
      ProductOwner: ['', []],
      Documents: this.formBuilder.array([]),
      Commissionable: [false, [Validators.required]],
      Notes: ['', []],
      BudgetNote: ['', []],
    });

    let data = {
      AgreementNumber: '',
      Commissionable: false,
      Notes: '',
      BudgetNote: '',
      HasSLA: false,
      SupportHours: null,
      Documents: [],
      PurchasedHours: [],
      UnchargeableHours: [],
      ProfitableHours: [],
    };
    this.cleanEmpty(Object.assign(data, this.modelInstance), '');

    for (let i = 0; i < data.Documents.length; i++) {
      this.addDocument(data.Documents[i]['id'], data.Documents[i]['name']);
    }

    this.form.patchValue(data);

    this.form.controls['HasSLA'].valueChanges.subscribe((data) => {
      let ctrl = this.form.controls['SupportHours'];
      ctrl.setValidators(data ? [Validators.required, positiveValidator] : []);
      ctrl.updateValueAndValidity();
    });
  }

  initDocument(id: string, name: string): FormGroup {
    let form = this.formBuilder.group({
      DocumentId: [id, []],
      DocumentName: [name, []],
    });
    return form;
  }

  addDocument(id: string, name: string): void {
    let control = <FormArray>this.form.controls['Documents'];
    control.push(this.initDocument(id, name));
  }

  removeDocument(i: number): void {
    let control = <FormArray>this.form.controls['Documents'];
    control.removeAt(i);
  }

  downloadDocument(i: number): void {
    let control = <FormArray>this.form.controls['Documents'];
    let id = control.controls[i].value['DocumentId'];
    let name = control.controls[i].value['DocumentName'];
    this.itemService.downloadDocument(id);
  }

  beforeSubmit(data: any): void {
    if (data.Status === 'Expired' && this.statusBeforeSave !== 'Expired') {
      this.mailService.sendExpiredAgreement(this.modelInstance);
    }
    data.Documents = JSON.stringify(data.Documents);
    delete data.Addons;
    delete data.UnchargeableHours;
    delete data.ProfitableHours;
    delete data.PurchasedHours;
  }

  clientCodeChanged(val: string): void {
    (<FormControl>this.form.controls['ClientCode']).setValue(val);
    this.form.controls['Commissionable'].setValue(
      this.commissionableCodes.has(val)
    );
  }

  productOwnerChanged(val: string): void {
    (<FormControl>this.form.controls['ProductOwner']).setValue(val);
  }

  jobNumberChanged(val: string): void {
    (<FormControl>this.form.controls['JobNumber']).setValue(val);
  }

  onSubmit(): void {
    if (this.uploader.getNotUploadedItems().length) {
      this.uploader.uploadAll();
      this.completedSubject.asObservable().subscribe((success: boolean) => {
        super.onSubmit();
      });
    } else {
      super.onSubmit();
    }
  }
}
