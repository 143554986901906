<section class="widget">
  <header>
    <h4>Resource Calculator</h4>
  </header>
  <div class="body">
    <div class="mt">
      <table
        id="datatable-table"
        class="table table-striped table-hover agreement-table"
      >
        <thead>
          <tr>
            <th style="width: 110px">Project Name</th>
            <th>CKAN</th>
            <th>Drupal</th>
            <th>JS</th>
            <th>Other</th>
            <th>DevTeam</th>
            <th
              title="SUM(Tech / TeamTechCapacity) * 22(business days in a month)"
            >
              Days
            </th>
            <th title="DAYS / 22(business days in a month)">Months</th>
            <th title="SUM(25 / TeamTechCapacity) / TeamSize * 100%">
              Efficiency
            </th>
            <th style="width: 35px"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="let record of [record].concat(records); let i = index"
          >
            <tr>
              <td>
                <input
                  class="flat-input"
                  [(ngModel)]="record.ProjectName"
                  (blur)="takeSnapshot()"
                />
              </td>
              <td>
                <input
                  class="flat-input"
                  (change)="recalculateRecord(record)"
                  [(ngModel)]="record.CkanCapacity"
                  type="number"
                  step="0.1"
                />
              </td>
              <td>
                <input
                  class="flat-input"
                  (change)="recalculateRecord(record)"
                  [(ngModel)]="record.DrupalCapacity"
                  type="number"
                  step="0.1"
                />
              </td>
              <td>
                <input
                  class="flat-input"
                  (change)="recalculateRecord(record)"
                  [(ngModel)]="record.JsCapacity"
                  type="number"
                  step="0.1"
                />
              </td>
              <td>
                <input
                  class="flat-input"
                  (change)="recalculateRecord(record)"
                  [(ngModel)]="record.OtherCapacity"
                  type="number"
                  step="0.1"
                />
              </td>
              <td>
                <select
                  class="flat-input"
                  (change)="
                    addDev(record, record.activeDev); recalculateRecord(record)
                  "
                  [(ngModel)]="record.activeDev"
                >
                  <option [ngValue]="null" disabled selected>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </option>
                  <option
                    *ngFor="
                      let dev of devTeam | notActiveTeamMember : record.DevTeam
                    "
                    [ngValue]="dev"
                  >
                    {{ dev.FullName }}
                  </option>
                </select>
              </td>
              <td>
                <input
                  class="flat-input"
                  type="number"
                  step="0.01"
                  readonly
                  [value]="record.EstimatedDays | number : '.0-2'"
                />
              </td>
              <td>
                <input
                  class="flat-input"
                  type="number"
                  step="0.01"
                  readonly
                  [value]="record.EstimatedDays / 22 | number : '.0-2'"
                />
              </td>
              <td>
                <input
                  class="flat-input"
                  type="number"
                  step="0.01"
                  readonly
                  [value]="record.Efficiency | number : '.0-2'"
                />
              </td>
              <td>
                <button
                  *ngIf="i === 0; else dropButton"
                  class="btn btn-xs btn-success"
                  (click)="addRecord(record)"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <ng-template #dropButton>
                  <button
                    class="btn btn-xs btn-danger"
                    (click)="dropRecord(record)"
                  >
                    <i class="fa fa-minus"></i>
                  </button>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <ul class="unstyled dev-team-members">
                  <li>Dev Team:</li>
                  <li *ngFor="let dev of record.DevTeam">
                    {{ dev.FullName }}
                    <i
                      class="fa fa-times"
                      (click)="dropDev(record, dev); recalculateRecord(record)"
                      style="cursor: pointer"
                    ></i>
                  </li>
                  <li *ngIf="!record.DevTeam.length">empty</li>
                </ul>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</section>
