<div
  [id]="modalId"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  style="display: none"
  #modal
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
        <h4 class="modal-title" id="myModalLabel2">{{ heading }}</h4>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div *ngIf="withControls" class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          (click)="rejectModal.emit(modal)"
          data-dismiss="modal"
        >
          {{ cancelText }}
        </button>
        <button type="button" class="btn btn-primary" (click)="onAccept(modal)">
          {{ confirmText }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
