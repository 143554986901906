import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { IClient } from '../prototype/client.prototype';
import { ClientService } from '../service/client.service';
import { Observable } from 'rxjs';

@Injectable()
export class ClientResolver implements Resolve<IClient | null> {
  constructor(private backend: ClientService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IClient | null> {
    let id = route.params['entityId'];

    return this.backend.getByUniqueKey(id);
  }
}
